import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../context/AppContext";
import styles from "./index.module.css";

const TermsConditionModal = ({ terms }) => {
  const { setModal } = useAppContext();
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <span className={styles.btn_close} onClick={() => setModal({ open: false })}>
        <svg
          style={{ marginTop: "-4px" }}
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            fill="#ffffff"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
        </svg>
      </span>
      <p className={styles.event_name}>{t("terms and conditions")}</p>
      <div
        className="description__text"
        dangerouslySetInnerHTML={{
          __html: terms,
        }}
      />
    </div>
  );
};

export default TermsConditionModal;
