// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { createCollection } from "../../../api/event";
import JoditEditor from "jodit-react";
import "jodit";
import "jodit/build/jodit.min.css";
import styles from "./index.module.css";

const PageCollectionCreate = () => {
  const { userInfo } = useUserContext();
  const [backgroundFile, setBackgroundFile] = useState<File>();
  const [largeFile, setLargeFile] = useState<File>();
  const [largeMobileFile, setLargeMobileFile] = useState<File>();
  const [smallFile, setSmallFile] = useState<File>();
  const [backgroundImagePreview, setBackgroundImagePreview] = useState("");
  const [largeImagePreview, setLargeImagePreview] = useState("");
  const [largeMobileImagePreview, setLargeMobileImagePreview] = useState("");
  const [smallImagePreview, setSmallImagePreview] = useState("");
  const { setLoading, addToast } = useAppContext();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [values, setValues] = useState({
    name: "",
    category: "",
    wallet_address: "",
    description: "",
  });

  const borderStyle = (str: string) => {
    return str === "has-empty"
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };

  const initialValidation = {
    picture_background: "",
    picture_large: "",
    picture_mobile_large: "",
    picture_small: "",
    name: "",
    category: "",
    wallet_address: "",
    description: "",
  };

  const editorConfig = {
    readonly: false,
    toolbar: false,
    language: "en",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    uploader: {
      insertImageAsBase64URI: false,
    },
    height: 100,
  };

  const [validations, setValidations] = useState(initialValidation);

  const checkvalidations = () => {
    if (!backgroundFile) {
      setValidations({ ...initialValidation, picture_background: "has-empty" });
      return false;
    } else if (!largeFile) {
      setValidations({ ...initialValidation, picture_large: "has-empty" });
      return false;
    } else if (!largeMobileFile) {
      setValidations({
        ...initialValidation,
        picture_mobile_large: "has-empty",
      });
      return false;
    } else if (!smallFile) {
      setValidations({ ...initialValidation, picture_small: "has-empty" });
      return false;
    } else if (values.name === "") {
      setValidations({ ...initialValidation, name: "has-empty" });
      return false;
    } else if (values.category === "") {
      setValidations({ ...initialValidation, category: "has-empty" });
      return false;
      // } else if (!values.wallet_address) {
      //   setValidations({ ...initialValidation, wallet_address: "has-empty" });
      //   return false;
    } else if (!values.description) {
      setValidations({ ...initialValidation, description: "has-empty" });
      return false;
    } else {
      setValidations({ ...initialValidation });
    }

    return true;
  };

  const backgroundImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader();
      const f = e.target.files[0];
      if (reader !== undefined && f !== undefined) {
        reader.onloadend = () => {
          setBackgroundFile(f);
          setBackgroundImagePreview(reader.result as string);
        };
        reader.readAsDataURL(f);
      }

      setValidations({ ...initialValidation, picture_background: "" });
    }
  };

  const largeImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader();
      const f = e.target.files[0];
      if (reader !== undefined && f !== undefined) {
        reader.onloadend = () => {
          setLargeFile(f);
          setLargeImagePreview(reader.result as string);
        };
        reader.readAsDataURL(f);
      }

      setValidations({ ...initialValidation, picture_large: "" });
    }
  };

  const largeMobileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader();
      const f = e.target.files[0];
      if (reader !== undefined && f !== undefined) {
        reader.onloadend = () => {
          setLargeMobileFile(f);
          setLargeMobileImagePreview(reader.result as string);
        };
        reader.readAsDataURL(f);
      }

      setValidations({ ...initialValidation, picture_mobile_large: "" });
    }
  };

  const smallImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length) {
      const reader = new FileReader();
      const f = e.target.files[0];
      if (reader !== undefined && f !== undefined) {
        reader.onloadend = () => {
          setSmallFile(f);
          setSmallImagePreview(reader.result as string);
        };
        reader.readAsDataURL(f);
      }
    }
  };

  const handleChange = (prop: string, value: string) => {
    setValidations((prevState) => ({ ...prevState, [prop]: "" }));
    setValues({ ...values, [prop]: value });
  };

  const handleCreate = async () => {
    if (!checkvalidations()) return;

    setLoading(true);

    const ipfs_url = `https://bkstage.infura-ipfs.io/ipfs/`;

    const fd = new FormData();
    fd.append("background_image", backgroundFile as File);
    fd.append("large_image", largeFile as File);
    fd.append("large_mobile_image", largeMobileFile as File);
    fd.append("small_image", smallFile as File);
    fd.append("picture_ipfs", ipfs_url);
    fd.append("creator", userInfo.user.id);

    for (const [key, value] of Object.entries(values)) {
      if (key === "date") {
        fd.append(key, new Date(value).toISOString().toString());
      } else {
        fd.append(key, value as string);
      }
    }
    createCollection(fd)
      .then(async (res) => {
        if (res.success) {
          setLoading(false);
          navigate("/");
        } else {
          addToast("failed", { appearance: "error", autoDismiss: true });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast("An error occured", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    if (
      userInfo?.user?.user_type === "ADMIN" ||
      (userInfo?.user?.user_type === "BUSINESS" &&
        userInfo?.user?.business_allow)
    ) {
      return;
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <div className={styles.cr__collection_container}>
      <h2 className={styles.title}>{t("create collection")}</h2>
      <div className={styles.cr__collection_formContainer}>
        <div style={{ display: "flex" }}>
          <div className={styles.purple_mark}></div>
          <p className={styles.cr__collection_subtitle}>
            {t("collection details")}
          </p>
        </div>
        <div>
          <p className="cr__evcard-labelTitle">{t("name")}</p>
          <div className="sign__group">
            <input
              id="name"
              type="text"
              name="name"
              className={styles.input}
              value={values.name}
              style={borderStyle(validations.name)}
              onChange={(e) => handleChange("name", e.target.value)}
            />
            {validations.name === "has-empty" && (
              <span className={styles.error_text}>{t("name required")}*</span>
            )}
          </div>
        </div>
        <div>
          <p className="cr__evcard-labelTitle">{t("category")}</p>
          <div className="sign__group">
            <select
              id="category"
              name="category"
              className="sign__select"
              value={values.category}
              style={borderStyle(validations.category)}
              onChange={(e) => handleChange("category", e.target.value)}
            >
              <option disabled hidden value="">
                {t("select category")}
              </option>
              <option value="Festival">{t("festival")}</option>
              <option value="Concert">{t("concert")}</option>
              <option value="Club">{t("club")}</option>
              <option value="Store">{t("store")}</option>
              <option value="Meet & Greet">{t("meet & greet")}</option>
              <option value="Awards">{t("awards")}</option>
              <option value="Art">{t("art")}</option>
              <option value="Sport">{t("sport")}</option>
              <option value="Hospitality">{t("hospitality")}</option>
              <option value="Platform">{t("platform")}</option>
              <option value="Experience">{t("experience")}</option>
              <option value="Cirque">{t("cirque")}</option>
              <option value="Show ">{t("show")}</option>
            </select>
            <img
              alt="arrow"
              className={styles.cr__collection_selectArrow}
              src="/img/icons/arrow-down.svg"
            />
            {validations.category === "has-empty" && (
              <span className={styles.error_text}>
                {t("category required")}*
              </span>
            )}
          </div>
        </div>
        <div className="cr__evcard-100" style={{ paddingLeft: 0 }}>
          <p className="cr__evcard-labelTitle">{t("general description")}</p>
          <div className={styles.collection_description}>
            <JoditEditor
              config={editorConfig}
              value={values.description}
              onBlur={(newContent) => handleChange("description", newContent)}
            />
          </div>
          {validations.description === "has-empty" && (
            <span className={styles.error_text}>
              {t("description required")}*
            </span>
          )}
        </div>
        <div style={{ display: "flex", marginTop: 74 }}>
          <div className={styles.purple_mark}></div>
          <p className="cr__article--subtitle">
            {t("upload collection image")}
          </p>
        </div>
        <div style={{ paddingTop: 10 }}>
          <div className="cr__evcard-uploadImg">
            <label
              id="file2"
              htmlFor="smallimage-upload"
              className={styles.collection_input__upload}
              style={{
                width: 140,
                height: 140,
                borderRadius: 140,
                borderStyle: "dashed",
              }}
            >
              {!smallImagePreview && (
                <>
                  <LazyLoadImage src="/img/icons/upload-1.svg" alt="data" />{" "}
                  <span>{t("upload")}</span>
                </>
              )}
            </label>
            <input
              data-name="#file2"
              id="smallimage-upload"
              name="file"
              type="file"
              accept=".png,.jpg,.jpeg,.gif"
              onChange={smallImageChange}
              src={smallImagePreview}
            />

            {validations.picture_small === "has-empty" && (
              <span className={styles.error_text}>
                {t("small image required")}*
              </span>
            )}
          </div>
          {smallImagePreview && (
            <div style={{ marginTop: -134, marginLeft: 6, paddingBottom: 6 }}>
              <LazyLoadImage
                src={smallImagePreview}
                style={{ width: 128, height: 128, borderRadius: 140 }}
                alt="logo"
              />
            </div>
          )}
          <p className="cr__evcard-labelTitle">{t("Logo Image")}</p>
          <p className="cr__evcard-labelDesc">{t("logo text")}</p>
        </div>
        <div style={{ marginTop: 44 }}>
          <div className="cr__evcard-uploadImg">
            <label
              id="file"
              htmlFor="largeimage-upload"
              className={styles.collection_input__upload}
              style={{
                maxWidth: 442,
                height: 115,
                borderStyle: "dashed",
              }}
            >
              {!largeImagePreview && (
                <>
                  <LazyLoadImage src="/img/icons/upload-1.svg" alt="data" />
                  <span>{t("upload")}</span>
                </>
              )}
            </label>
            <input
              data-name="#file1"
              id="largeimage-upload"
              name="file"
              type="file"
              accept=".png,.jpg,.jpeg,.gif"
              onChange={largeImageChange}
              src={largeImagePreview}
            />
            {validations.picture_large === "has-empty" ? (
              <span className={styles.error_text}>
                {t("large image required")}*
              </span>
            ) : validations.picture_large === "has-invalid" ? (
              <span className={styles.error_text}>
                {t("wrong image size")}*
              </span>
            ) : (
              ""
            )}
          </div>
          {largeImagePreview && (
            <div style={{ marginTop: -110, marginLeft: 5, paddingBottom: 5 }}>
              <LazyLoadImage
                src={largeImagePreview}
                style={{ width: 431, height: 105 }}
                alt="background"
              />
            </div>
          )}
          <p className="cr__evcard-labelTitle">{t("Main Banner Image")}</p>
          <p className="cr__evcard-labelDesc">{t("main banner text")}</p>
        </div>

        <div style={{ marginTop: 44 }}>
          <div className="cr__evcard-uploadImg">
            <label
              id="file"
              htmlFor="largeMobileimage-upload"
              className={styles.collection_input__upload}
              style={{
                maxWidth: 188,
                height: 115,
                borderStyle: "dashed",
              }}
            >
              {!largeMobileImagePreview && (
                <>
                  <LazyLoadImage src="/img/icons/upload-1.svg" alt="data" />
                  <span>{t("upload")}</span>
                </>
              )}
            </label>
            <input
              data-name="#file1"
              id="largeMobileimage-upload"
              name="file"
              type="file"
              accept=".png,.jpg,.jpeg,.gif"
              onChange={largeMobileImageChange}
              src={largeMobileImagePreview}
            />
            {validations.picture_mobile_large === "has-empty" ? (
              <span className={styles.error_text}>
                {t("large mobile image required")}*
              </span>
            ) : validations.picture_mobile_large === "has-invalid" ? (
              <span className={styles.error_text}>
                {t("wrong image size")}*
              </span>
            ) : (
              ""
            )}
          </div>
          {largeMobileImagePreview && (
            <div style={{ marginTop: -110, marginLeft: 5, paddingBottom: 5 }}>
              <LazyLoadImage
                src={largeMobileImagePreview}
                style={{ width: 178.5, height: 105 }}
                alt="Mobile banner"
              />
            </div>
          )}
          <p className="cr__evcard-labelTitle">
            {t("Main Mobile Banner Image")}
          </p>
          <p className="cr__evcard-labelDesc">{t("main mobile banner text")}</p>
        </div>

        <div style={{ marginTop: 44 }}>
          <div className="cr__evcard-uploadImg">
            <label
              id="file"
              htmlFor="backgroundimage-upload"
              className={styles.collection_input__upload}
              style={{
                width: 230,
                height: 115,
                borderStyle: "dashed",
              }}
            >
              {!backgroundImagePreview && (
                <>
                  <LazyLoadImage src="/img/icons/upload-1.svg" alt="data" />
                  <span>{t("upload")}</span>
                </>
              )}
            </label>
            <input
              data-name="#file1"
              id="backgroundimage-upload"
              name="file"
              type="file"
              accept=".png,.jpg,.jpeg,.gif"
              onChange={backgroundImageChange}
              src={backgroundImagePreview}
            />
            {validations.picture_background === "has-empty" ? (
              <span className={styles.error_text}>
                {t("background image required")}*
              </span>
            ) : validations.picture_background === "has-invalid" ? (
              <span className={styles.error_text}>
                {t("wrong image size")}*
              </span>
            ) : (
              ""
            )}
          </div>
          {backgroundImagePreview && (
            <div style={{ marginTop: -110, marginLeft: 5, paddingBottom: 5 }}>
              <LazyLoadImage
                src={backgroundImagePreview}
                style={{ width: 219, height: 105 }}
                alt="large"
              />
            </div>
          )}
          <p className="cr__evcard-labelTitle">
            {t("Collection Card Banner Image")}
          </p>
          <p className="cr__evcard-labelDesc">{t("collection text")}</p>
        </div>
      </div>
      <div className="referral__btn_container">
        <button
          type="button"
          className={
            values.name === "" || !smallFile ? "buy__btn-disable" : "sign__btn"
          }
          disabled={values.name === "" || !smallFile}
          onClick={handleCreate}
          style={{ marginRight: 0, marginLeft: 0 }}
        >
          {t("create collection")}
        </button>
      </div>
    </div>
  );
};

export default PageCollectionCreate;
