import api from "./api";

const getRates = () => {
    return new Promise((resolve, reject) => {
      api
        .get("/api/rate")
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };
  

export { getRates };
