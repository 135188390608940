import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PageError = () => {
  const { t } = useTranslation();
  return (
    <main className="main">
      <div className="container">
        <div className="row row--grid">
          <div className="col-12">
            <ul className="breadcrumb">
              <li className="breadcrumb__item">
                <Link to="/">{t("home")}</Link>
              </li>
              <li className="breadcrumb__item breadcrumb__item--active">
              {t("error 404")}
              </li>
            </ul>
          </div>
          <div className="col-12">
            <div className="page-404">
              <div className="page-404__wrap">
                <div className="page-404__content">
                  <h1 className="page-404__title">404</h1>
                  <p className="page-404__text">
                    {t("The page you are looking for is not available!")}
                  </p>
                  <Link to="/" className="page-404__btn">{t("go back")}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PageError;
