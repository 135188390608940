import { Link } from "react-router-dom";
import VerifyPopper from "../VerifyPopper";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../context/UserContext";
import { dateString } from "../../utils";
import styles from "./index.module.css";

const VerifyBlogItem = ({ article, handleVerifyArticle }) => {
  const { userInfo } = useUserContext();
  const { t } = useTranslation();

  return (
    <div className="col-12 col-sm-6 col-lg-4" key={article.id}>
      <div className={styles.post}>
        {userInfo && userInfo.user.user_type === "ADMIN" ? (
          <VerifyPopper
            setDeletePopupStatus={() => {}}
            onClickDelete={() => {
              handleVerifyArticle(article.id);
            }}
          />
        ) : (
          ""
        )}

        <Link
          to={`/article/${article.title
            .replaceAll(" ", "_")
            .replaceAll("&", "_")}/${article.id}`}
          className={styles.post__img_blog}
        >
          <LazyLoadImage
            src={`${process.env.REACT_APP_ASSET_BASE_URL}/${article.image}`}
            alt="data"
          />
        </Link>

        <div className={styles.post__content_blog}>
          <span className={styles.post__category_blog}>{t("news")}</span>
          <h3 className={styles.post__title_blog}>
            <Link
              to={`/article/${article.title
                .replaceAll(" ", "_")
                .replaceAll("&", "_")}/${article.id}`}
            >
              {article.title}
            </Link>
          </h3>
          <div className={styles.post__meta}>
            <span className={styles.post__date}>
              <img src="/img/icons/clock.svg" alt="data" />
              {dateString(article.createdAt)}
            </span>
            <div className={styles.post__read_like}>
              {/* <span className={styles.post__date}>
                <img src="/img/icons/message-square.svg" alt="data" />
                {article.read}
              </span>
              <span className={styles.dot}>&#183;</span> */}
              <span className={styles.post__date}>
                <img src="/img/icons/thumbs-up.svg" alt="data" />
                {article.likes_number === null
                  ? 0
                  : JSON.parse(article.likes_number).length}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyBlogItem;
