import PageError from "../views/pages/404Error";
import PageArticleCreate from "../views/pages/Article/create";
import PageArticleEdit from "../views/pages/Article/edit";
import PageArticle from "../views/pages/Article";
import PageAuthor from "../views/pages/author";
import PageBlog from "../views/pages/blog";
import PageVerifyBlog from "../views/pages/blog/verify";
import PageCollection from "../views/pages/collection";
import PageContact from "../views/pages/contact";
import PageEventCardCreate from "../views/pages/event/create_eventcard";
import PageCollectionCreate from "../views/pages/collection/create_collection";
import PagePromoCodeGenerate from "../views/pages/generate/promo_code_generate";
import PageGenerateLink from "../views/pages/generate/generate_link";
import PagePromoLink from "../views/pages/generate/promo_link";
import PageEventCard from "../views/pages/event/eventcard";
import PageEventCardUpdate from "../views/pages/event/update";
import PageExplorer from "../views/pages/explorer";
import PageForgetpassword from "../views/pages/forget";
import Home from "../views/pages/home";
import PagePrivacy from "../views/pages/privacy";
import PageTermsPrivacy from "../views/pages/terms_privacy";
import PageSignin from "../views/pages/signin";
import PageSignup from "../views/pages/signup";
import PageBusinessSignup from "../views/pages/business_signup";
import PageMessageCreate from "../views/pages/message/create";
import PageResetPassword from "../views/pages/reset_password";
import PageAdminUsers from "../views/pages/admin/users";
import PageUserCreate from "../views/pages/admin/users/create_user";
import PageAdminSettings from "../views/pages/admin/settings";
import PageWallet from "../views/pages/wallet";
import PageOffer from "../views/pages/offer";
import PagePermission from "../views/pages/permission";
import About from "../views/pages/about";
import TermsAndConditions from "../views/pages/terms";
import PageAdminReport from "../views/pages/admin/report";
import CheckTicket from "../views/pages/check";
import CheckBenefit from "../views/pages/check/check_benefit";
import PageLiked from "../views/pages/liked";
import Selfie from "../views/pages/selfie";
import PageFaq from "../views/pages/faq";
import PageTrackReport from "../views/pages/track/report";
import PageSalesReport from "../views/pages/sales/report";
import PageSalesOverview from "../views/pages/sales/overview";
import PageVerifyEvent from "../views/pages/verifyEvent";
import PageOfferCreate from "../views/pages/offer/create";
import PageNotificationCreate from "../views/pages/notification/create";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/error",
    element: <PageError />,
  },
  {
    path: "/article/create",
    element: <PageArticleCreate />,
  },
  {
    path: "/notification/create",
    element: <PageNotificationCreate />,
  },
  {
    path: "/article/edit/:id",
    element: <PageArticleEdit />,
  },
  {
    path: "/article/:title/:articleId",
    element: <PageArticle />,
  },
  {
    path: "/article/earasers",
    element: <PageArticle />,
  },
  {
    path: "/EventFi",
    element: <PageArticle />,
  },
  {
    path: "/admin/users",
    element: <PageAdminUsers />,
  },
  {
    path: "/admin/settings",
    element: <PageAdminSettings />,
  },
  {
    path: "/admin/report",
    element: <PageAdminReport />,
  },
  {
    path: "/admin/users/create",
    element: <PageUserCreate />,
  },
  {
    path: "/wallet/*",
    element: <PageWallet />,
  },
  {
    path: "/signin",
    element: <PageSignin />,
  },
  {
    path: "/signup",
    element: <PageSignup />,
  },
  {
    path: "/business-signup",
    element: <PageBusinessSignup />,
  },
  {
    path: "/forget",
    element: <PageForgetpassword />,
  },
  {
    path: "/reset_password/:code",
    element: <PageResetPassword />,
  },
  {
    path: "/privacy",
    element: <PagePrivacy />,
  },
  {
    path: "/terms",
    element: <TermsAndConditions />,
  },
  {
    path: "/terms-privacy",
    element: <PageTermsPrivacy />,
  },
  {
    path: "/blog",
    element: <PageBlog />,
  },
  {
    path: "/blog/verify",
    element: <PageVerifyBlog />,
  },
  {
    path: "/contacts",
    element: <PageContact />,
  },
  {
    path: "/explorer",
    element: <PageExplorer />,
  },
  {
    path: "/liked",
    element: <PageLiked />,
  },
  {
    path: "/collection/:id",
    element: <PageCollection />,
  },
  {
    path: "/ha-ash",
    element: <PageCollection />,
  },
  {
    path: "/imolese",
    element: <PageCollection />,
  },
  
  {
    path: "/theboatparty",
    element: <PageCollection />,
  },
  {
    path: "/hayafest",
    element: <PageCollection />,
  },
  {
    path: "/Matute",
    element: <PageCollection />,
  },
  {
    path: "/SantoRemedio",
    element: <PageCollection />,
  },
  {
    path: "/earasers",
    element: <PageCollection />,
  },
  {
    path: "/nightDay",
    element: <PageCollection />,
  },
  {
    path: "/zamna",
    element: <PageCollection />,
  },
  {
    path: "/ZamnaFrance",
    element: <PageCollection />,
  },
  {
    path: "/onparty",
    element: <PageCollection />,
  },
  {
    path: "/DOIT4MUSIC",
    element: <PageCollection />,
  },
  {
    path: "/bamboleo",
    element: <PageCollection />,
  },
  {
    path: "/TulumCryptoFest24",
    element: <PageCollection />,
  },
  {
    path: "/author",
    element: <PageAuthor />,
  },
  {
    path: "/event/eventcard/create/:id",
    element: <PageEventCardCreate />,
  },
  {
    path: "/collection/create",
    element: <PageCollectionCreate />,
  },
  {
    path: "/generate_link",
    element: <PageGenerateLink />,
  },
  {
    path: "/promo_code_link",
    element: <PagePromoLink />,
  },
  {
    path: "/promo_code_generate/:eventcardId/:promoId",
    element: <PagePromoCodeGenerate />,
  },
  {
    path: "/event/eventcard/:eventId",
    element: <PageEventCard />,
  },
  {
    path: "/IBIZAFullExperience",
    element: <PageEventCard />,
  },
  {
    path: "/spacetaleofus",
    element: <PageEventCard />,
  },
  {
    path: "/event/update/:id",
    element: <PageEventCardUpdate />,
  },
  {
    path: "/message/create",
    element: <PageMessageCreate />,
  },
  {
    path: "/check_ticket/:event_name/:serial_text/:key/:wallet/:nft_address/:token_id",
    element: <CheckTicket />,
  },
  {
    path: "/check_benefit/:id/:key",
    element: <CheckBenefit />,
  },
  {
    path: "/haash-giveaway-selfie",
    element: <Selfie />,
  },
  {
    path: "/faq",
    element: <PageFaq />,
  },
  {
    path: "/offer",
    element: <PageOffer />,
  },
  {
    path: "/offer/create/:id",
    element: <PageOfferCreate />,
  },
  {
    path: "/permissions",
    element: <PagePermission />,
  },
  {
    path: "/track/report",
    element: <PageTrackReport />,
  },
  {
    path: "/sales/report/:id",
    element: <PageSalesReport />,
  },
  {
    path: "/sales/overview",
    element: <PageSalesOverview />,
  },
  {
    path: "/verify-event",
    element: <PageVerifyEvent />,
  },
  {
    path: "*",
    element: <PageError />,
  },
];

export default routes;
