import moment from "moment";
import DateObject from "react-date-object";

const getCookie = (cookiename) => {
  let cookiestring = RegExp(cookiename + "=[^;]+").exec(document.cookie);
  return decodeURIComponent(
    !!cookiestring ? cookiestring.toString().replace(/^[^=]+./, "") : ""
  );
};

const deleteAllCookies = () => {
  let cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let eqPos = cookie.indexOf("=");
    let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

const validateEmail = (value) => {
  let emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};

const isWalletValid = (wallet) => {
  const len = wallet?.length;
  if (len !== 42) return false;
  if (wallet[0] !== "0" || wallet[1] !== "x") return false;
  for (let i = 2; i < len; i++)
    if (
      (wallet[i] < "a" || wallet[i] > "f") &&
      (wallet[i] < "A" || wallet[i] > "F") &&
      (wallet[i] < "0" || wallet[i] > "9")
    )
      return false;
  return true;
};

const validateHtmlEditorValue = (value) => {
  let html = value.toString("html");
  html = html
    .replaceAll("\n", "")
    .replaceAll("&nbsp;", "")
    .replaceAll("<p><br></p>", "")
    .replaceAll("<p></p>", "");
  if (html === "") return false;
  return true;
};

const getDateString = (timestamp) => {
  return moment(timestamp).format("MMM DD, YYYY");
};

const dateString = (d) => {
  const date = new DateObject({
    date: new Date(d),
  });

  return date.format("MMMM DD, YYYY");
};

const getEventPrice = (eventCard) => {
  const addons = eventCard?.addons ? JSON.parse(eventCard.addons) : [];
  const payees = eventCard?.payees ? JSON.parse(eventCard.payees) : [];
  let addonPrice = 0;
  const len = addons.length;
  for (let i = 0; i < len; i++) {
    addonPrice += Number(addons[i].price);
  }
  let payeeFee = 0;
  payees.map((payee) => {
    payeeFee += Number(payee.fee);
    return 0;
  });
  return ((eventCard.price + addonPrice) * (100 + payeeFee)) / 100;
  // return eventCard.price;
};

const isVideoFile = (str) => {
  const fileName = str.split(".");
  const ext = fileName[fileName.length - 1].toLowerCase();
  return ext === "mp4" || ext === "mov" || ext === "avi";
};

const getLikesNumber = (eventCard) => {
  let likes = [];
  try {
    likes = JSON.parse(eventCard.likes_number);
  } catch (err) {
    likes = [];
  }
  if (typeof likes !== "object" || likes === null) likes = [];
  if (likes) return likes.length;
  else return 0;
};

const isURL = (str) => {
  const urlPattern = /^(https?:\/\/)?([\da-z\\.-]+)\.([a-z\\.]{2,6})([\\/\w \\.-]*)*\/?$/;
  return urlPattern.test(str);
}

export {
  getCookie,
  deleteAllCookies,
  validateEmail,
  getDateString,
  dateString,
  validateHtmlEditorValue,
  getEventPrice,
  // _getEventPrice,
  isVideoFile,
  getLikesNumber,
  isWalletValid,
  isURL,
};
