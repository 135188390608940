import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";
import styles from "./index.module.css";

const RecommendTwoFactorModal = () => {
  const { setModal } = useAppContext();
  const navigate = useNavigate();

  const handle2FA = () => {
    setModal({
      open: false,
    });
    navigate("/author?tab=security");
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>Enable Two Factor Authentication</p>
      <p className={styles.text}>
        We kindly recommend you to enable Two Factor Authentication for your
        account by following these simple steps:
      </p>
      <p className={styles.text}>1. Navigate to the "Profile Settings"</p>
      <p className={styles.text}>
        2. Look for "SECURITY" section and click "ENABLE 2FA" button
      </p>
      <p className={styles.text}>
        3. Follow the on-screen instructions to enable it.
      </p>
      <button className={styles.add__btn} onClick={handle2FA}>
        Enable 2FA NOW
      </button>
    </div>
  );
};

export default RecommendTwoFactorModal;
