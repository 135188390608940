import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../../context/UserContext";
import { useAppContext } from "../../../context/AppContext";
import { buyTicketBKSUSDT } from "../../../api/ticket";
import styles from "./index.module.css";

const PasswordModal = ({ data, handleBuyTicket }) => {
  const { userInfo } = useUserContext();
  const { setLoading, setModal, addToast } = useAppContext();
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const handleEvent = async () => {
    setLoading(true);
    console.log(userInfo.user.email, password, data);
    const sendData = {
      email: userInfo.user.email,
      password,
      data,
    };
    const response = await buyTicketBKSUSDT(sendData);
    console.log(response);
    if (response.success) {
      await handleBuyTicket(response?.status?.transactionHash, userInfo.user.wallet_address, "Binance Smart Chain");
    } else {
      if (response.status === "small usdt") {
        addToast("You don't have enough USDT in your BKS wallet.", { appearance: "error", autoDismiss: true });
      } else if (response.status === "no user") {
        addToast("Wrong password", { appearance: "error", autoDismiss: true });
      } else {
        addToast("Failed. Please check your BKS wallet.", { appearance: "error", autoDismiss: true });
      }
      setModal({ open: false });
      setLoading(false);
    }
  };
  return (
    <form>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.input_wrapper_full}>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Input password"
            />
          </div>
        </div>
        <button className={styles.add__btn} onClick={handleEvent} type="button">
          {t("confirm")}
        </button>
      </div>
    </form>
  );
};

export default PasswordModal;
