import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import { useAppContext } from "../../context/AppContext";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ItemRoyalty = ({
  values,
  royalties,
  remove_Royalty,
  add_Royalty,
  handleChange,
}) => {
  const { t } = useTranslation();
  // const { addToast } = useAppContext();
  const [royaltyWallet, setRoyaltyWallet] = useState("");
  const [royaltyFee, setRoyaltyFee] = useState("");
  const shortAddress = (str) => {
    return str?.slice(0, 5) + "..." + str?.slice(str.length - 4);
  };
  // const add = () => {
  //   if (royalties.length > 10) return;
  //   if (royaltyWallet === "" || royaltyFee === "") {
  //     addToast("Please input all traits for the Royalty", {
  //       appearance: "error",
  //       autoDismiss: true,
  //     });
  //     return;
  //   }
  //   add_Royalty(royaltyWallet, royaltyFee);
  //   setRoyaltyWallet("");
  //   setRoyaltyFee("");
  // };

  return (
    <div className="cr__evcard-subItem">
      <h4 className="cr__evcard--subtitle">
        <span></span>
        {t("add royalty settings")}
      </h4>
      <div className="seat-container">
        {royalties.map((royalty, index) => (
          <div key={index}>
            <div className="addon-block" style={{ paddingTop: 10 }}>
              <p
                style={{
                  color: "#fff",
                  width: 20,
                  marginBottom: 0,
                }}
              >
                {index + 1}
              </p>
              {royalty.chain === "bsc" && (
                <LazyLoadImage src="/img/bsc.png" alt="bsc" />
              )}
              {royalty.chain === "near" && (
                <LazyLoadImage src="/img/near.png" alt="bsc" />
              )}
              <p
                className="text__addon-name"
                style={{
                  width: 60,
                }}
              >
                {royalty.chain}
              </p>
              <p
                className="text__addon-name"
                style={{
                  width: 300,
                }}
              >
                {shortAddress(royalty.wallet)}
              </p>
              <p
                className="text__addon-name"
                style={{
                  width: 60,
                }}
              >
                {royalty.fee}
              </p>
              {/* <div
                className="sign__btn-second"
                style={{
                  width: 40,
                  border: 0,
                  margin: 0,
                }}
                onClick={() => remove_Royalty(index)}
              >
                <img src="/img/trash.png" alt="trush" className="img__trush" />
              </div> */}
            </div>
          </div>
        ))}
        <div
          className="cr__evcard-rowParent"
          style={{ gap: 20, paddingLeft: 0, display: 'none' }}
        >
          <div className="sign__group" style={{ width: 120 }}>
            <p className="cr__evcard-labelTitle">{t("wallet")}</p>
            <select
              id="category"
              name="category"
              className="sign__select"
              style={{
                padding: values.wallet === "" ? "0 20px" : "0 20px 0 45px",
              }}
              value={values.wallet}
              onChange={(e) => handleChange("wallet", e.target.value)}
            >
              {/* <option disabled hidden value="">
                {t("select...")}
              </option> */}
              <option value="bsc">BSC</option>
              <option value="near">NEAR</option>
            </select>
            <img
              src="/img/icons/arrow-down.svg"
              alt="dropdown"
              className="cr__evcard-selectArrow"
            />
            {values.wallet === "bsc" && (
              <img
                src="/img/bsc.png"
                alt="bsc"
                style={{
                  position: "absolute",
                  left: 13,
                  top: 51,
                }}
              />
            )}
            {values.wallet === "near" && (
              <img
                src="/img/near.png"
                alt="bsc"
                style={{
                  position: "absolute",
                  left: 13,
                  top: 51,
                }}
              />
            )}
          </div>
          <div className="sign__group" style={{ flex: 1, minWidth: 120 }}>
            <p className="cr__evcard-labelTitle">{t("BSC wallet")}</p>
            <input
              type="text"
              className="sign__input"
              value={royaltyWallet}
              onChange={(e) => setRoyaltyWallet(e.target.value)}
            />
          </div>
          <div
            className="sign__group"
            style={{ width: 100, position: "relative" }}
          >
            <p className="cr__evcard-labelTitle">{t("royalty fee")}</p>
            <input
              type="number"
              className="sign__input"
              placeholder="0"
              value={royaltyFee}
              onChange={(e) => setRoyaltyFee(e.target.value)}
            />
            <div className="cr__evcard-price-symbol">%</div>
          </div>
        </div>
      </div>
      {/* <div
        className="sign__group cr__evcard-100"
        style={{ padding: "0 30px 0 53px" }}
      >
        <div className="sign__btn-second" onClick={add}>
          {t("add royalty")}
        </div>
      </div> */}
    </div>
  );
};

export default ItemRoyalty;
