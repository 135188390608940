// import { useState, useEffect } from "react";
import styles from "./index.module.css";

const ViewOfferModal = ({ offers }) => {
  return (
    <div className={styles.container}>
      {offers.map((item, index) => (
        <div key={index} className={styles.item}>
          <p className={styles.description}>{item.offer.description}</p>
          <p className={styles.url}>{item.offer.url}</p>
          <p className={styles.url}>{item.offer.date}</p>
        </div>
      ))}
    </div>
  );
};

export default ViewOfferModal;
