import { useTranslation } from "react-i18next";

const FilterItem = ({
  value,
  currentTag,
  setCurrentBtn,
  setCurrentTag,
}) => {
  const { t } = useTranslation();
  return (
    <div className="explorer__top-checkbox">
      <input
        id={value}
        name={value}
        type="checkbox"
        checked={currentTag === value}
        className="empty"
        onChange={() => {
          setCurrentTag(value);
          setCurrentBtn("");
        }}
      />
      <label htmlFor={value}>{t(value)}</label>
    </div>
  );
};

export default FilterItem;
