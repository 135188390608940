import React, { useState, useMemo } from "react";
import TransferCurrencyModal from "../custom_modals/transfer_currency_modal";
import { useAppContext } from "../../context/AppContext";

import styles from "./index.module.css";

const TokenItem = ({ ...props }) => {
  const { setModal, balance, addToast, bnbPrice } = useAppContext();
  const token = useMemo(() => {
    if (props.token_address === "bnb") {
      return {
        ...props,
        balance: balance,
        price: `$${(bnbPrice * balance).toFixed(2)} USD`,
      }
    } else {
      return props;
    }
  }, [props, balance, bnbPrice]);
  const [imageSrc, setImageSrc] = useState(props.imgSrc);
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
    setImageSrc("/img/wallet/bnb.svg");
  };

  const transferToken = () => {
    if (token.balance !== 0) {
      if (balance > 0) {
        setModal({
          open: true,
          children: <TransferCurrencyModal currency={token} />,
        });
      } else {
        addToast("You don't have bnb for transaction fee in your wallet.", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }
  };

  return (
    <div className={styles.token_block} onClick={transferToken}>
      <div className={styles.bnb__balance_block}>
        <div className={styles.token_info}>
          <div>
            {imageError ? (
              <img src={imageSrc} className={styles.coin_img} alt="coin logo" />
            ) : (
              <img
                src={imageSrc}
                className={styles.coin_img}
                alt="coin logo"
                onError={handleImageError}
              />
            )}
          </div>
          <div>
            <p className={styles.price_txt}>{token.coinName}</p>
            <p className={styles.mobile__price_txt}>
              {token.balance} {token.coinName}
            </p>
          </div>
        </div>
      </div>
      <p className={styles.price_txt}>{token.price}</p>
    </div>
  );
};

export default TokenItem;
