import { useState } from "react";
import Web3 from "web3";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { sendCoin } from "../../../api/ticket";
import TokenFailedModal from "../token_failed_modal";
import { isWalletValid } from "../../../utils";
import styles from "./index.module.css";

const TransferCurrencyModal = ({ currency }) => {
  const { setModal, setLoading, twoStepStatus } = useAppContext();
  const { userInfo } = useUserContext();
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  const [token, setToken] = useState("");
  const [step, setStep] = useState(0);
  const [amount, setAmount] = useState("");
  const [password, setPassword] = useState("");
  const [validation, setValidation] = useState("");
  const [passType, setPassType] = useState("password");
  const [imageSrc, setImageSrc] = useState(currency.imgSrc);
  const [imageError, setImageError] = useState(false);
  const web3 = new Web3("https://bsc-dataseed.binance.org");
  const handleImageError = () => {
    setImageError(true);
    setImageSrc("/img/wallet/bnb.svg");
  };

  const handleAddressChange = (value) => {
    setAddress(value);
  };

  const checkValidation = () => {
    if (address === "" || !isWalletValid(address)) {
      setValidation("address");
      return false;
    } else if (Number(amount) === 0) {
      setValidation("amount");
    } else if (Number(amount) > currency.balance) {
      setValidation("big-amount");
    } else if (!twoStepStatus && password === "") {
      setValidation("password");
      return false;
    } else if (twoStepStatus && token === "") {
      setValidation("token");
      return false;
    } else {
      setValidation("");
    }

    return true;
  };

  const cancel = () => {
    setModal({ open: false });
  };

  const transfer = async () => {
    if (step === 0) {
      if (
        isWalletValid(address) &&
        Number(amount) !== 0 &&
        Number(amount) <= currency.balance
      ) {
        setStep(1);
      } else {
        if (!checkValidation()) return;
      }
    } else {
      if (!checkValidation()) return;
      setLoading(true);
      const data = {
        userId: userInfo.user.id,
        email: userInfo.user.email,
        password,
        token,
        receiver_address: address,
        contract_address: currency.token_address,
        token_amount: Number(amount),
        decimals: currency.decimals,
        sender_address: userInfo.user.wallet_address,
      };
      const res = await sendCoin(data);
      setLoading(false);
      if (!res.success) {
        console.log(res.error);
        setModal({
          open: true,
          children: <TokenFailedModal />,
        });
      } else {
        setStep(2);
      }
    }
  };

  const setMaxAmount = async () => {
    const gasPrice = await web3.eth.getGasPrice();
    const gasLimit = 21000;
    const transactionFee = gasPrice * gasLimit;
    if (currency.token_address === "bnb") {
      setAmount((currency.balance * 10 ** 18 - transactionFee) / 10 ** 18);
    } else {
      setAmount(currency.balance);
    }
  };

  return (
    <div className={styles.modal_contain}>
      <div
        className={step === 2 ? styles.success_part_contain : styles.container}
      >
        {step !== 2 && (
          <div style={{ paddingBottom: 4 }}>
            <h4 className="modal__title">{t("Send Currency")}</h4>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.btn_close}
              onClick={() => setModal({ open: false })}
            >
              <path
                d="M18 6L6 18"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        {step === 2 && (
          <div style={{ textAlign: "center", marginBottom: 48 }}>
            <img
              alt="success"
              className={styles.success_icon}
              src="/img/wallet/check-circle.svg"
            />
            <p className={styles.success_title}>{t("success")}!</p>
            <p className={styles.success_text}>
              {t("Your transaction has been successfully processed.")}
            </p>
            {/* <div className={styles.divide_line}></div> */}
          </div>
        )}
        {step !== 0 && (
          <div className={styles.contain_wallet}>
            <div className={styles.wallet_item}>
              <div>
                {userInfo.user.avatar ? (
                  <img
                    src={`${userInfo.user.avatar}`}
                    className={styles.avatar}
                    alt="user"
                  />
                ) : (
                  <img
                    src="/img/avatars/avatar5.jpg"
                    className={styles.avatar}
                    alt="user"
                  />
                )}
              </div>
              <div className={styles.user_name}>
                <p style={{ marginBottom: 0, fontSize: 14 }}>
                  {userInfo.user.name.split(" ")[0]}
                </p>
                <img src="/img/icons/verified.svg" alt="data" />
              </div>
            </div>
            <img alt="arror" src="/img/wallet/arrow-right.svg" />
            <div className={styles.wallet_item}>
              <div>
                <img
                  src="/img/wallet/user.svg"
                  className={styles.avatar}
                  alt="user"
                />
              </div>
              <div className={styles.user_name}>
                <p style={{ marginBottom: 0, fontSize: 14 }}>
                  {address.substring(0, 4) +
                    "..." +
                    address.substring(address.length - 3, address.length)}
                </p>
              </div>
            </div>
          </div>
        )}
        {step === 0 ? (
          <div className={styles.digital__item__block}>
            <p className={styles.balance_top_txt}>{t("AVAILABLE BALANCE")}</p>
            <div className={styles.logo_contain}>
              <p className={styles.balance_txt}>
                {currency.balance} {currency.coinName}
              </p>
              <div>
                {imageError ? (
                  <img
                    src={imageSrc}
                    style={{ width: 24, borderRadius: 24 }}
                    alt="coin logo"
                  />
                ) : (
                  <img
                    src={imageSrc}
                    style={{ width: 24, borderRadius: 24 }}
                    alt="coin logo"
                    onError={handleImageError}
                  />
                )}
              </div>
            </div>
            <p className={styles.price_txt}>{currency.price}</p>
          </div>
        ) : (
          <div className={styles.digital__item__block}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 6,
              }}
            >
              <p className={styles.sending_txt}>
                {t("Sending")} {currency.coinName}
              </p>
              <div>
                <img
                  style={{ width: 20, borderRadius: 20 }}
                  alt="coin logo"
                  src={currency.imgSrc}
                />
              </div>
            </div>
            <p className={styles.sending_amount_txt}>{amount}</p>
            <p className={styles.sending_price}>
              ${(amount * currency.token_price).toFixed(2)} USD
            </p>
          </div>
        )}
        {step === 0 && (
          <div>
            <div className={styles.address_container}>
              <p style={{ marginBottom: 0, fontSize: 14 }}>To</p>
              <input
                type="text"
                className="sign__input address__input"
                placeholder="Enter public address..."
                value={address}
                onChange={(e) => handleAddressChange(e.target.value)}
              />
              {validation === "address" && (
                <span className="text-error">{t("Address Required")}*</span>
              )}
            </div>
            <div className={styles.amount_container}>
              <p style={{ marginBottom: 0, fontSize: 14 }}>{t("amount")}</p>
              <div className={styles.amount_block}>
                <div>
                  <div style={{ display: "flex" }}>
                    <input
                      type="number"
                      autoFocus
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      style={{
                        width:
                          amount.toString().replace(".", "").length * 10 +
                          (Number.isInteger(amount) ? 0 : 4) +
                          "px",
                      }}
                      className={styles.amount_input}
                      placeholder="0"
                    />
                    <p style={{ margin: "1px 0 0 0", fontSize: 14 }}>
                      {currency.coinName}
                    </p>
                  </div>
                  <p className={styles.price_amount_txt}>
                    ${(amount * currency.token_price).toFixed(2)} USD
                  </p>
                </div>
                <button
                  onClick={() => setMaxAmount()}
                  className={styles.btn_max}
                >
                  {t("max")}
                </button>
              </div>
              {validation === "amount" && (
                <span className="text-error">{t("Amount Required")}*</span>
              )}
              {validation === "big-amount" && (
                <span className="text-error">
                  {t("Amount is bigger than available balance")}
                </span>
              )}
            </div>
          </div>
        )}
        {step !== 0 && (
          <div>
            <div className={styles.contain_fee}>
              <p className={styles.txt_network}>{t("Network Fee")}</p>
              <p style={{ fontSize: 14 }}>$0.00048</p>
            </div>
          </div>
        )}
        {step === 2 && (
          <button className={styles.btn_exit} onClick={cancel}>
            {t("close")}
          </button>
        )}
      </div>
      {step !== 2 && (
        <div className={styles.bottom}>
          {step === 1 && (
            <form>
              {twoStepStatus ? (
                <div className="sign__group password__input">
                  <p style={{ marginBottom: 0, color: "white" }}>
                    {t("Enter passcode to confirm")}:
                  </p>
                  <input
                    type="text"
                    className="sign__input"
                    placeholder="Enter passcode..."
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                  />
                  {validation === "token" && (
                    <span className="text-error">
                      {t("passcode required")}*
                    </span>
                  )}
                </div>
              ) : (
                <div className="sign__group password__input">
                  <p style={{ marginBottom: 0, color: "white" }}>
                    {t("Enter your password to confirm")}:
                  </p>
                  <input
                    type={passType}
                    className="sign__input"
                    placeholder="Enter password..."
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passType === "password" && (
                    <img
                      src="/img/eye.png"
                      onClick={() => setPassType("text")}
                      className="img_eye"
                      style={{ top: 46 }}
                      alt="eye"
                    />
                  )}
                  {passType === "text" && (
                    <img
                      src="/img/eye-off.png"
                      onClick={() => setPassType("password")}
                      className="img_eye"
                      style={{ top: 46 }}
                      alt="eye"
                    />
                  )}
                  {validation === "password" && (
                    <span className="text-error">
                      {t("password required")}*
                    </span>
                  )}
                </div>
              )}
            </form>
          )}
          {step !== 2 && (
            <div style={{ display: "flex", gap: 14 }}>
              <button className={styles.btn_cancel} onClick={cancel}>
                {t("cancel")}
              </button>
              <button
                style={{ opacity: isWalletValid(address) ? 1 : 0.5 }}
                className={styles.btn_next}
                onClick={transfer}
              >
                {step === 0 ? t("next") : step === 1 ? t("send") : t("confirm")}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TransferCurrencyModal;
