import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import PermissionTable from "../../../components/permission_table";
import styles from "./index.module.css";

const PagePermission = () => {
  const { scrollItem } = useAppContext();
  const { userInfo } = useUserContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    (scrollItem.current as HTMLDivElement).scrollIntoView({
      behavior: "smooth",
    });
  }, [scrollItem]);

  useEffect(() => {
    if (!userInfo || userInfo.user.email !== process.env.REACT_APP_PERMISSION) {
      navigate("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <div className={styles.offer__container}>
      <p className={styles.title}>{t("permission")}</p>
      <PermissionTable />
    </div>
  );
};

export default PagePermission;
