import { useRef, useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
import { deleteCollectionById } from "../../../api/event";
import { ICollection } from "../../../utils/interface";
import Collection from "../../../components/collection";
import CarouselButton from "../../../components/carousel_button";
import { useAppContext } from "../../../context/AppContext";
import styles from "./index.module.css";

const Pastcollections = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const collectionCarousel = useRef<typeof OwlCarousel>(null);
  const { carouselItem, allCollections, setAllCollections } = useAppContext();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 450px)");
  const isTablet = useMediaQuery("(max-width: 800px)");
  const pastCollections = useMemo(
    () => allCollections.filter((collection: any) => collection.past === true),
    [allCollections]
  );

  const handleDeleteCollection = async (collectionId: string) => {
    deleteCollectionById(collectionId)
      .then((res) => {
        if (res.success) {
          let _collections = allCollections;
          _collections.forEach((element: ICollection, index: number) => {
            if (element.id === collectionId) {
              _collections.splice(index, 1);
            }
          });
          setAllCollections([..._collections]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const next = (eleRf: any) => {
    const ele: any = eleRf.current;
    ele.next(500);
  };

  const prev = (eleRf: any) => {
    const ele: any = eleRf.current;
    ele.prev(500);
  };

  return pastCollections.length > 0 ? (
    <section className="row row--grid" style={{ margin: 0, padding: 0 }}>
      <div className="col-12" style={{ padding: 0 }}>
        <h1 className={styles.main__sub__title}>{t("past concerts & events")}</h1>
      </div>

      <div className="col-12 p__zero">
        <div className="carousel-wrapper">
          <div
            className="left__arrow_btn"
            onClick={() => prev(collectionCarousel)}
          >
            <img src="/img/chevrons-left.svg" alt="left" />
          </div>
          <div
            className="right__arrow_btn"
            onClick={() => next(collectionCarousel)}
          >
            <img src="/img/chevrons-right.svg" alt="right" />
          </div>
          <CarouselButton
            prev={() => prev(collectionCarousel)}
            next={() => next(collectionCarousel)}
          />
          <OwlCarousel
            className="owl-theme"
            margin={12}
            items={carouselItem}
            autoplay={true}
            autoplayHoverPause={true}
            lazyLoad={true}
            dots={isTablet ? false : true}
            loop={pastCollections.length < 4 ? (isMobile ? true : false) : true}
            ref={collectionCarousel as any}
          >
            {pastCollections.map((collection: ICollection) => (
              <div key={collection.id} className="collection_block">
                <Collection
                  key={collection.id}
                  collection={collection}
                  handleDelete={handleDeleteCollection}
                />
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </section>
  ) : (
    <></>
  );
};

export default Pastcollections;
