// import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import styles from "./index.module.css";

const ScanTable = ({ tickets }) => {
  //   const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);

  const nextPage = () => {
    if (page < tickets.length / count) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [count]);
  return (
    <div className={styles.container}>
      <table className={styles.transaction_table}>
        <thead>
          <tr>
            <th>Sold Date</th>
            <th>User Name</th>
            <th>User Email</th>
            <th className={styles.transaction__last_col}>Scan Count</th>
          </tr>
        </thead>
        <tbody>
          {tickets?.map(
            (item, index) =>
              index >= (page - 1) * count &&
              index < page * count && (
                <tr className={styles.transaction_row} key={index}>
                  <td>{item?.createdAt.substring(0, 16).replace("T", " ")}</td>
                  <td>{item?.user_name}</td>
                  <td>{item?.email}</td>
                  <td className={styles.transaction__last_col}>
                    {item?.scan - item?.valid} of {item?.scan}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
      {tickets.length > count && (
        <div className={styles.transaction_pagination}>
          <div className={styles.tr__pagination_left}>
            <div className={styles.number_select} style={{ paddingRight: 2 }}>
              <p className={styles.show__numbers_txt}>Show:</p>
              <div style={{ position: "relative", display: "flex" }}>
                <select
                  className={styles.transaction__page_select}
                  name="page number"
                  id="page number"
                  value={count}
                  onChange={(e) => setCount(Number(e.target.value))}
                >
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                <img
                  className={styles.page__down_arrow}
                  src="/img/wallet/down_arrow.svg"
                  alt="down"
                />
              </div>
            </div>
            <p className={styles.show__numbers_txt}>
              Showing{" "}
              {tickets.length > count * page
                ? count
                : tickets.length - (page - 1) * count}{" "}
              out of {tickets.length} entries
            </p>
          </div>
          <div className={styles.tr__pagination_right}>
            <div className={styles.prev_page} onClick={() => prevPage()}>
              <img src="/img/wallet/chevron-left.svg" alt="left arrow" />
            </div>
            <div className={styles.number_select}>
              <p className={styles.show__numbers_txt}>
                Page {page} of {Math.ceil(tickets.length / count)}
              </p>
            </div>
            <div className={styles.next_page} onClick={() => nextPage()}>
              <img src="/img/wallet/chevron-right.svg" alt="right arrow" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScanTable;
