import OfferItem from "../../../components/offer_item";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../context/AppContext";
import styles from "./index.module.css";

const Offers = () => {
  const { t } = useTranslation();
  const { offers } = useAppContext();
  return (
    <div className={styles.wallet_content}>
      <div className={styles.wallet__title_part}>
        <img src="/img/wallet/offers_1.svg" alt="currencies" />
        <div>
          <p className={styles.wallet__content_title}>{t("offers")}</p>
        </div>
      </div>
      <div className={styles.offers_block}>
        <p className={styles.available_offer}>
          {offers.length} {t("offers available")}
        </p>
        {offers.map((offer: any, index: number) => (
          <OfferItem key={index} offer={offer} />
        ))}
        {/* <div className="transaction_pagination">
          <div className="tr__pagination_left">
            <div className="number_select">
              <p className="show__numbers_txt">Show:</p>
              <select
                className="transaction__page_select"
                name="page number"
                id="page number"
              >
                <option value="10">10</option>
                <option value="10">15</option>
                <option value="10">20</option>
              </select>
              <img
                className="page__down_arrow"
                src="/img/wallet/down_arrow.svg"
                alt="down"
              />
            </div>
            <p className="show__entries_txt">Showing 0 out of 0 entries</p>
          </div>
          <div className="tr__pagination_right">
            <div className="prev_page">
              <img src="/img/wallet/chevron-left.svg" alt="left arrow" />
            </div>
            <div className="number_select">
              <p className="show__numbers_txt">Page 1 of 1</p>
            </div>
            <div className="next_page">
              <img src="/img/wallet/chevron-right.svg" alt="right arrow" />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Offers;
