// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { getEventCardById } from "../../../api/event";
import { updateEventDescription } from "../../../api/event";
import JoditEditor from "jodit-react";
import "jodit";
import "jodit/build/jodit.min.css";
import styles from "./index.module.css";

const PageEventCardUpdate = () => {
  let { id } = useParams();
  const { setLoading } = useAppContext();
  const { userInfo } = useUserContext();
  const [eventCard, setEventCard] = useState<any>();
  const editor = useRef(null);
  const navigate = useNavigate();

  const getEventCardData = async () => {
    setLoading(true);
    await getEventCardById(id).then((res) => {
      console.log(res, id);
      if (res.success) {
        setEventCard(res.eventcard);
      } else {
        navigate("/");
      }
    });
    setLoading(false);
  };

  const updateDescription = async () => {
    setLoading(true);
    await updateEventDescription({
      id: eventCard?.id,
      description: editor.current.value,
    }).then((res) => {
      if (res.success) {
        console.log(res);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    if (userInfo && userInfo.user.user_type === "ADMIN") {
      getEventCardData();
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, id]);

  const editorConfig = {
    readonly: false,
    toolbar: false,
    language: "en",
    toolbarButtonSize: "medium",
    toolbarAdaptive: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    uploader: {
      insertImageAsBase64URI: false,
    },
    height: 100,
  };

  return (
    <div className={styles.item__detail_container}>
      <h1 className={styles.update__name}>{eventCard?.name}</h1>
      <div className="text-editor-wrapper-article">
        <JoditEditor
          config={editorConfig}
          ref={editor}
          value={
            eventCard?.description
              ? eventCard?.description
              : eventCard?.venue_description
          }
        />
      </div>
      <div className="sign__group" style={{ padding: "0 30px" }}>
        <div className="sign__btn" onClick={updateDescription} style={{ marginTop: 30, marginBottom: 200 }}>
          Update Description
        </div>
      </div>
    </div>
  );
};

export default PageEventCardUpdate;
