import { useAppContext } from "../../context/AppContext";

const FloorMapModal = ({ eventCard }) => {
  const { setModal } = useAppContext();
  return (
    <div style={style.container}>
      <span style={style.btn_close} onClick={() => setModal({ open: false })}>
        <svg
          style={{ marginTop: "-4px" }}
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            fill="#ffffff"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
        </svg>
      </span>
      <img
        src={`${process.env.REACT_APP_ASSET_BASE_URL}/${eventCard.picture_floormap}`}
        alt="no floor map"
        style={{
          maxWidth: "1400px",
          objectFit: "cover",
          width: "100%",
        }}
      />
    </div>
  );
};

const style = {
  container: {
    textAlign: "center",
    backgroundColor: "#14142f",
    color: "white",
    borderRadius: "10px",
    margin: "auto",
  },
  btn_close: {
    width: "20px",
    height: "25px",
    display: "inline-block",
    paddingBottom: "2px",
    borderRadius: "3px",
    verticalAlign: "top",
    cursor: "pointer",
    position: "absolute",
    top: "26px",
    right: "26px",
  },
};

export default FloorMapModal;
