import React from 'react';
import { Helmet } from 'react-helmet';

const CustomHelmet = ({ title, description, keywords, canonical, image }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <link rel="canonical" href={canonical} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
      <meta name="robots" content="index,follow" />
    </Helmet>
  );
};

export default CustomHelmet;
