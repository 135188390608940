import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../context/AppContext";
import { resetPassword } from "../../../api/auth";

const PageResetPassword = () => {
  const { code } = useParams();
  const { setLoading } = useAppContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [values, setValues] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [validations, setValidations] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const { addToast } = useAppContext();

  const handleChange = (prop: string, value: string) => {
    setValidations((prevState) => ({ ...prevState, [prop]: "" }));
    setValues({ ...values, [prop]: value });
  };

  const handleReset = () => {
    if (!checkvalidations()) return;

    setLoading(true);

    resetPassword({ password: values.newPassword, code: code })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          addToast("Password reseted. Please login", {
            appearance: "success",
            autoDismiss: true,
          });
          navigate("/signin");
        } else {
          addToast(res.message, { appearance: "error", autoDismiss: true });
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast("failed", { appearance: "error", autoDismiss: true });
      });
  };

  const checkvalidations = () => {
    if (values.newPassword === "") {
      setValidations({ newPassword: "has-empty", confirmPassword: "" });
      return false;
    } else if (!values.confirmPassword) {
      setValidations({ newPassword: "", confirmPassword: "has-empty" });
      return false;
    } else if (values.newPassword !== values.confirmPassword) {
      setValidations({ newPassword: "", confirmPassword: "has-danger" });
      return false;
    } else {
      setValidations({ newPassword: "", confirmPassword: "" });
    }

    return true;
  };

  return (
    <div className="container">
      <div className="row row--grid">
        <div className="col-12">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <Link to="/">{t("home")}</Link>
            </li>
            <li className="breadcrumb__item breadcrumb__item--active">
              {t("restore password")}
            </li>
          </ul>
        </div>

        <div className="col-12">
          <div>
            <div className="sign__content">
              <form action="#" className="sign__form">
                <Link to="/" className="sign__logo">
                  <LazyLoadImage src="/img/backstage_logo_horizontal_dark_background.svg" alt="data" />
                </Link>

                <div className="sign__group">
                  <input
                    type="password"
                    className="sign__input"
                    placeholder="New Password"
                    value={values.newPassword}
                    onChange={(e) =>
                      handleChange("newPassword", e.target.value)
                    }
                  />
                  {validations.newPassword === "has-empty" && (
                    <span className="text-error">{t("password required")}*</span>
                  )}
                </div>

                <div className="sign__group">
                  <input
                    type="password"
                    className="sign__input"
                    placeholder="Confirm Password"
                    value={values.confirmPassword}
                    onChange={(e) =>
                      handleChange("confirmPassword", e.target.value)
                    }
                  />
                  {validations.confirmPassword === "has-empty" && (
                    <span className="text-error">{t("confirm password required")}*</span>
                  )}
                  {validations.confirmPassword === "has-danger" && (
                    <span className="text-error">
                      {t("password must be matched")}
                    </span>
                  )}
                </div>

                <button
                  className="sign__btn"
                  type="button"
                  onClick={handleReset}
                >
                  {t("reset")}
                </button>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageResetPassword;
