/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getEventCardById } from "../../../api/event";
import { createPromoLink } from "../../../api/promo";
import { useUserContext } from "../../../context/UserContext";
import { useAppContext } from "../../../context/AppContext";
import { saveAs } from "file-saver";
import styles from "./index.module.css";
import { event } from "react-ga";

const PagePromoLink = () => {
  const { userInfo } = useUserContext();
  const { setLoading, addToast } = useAppContext();
  const [searchParams] = useSearchParams();
  const origin = searchParams.get("origin");
  const initialValidation = {
    promoter_name: "",
    discount: "",
    quantity: "",
  };
  const [validations, setValidations] = useState(initialValidation);
  const [values, setValues] = useState({
    promoter_name: "",
    discount: 0,
    quantity: 0,
    eventcard: origin,
  });
  const navigate = useNavigate();

  const { t } = useTranslation();

  const borderStyle = (str: string) => {
    return str === "has-empty" || str === "has-error"
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };

  const verifyAccess = async () => {
    setLoading(true);
    const res = await getEventCardById(origin);
    console.log(res);
    setLoading(false);
    if (res.success) {
      if (!userInfo) {
        navigate("/");
      } else {
        if (userInfo.user.user_type !== "ADMIN" && res.eventcard.creator.id !== userInfo.user.id) {
          navigate("/");
        }
      }
    } else {
      navigate("/");
    }
  };

  const handleChange = (prop: string, value: string) => {
    setValidations((prevState) => ({ ...prevState, [prop]: "" }));
    setValues({ ...values, [prop]: value });
  };

  const checkvalidations = () => {
    if (values.promoter_name === "") {
      setValidations({ ...initialValidation, promoter_name: "has-empty" });
      return false;
    } else if (values.discount === 0) {
      setValidations({ ...initialValidation, discount: "has-empty" });
      return false;
    } else if (values.discount > 100) {
      setValidations({ ...initialValidation, discount: "has-error" });
      return false;
    } else if (values.quantity === 0) {
      setValidations({ ...initialValidation, quantity: "has-empty" });
      return false;
    } else {
      setValidations({ ...initialValidation });
    }
    return true;
  };

  const generateLink = async () => {
    if (!checkvalidations()) {
      addToast("You need to input correct values for all the inputs", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    setLoading(true);
    const res = await createPromoLink(values);
    if (res.success) {
      let referral_links = process.env.REACT_APP_SITE_URL + "/promo_code_generate/" + origin + "/" + res.promo.id;
      let blob = new Blob([referral_links], { type: "text/plain;charset=utf-8" });
      saveAs(blob, "promo_link.txt");
    }
    setLoading(false);
  };

  useEffect(() => {
    verifyAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t("generate promo code links")}</h2>
      <div className={styles.promo_form}>
        <input
          id="promoter_name"
          type="text"
          name="promoter_name"
          className={styles.input}
          style={borderStyle(validations.promoter_name)}
          placeholder="Promoter name"
          onChange={(e) => handleChange("promoter_name", e.target.value)}
        />
        {validations.promoter_name === "has-empty" && (
          <p className={styles.error_text}>
            {t("promoter name is required")} *
          </p>
        )}
        <input
          id="discount"
          type="number"
          name="discount"
          className={styles.input}
          style={borderStyle(validations.discount)}
          placeholder="Discount"
          onChange={(e) => handleChange("discount", e.target.value)}
        />
        {validations.discount === "has-empty"  && (
          <p className={styles.error_text}>{t("discount is required")} *</p>
        )}
        {validations.discount === "has-error" && (
          <p className={styles.error_text}>{t("discount value should be less than 100")} *</p>
        )}
        <input
          id="quantity"
          type="number"
          name="quantity"
          className={styles.input}
          style={borderStyle(validations.quantity)}
          placeholder="Quantity"
          onChange={(e) => handleChange("quantity", e.target.value)}
        />
        {validations.quantity === "has-empty" && (
          <p className={styles.error_text}>{t("quantity is required")} *</p>
        )}
        <button className={styles.generate_btn} onClick={generateLink}>
          Generate
        </button>
      </div>
    </div>
  );
};

export default PagePromoLink;
