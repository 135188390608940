import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

export default function Loading({ open }) {
  return (
    <Backdrop style={{ color: '#fff', zIndex: 10000, }} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
