import api from "./api";

const createPromoLink = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/promo/create", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const createPromoCode = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/promo/create_code", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const verifyPromoCode = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/promo/verify_code", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export { createPromoLink, createPromoCode, verifyPromoCode };
