import { useEffect } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { ethers } from "ethers";
import Web3 from "web3";
import axios from "axios";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";

import { buyTicket } from "../../../api/event";
import { getTicketById } from "../../../api/event";
import { sendMailTicket } from "../../../api/message";
import useAnalyticsEventTracker from "../../../utils/ga";
import {
  USDTPayment_ABI,
  USDT_MAIN_ABI,
  XLAYERUSDTMainAddress,
  xlayer__paymentAddress_mainnet,
} from "../../../utils/payment_contract";
import PaymentSuccessModal from "../payment_success_modal";
import PaymentFailedModal from "../payment_failed_modal";
import useConvertedFiat from "../../../hooks/useConvertedFiat";
import useFiatSymbol from "../../../hooks/useFiatSymbol";
import { getEventPrice } from "../../../utils";
import client from "../../../utils/ipfs_client";
import { convertHtmlToString } from "../../../utils/convertHtmlToString";
import styles from "./index.module.css";

const OKXModal = ({ eventCard }) => {
  const {
    setLoading,
    setModal,
    country,
    addToast,
    discount,
    setDiscount,
    selectedSeats,
    rateEURvsUSD,
    rateTRYvsUSD,
    rateINRvsUSD,
    rateCLPvsUSD,
    rateGBPvsUSD,
    rateDKKvsUSD,
    rateSEKvsUSD,
    setTicketAmount,
    ticketAmount,
    media,
    trackId,
  } = useAppContext();
  const { userInfo } = useUserContext();
  const symbol = useFiatSymbol(country);
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 576px)");
  const gaEventTracker = useAnalyticsEventTracker("Buy Ticket");

  useEffect(() => {
    if (getEventPrice(eventCard) === 0) {
      handleBuyTicket(
        "free ticket",
        userInfo.user.wallet_address,
        "Binance Smart Chain"
      );
      setModal({ open: false });
    }
    if (selectedSeats.length > 0) {
      setTicketAmount(selectedSeats.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buyInUSDT = async (provide) => {
    const provider = new ethers.providers.Web3Provider(provide);
    const chainId = Number(provider.provider.chainId);
    if (chainId !== 196) {
      addToast("Please change the network X Layer", {
        appearance: "warning",
        autoDismiss: true,
      });
    } else {
      addToast("Please wait ... It might takes some time", {
        appearance: "warning",
        autoDismiss: true,
      });
      setLoading(true);
      try {
        const account = await provider.getSigner().getAddress();
        const USDT = new ethers.Contract(
          XLAYERUSDTMainAddress,
          USDT_MAIN_ABI,
          provider.getSigner()
        );
        const $provider = new ethers.providers.JsonRpcProvider(
          "https://rpc.xlayer.tech"
        );
        const $USDT = new ethers.Contract(
          XLAYERUSDTMainAddress,
          USDT_MAIN_ABI,
          $provider
        );
        const contract = new ethers.Contract(
          xlayer__paymentAddress_mainnet,
          USDTPayment_ABI,
          provider.getSigner()
        );
        const $price = calcPrice(eventCard.currency, getEventPrice(eventCard));
        const price = parseInt($price / ethers.BigNumber.from(1000000000000));
        const totalUSDT = await $USDT.balanceOf(account);
        if (ethers.BigNumber.from(totalUSDT).lt(price)) {
          addToast("You don't have enough USDT in your wallet", {
            appearance: "error",
            autoDismiss: true,
          });
          setLoading(false);
        } else {
          try {
            let txn = await USDT.approve(xlayer__paymentAddress_mainnet, price);
            await txn.wait();
            const _payees = JSON.parse(eventCard.payees).map(
              (payee) => payee.wallet
            );
            const _fees = JSON.parse(eventCard.payees).map((payee) =>
              Number(Number(payee.fee).toFixed())
            );
            const gasLimit = await contract.estimateGas.payWithBUSD(
              account,
              eventCard.owner_wallet,
              _payees,
              _fees,
              price
            );
            const gasPrice = await provider.getGasPrice();
            txn = await contract.payWithBUSD(
              account,
              eventCard.owner_wallet,
              _payees,
              _fees,
              price,
              {
                gasLimit,
                gasPrice
              }
            );
            await txn.wait();
            handleBuyTicket(txn.hash, account, "X Layer");
          } catch (error) {
            console.log(error);
            setLoading(false);
            setModal({
              open: true,
              children: <PaymentFailedModal />,
            });
          }
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        addToast(err.message, { appearance: "warning", autoDismiss: true });
      }
    }
  };

  const buyWithUSDT = async () => {
    let provide = null;
    if (isMobile) {
      if (!window.okxwallet) {
        const encodedDappUrl = encodeURIComponent(
          `https://backstage.global/event/eventcard/${eventCard.id}`
        );
        const deepLink = "okx://wallet/dapp/url?dappUrl=" + encodedDappUrl;
        const encodedUrl =
          "https://www.okx.com/download?deeplink=" +
          encodeURIComponent(deepLink);
        window.location.href = encodedUrl;
      } else {
        provide = window.okxwallet;
        const accounts = await provide.request({ method: "eth_accounts" });
        if (accounts.length === 0)
          await provide.request({ method: "eth_requestAccounts" });
        buyInUSDT(provide);
      }
    } else {
      if (window.okxwallet) {
        provide = window.okxwallet;
      }

      if (provide === null) {
        addToast("You need to install OKX wallet", {
          appearance: "warning",
          autoDismiss: true,
        });
        return;
      }

      const accounts = await provide.request({ method: "eth_accounts" });
      if (accounts.length === 0)
        await provide.request({ method: "eth_requestAccounts" });
      buyInUSDT(provide);
    }
  };

  const calcPrice = (currency, eventPrice) => {
    const _EURprice = ethers.BigNumber.from(
      Math.floor(rateEURvsUSD * 100000000)
    );
    const _INRprice = ethers.BigNumber.from(
      Math.floor(rateINRvsUSD * 100000000)
    );
    const _CLPprice = ethers.BigNumber.from(
      Math.floor(rateCLPvsUSD * 100000000)
    );
    const _DKKprice = ethers.BigNumber.from(
      Math.floor(rateDKKvsUSD * 100000000)
    );
    const _SEKprice = ethers.BigNumber.from(
      Math.floor(rateSEKvsUSD * 100000000)
    );
    const _TRYprice = ethers.BigNumber.from(
      Math.floor(rateTRYvsUSD * 100000000)
    );
    const _GBPprice = ethers.BigNumber.from(
      Math.floor(rateGBPvsUSD * 100000000)
    );
    const _USDprice = ethers.BigNumber.from(100000000);
    const price = (currency === "USD"
      ? _USDprice
      : currency === "INR"
      ? _INRprice
      : currency === "CLP"
      ? _CLPprice
      : currency === "DKK"
      ? _DKKprice
      : currency === "SEK"
      ? _SEKprice
      : currency === "EUR"
      ? _EURprice
      : currency === "TRY"
      ? _TRYprice
      : _GBPprice
    )
      .mul(ethers.BigNumber.from(ticketAmount))
      .mul(
        ethers.BigNumber.from(
          Math.floor(
            eventPrice * (1 - discount) * (1 + eventCard.tax / 100) * 1000
          )
        )
      )
      .mul(ethers.BigNumber.from(10000000));
    return price;
  };

  const handleBuyTicket = async (orderid, _wallet, _chain) => {
    setLoading(true);
    const tokenObject = {
      name: eventCard?.name,
      description:
        eventCard.category !== "Category2"
          ? convertHtmlToString(eventCard.venue_description)
          : convertHtmlToString(eventCard.description),
      image: eventCard.picture_ipfs,
      attributes: [
        {
          trait_type: "Price",
          value:
            getEventPrice(eventCard) *
            (1 - discount) *
            (1 + eventCard.tax / 100).toFixed(2),
        },
        { trait_type: "Currency", value: symbol },
        { trait_type: "Location", value: eventCard.location },
        { trait_type: "Date", value: eventCard.date },
        { trait_type: "Collection", value: eventCard.collection.name },
        {
          trait_type: "Addons",
          value: JSON.parse(eventCard.addons),
        },
      ],
    };
    const added = await client.add(JSON.stringify(tokenObject));
    const ipfs_url = `https://bkstage.infura-ipfs.io/ipfs/${added.path}`;
    const nftData = {
      contract: eventCard.NFT_address,
      IPFS_URL: ipfs_url,
      account: userInfo.user.wallet_address,
      picture_ipfs: eventCard.picture_ipfs,
    };
    const ticketData = {
      wallet_address: _wallet,
      blockchain: _chain,
      eventcard: eventCard.id,
      collection: eventCard.collection.id,
      price:
        getEventPrice(eventCard) *
        (1 - discount) *
        (1 + eventCard.tax / 100) *
        ticketAmount,
      pay_order_id: orderid,
      count: ticketAmount,
      buyer: userInfo.user.id,
      service_date: localStorage.getItem("service_date")
        ? new Date(localStorage.getItem("service_date"))
            .toString()
            .substring(0, 16)
        : null,
      seats: JSON.stringify(selectedSeats),
      discount: discount * 100,
      media,
    };
    const allData = {
      ticketData,
      nftData,
      trackId,
    };
    buyTicket(allData)
      .then((res) => {
        if (res.success) {
          gaEventTracker(
            "purchased",
            "Purchased " + ticketAmount + " of ticket: " + eventCard.name
          );
          handleBought(res.ticket.tokenURL, res.ticket.id);
        } else {
          setDiscount(0);
          setLoading(false);
          setModal({
            open: true,
            children: <PaymentFailedModal />,
          });
        }
      })
      .catch((error) => {
        setDiscount(0);
        setLoading(false);
        addToast("failed", { appearance: "error", autoDismiss: true });
        setModal({ open: false });
      });
  };

  const buyWithOKB = async () => {
    let provide = null;
    if (isMobile) {
      if (!window.okxwallet) {
        const encodedDappUrl = encodeURIComponent(
          `https://backstage.global/event/eventcard/${eventCard.id}`
        );
        const deepLink = "okx://wallet/dapp/url?dappUrl=" + encodedDappUrl;
        const encodedUrl =
          "https://www.okx.com/download?deeplink=" +
          encodeURIComponent(deepLink);
        window.location.href = encodedUrl;
      } else {
        provide = window.okxwallet;
        const accounts = await provide.request({ method: "eth_accounts" });
        if (accounts.length === 0)
          await provide.request({ method: "eth_requestAccounts" });
        buyInOKB(provide);
      }
    } else {
      if (window.okxwallet) {
        provide = window.okxwallet;
      }

      if (provide === null) {
        addToast("You need to install OKX wallet", {
          appearance: "warning",
          autoDismiss: true,
        });
        return;
      }

      const accounts = await provide.request({ method: "eth_accounts" });
      if (accounts.length === 0)
        await provide.request({ method: "eth_requestAccounts" });
      buyInOKB(provide);
    }
  };

  const buyInOKB = async (provide) => {
    const provider = new ethers.providers.Web3Provider(provide);
    const chainId = Number(provider.provider.chainId);
    if (chainId !== 196) {
      addToast("Please change the network X Layer", {
        appearance: "warning",
        autoDismiss: true,
      });
    } else {
      addToast("Please wait ... It might takes some time", {
        appearance: "warning",
        autoDismiss: true,
      });
      setLoading(true);
      try {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: "https://www.okx.com/priapi/v1/wallet/web/coin/price?coinIds=814",
        };
    
        const res = await axios.request(config);
        const okbPrice = parseFloat(res.data.data[0].price);
        const price =
          calcPrice(eventCard.currency, getEventPrice(eventCard)) /
          ethers.BigNumber.from(okbPrice * 100) /
          ethers.BigNumber.from(100);
        const web3 = new Web3(provide);
        const accounts = await web3.eth.getAccounts();
        const amountToSend = web3.utils.toWei(
          (price / 100000000000000).toFixed(5),
          "ether"
        );
        const transactionParameters = {
          from: accounts[0],
          to: eventCard.owner_wallet,
          value: amountToSend,
        };

        const txn = await web3.eth.sendTransaction(transactionParameters);
        console.log(txn);
        handleBuyTicket(txn.transactionHash, accounts[0], "X Layer");
      } catch (err) {
        setLoading(false);
        addToast(err.message, { appearance: "warning", autoDismiss: true });
      }
    }
  };

  const totalCurrencyPrice =
    useConvertedFiat(eventCard?.price, eventCard?.currency, country) *
      (selectedSeats.length === 0
        ? Number(ticketAmount)
        : selectedSeats.length) +
    " " +
    useFiatSymbol(country);

  const ticketCurrencyPrice =
    useConvertedFiat(eventCard?.price, eventCard?.currency, country) +
    " " +
    useFiatSymbol(country);

  const handleBought = async (tokenURL, ticketId) => {
    setLoading(true);
    const res = await getTicketById({ id: ticketId });
    let url = new URL(res.ticket.tokenURL);
    let params = new URLSearchParams(url.search);
    let token_id = params.get("a");
    const emailData = {
      mobile: isMobile,
      email: userInfo.user.email,
      ticket_number:
        selectedSeats.length === 0
          ? Number(ticketAmount)
          : selectedSeats.length,
      user_name: userInfo.user.name,
      ticketId,
      totalPrice: totalCurrencyPrice,
      ticketPrice: ticketCurrencyPrice,
      collection_name: eventCard.collection.name,
      scan: eventCard.scan,
      ticket_type: eventCard.collection.category,
      item: eventCard,
      seats: selectedSeats,
      addons: JSON.parse(eventCard.addons),
      start_now:
        eventCard.collection.name !== "Tulum Crypto Fest 2023" ? true : false,
      date: new Date(localStorage.getItem("service_date") || eventCard.date)
        .toString()
        .substring(0, 21),
      end_date: new Date(
        localStorage.getItem("service_date") || eventCard.end_date
      )
        .toString()
        .substring(0, 21),
      wallet: userInfo?.user?.wallet_address,
      nft_address: res.ticket.nft_address,
      token_id,
      country,
      tokenURL,
    };
    localStorage.removeItem("service_date");

    sendMailTicket(emailData)
      .then((res) => {
        setLoading(false);
        setDiscount(0);
        setModal({
          open: true,
          children: <PaymentSuccessModal />,
        });
      })
      .catch((err) => {
        setLoading(false);
        setDiscount(0);
        setModal({
          open: true,
          children: <PaymentFailedModal />,
        });
      });
  };

  useEffect(() => {
    if (discount === 1) {
      handleBuyTicket(
        "free ticket",
        userInfo.user.wallet_address,
        "Binance Smart Chain"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount]);

  return (
    <div className={styles.container}>
      <h4 className="modal__title">{t("proceed to Pay")}</h4>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.btn_close}
        onClick={() => setModal({ open: false })}
      >
        <path
          d="M18 6L6 18"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6L18 18"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <div>
        <button className={styles.btn_crypto} onClick={() => buyWithOKB()}>
          <img src="/img/okx.png" alt="bitkeep" className={styles.bitget_img} />
          <span
            style={{
              marginLeft: 12,
            }}
          >
            OKB <span style={{ fontSize: 12 }}>(X Layer)</span>
          </span>
        </button>
        <button className={styles.btn_crypto} onClick={() => buyWithUSDT()}>
          <img
            src="/img/usdt.png"
            alt="bitkeep"
            className={styles.bitget_img}
          />
          <span
            style={{
              marginLeft: 12,
            }}
          >
            USDT <span style={{ fontSize: 12 }}>(X Layer)</span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default OKXModal;
