import React, {
  createContext,
  useState,
  useContext,
  FC,
  useEffect,
  useRef,
} from "react";
import { useToasts } from "react-toast-notifications";
import PropTypes from "prop-types";

interface IMessage {
  open: boolean;
  title?: string;
  description?: string;
}

// export const appContextDefaultValue: AppContextValue = {}
interface AppContextInfterface {
  loading: boolean;
  setLoading?: any;
  message: IMessage;
  setMessage?: any;
  ticketData: string;
  setTicketData?: any;
  country: string;
  setCountry?: any;
  modal: any;
  setModal?: any;
  settings?: any;
  setSettings?: any;
  isCookiePopup: boolean;
  setCookiePopup?: any;
  searchStr: string;
  setSearchStr?: any;
  scrollItem?: any;
  carouselItem: number;
  setCarouselItem?: any;
  rateEURvsUSD: number;
  rateTRYvsUSD: number;
  rateINRvsUSD: number;
  rateGBPvsUSD: number;
  rateCLPvsUSD: number;
  rateDKKvsUSD: number;
  rateSEKvsUSD: number;
  setRateEURvsUSD?: any;
  setRateTRYvsUSD?: any;
  setRateINRvsUSD?: any;
  setRateGBPvsUSD?: any;
  setRateCLPvsUSD?: any;
  setRateDKKvsUSD?: any;
  setRateSEKvsUSD?: any;
  pageNumber: number;
  setPageNumber?: any;
  allItems: any;
  setAllItems?: any;
  allCollections: any;
  setAllCollections?: any;
  addToast?: any;
  cPageNumber: number;
  setCPageNumber?: any;
  paymentModal: boolean;
  setPaymentModal?: any;
  selectedSeats: any;
  setSelectedSeats?: any;
  discount: number;
  setDiscount?: any;
  tablePackage: number;
  setTablePackage?: any;
  digitalItems: any;
  setDigitalItems?: any;
  tokens: any;
  setTokens?: any;
  balance: number;
  bnbPrice: number;
  setBalance?: any;
  setBnbPrice?: any;
  transactions: any;
  setTransactions?: any;
  erc20Transactions: any;
  setErc20Transactions?: any;
  erc721Transactions: any;
  setErc721Transactions?: any;
  permission: any;
  setPermission?: any;
  ticketAmount: number;
  setTicketAmount?: any;
  media: string;
  setMedia?: any;
  twoStepStatus: boolean;
  setTwoStepStatus?: any;
  offers: any;
  setOffers?: any;
  trackId: string;
  setTrackId?: any;
}

const defaultState: AppContextInfterface = {
  loading: false,
  message: { open: false },
  ticketData: "null",
  country: "",
  modal: { open: false },
  isCookiePopup: false,
  searchStr: "",
  carouselItem: 4,
  rateEURvsUSD: 1.0940691604879111,
  rateTRYvsUSD: 0.03124970410436426,
  rateINRvsUSD: 0.012083029309212127,
  rateGBPvsUSD: 1.2841833556995268,
  rateCLPvsUSD: 0.001034671741414259,
  rateDKKvsUSD: 0.14670532841088055,
  rateSEKvsUSD: 0.09486113515577385,
  pageNumber: 0,
  allItems: [],
  cPageNumber: 0,
  paymentModal: false,
  selectedSeats: [],
  discount: 0,
  allCollections: [],
  tablePackage: 0,
  digitalItems: [],
  tokens: [],
  bnbPrice: 0,
  balance: 0,
  transactions: [],
  erc20Transactions: [],
  erc721Transactions: [],
  permission: {},
  ticketAmount: 1,
  media: "",
  twoStepStatus: false,
  offers: [],
  trackId: "",
};

export const AppContext = createContext(defaultState);

const AppContextProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ open: false } as IMessage);
  const [modal, setModal] = useState({ open: false });
  const [settings, setSettings] = useState([]);
  const [isCookiePopup, setCookiePopup] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [country, setCountry] = useState("");
  const [discount, setDiscount] = useState(0);
  const [rateEURvsUSD, setRateEURvsUSD] = useState(1.0940691604879111);
  const [rateTRYvsUSD, setRateTRYvsUSD] = useState(0.03124970410436426);
  const [rateINRvsUSD, setRateINRvsUSD] = useState(0.012083029309212127);
  const [rateGBPvsUSD, setRateGBPvsUSD] = useState(1.2841833556995268);
  const [rateCLPvsUSD, setRateCLPvsUSD] = useState(0.001034671741414259);
  const [rateDKKvsUSD, setRateDKKvsUSD] = useState(0.14670532841088055);
  const [rateSEKvsUSD, setRateSEKvsUSD] = useState(0.09486113515577385);
  const [pageNumber, setPageNumber] = useState(0);
  const [cPageNumber, setCPageNumber] = useState(0);
  const [ticketData, setTicketData] = useState("null");
  const scrollItem = useRef();
  const [carouselItem, setCarouselItem] = useState(4);
  const [allItems, setAllItems] = useState([]);
  const [allCollections, setAllCollections] = useState([]);
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [tablePackage, setTablePackage] = useState(0);
  const [digitalItems, setDigitalItems] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [erc20Transactions, setErc20Transactions] = useState([]);
  const [erc721Transactions, setErc721Transactions] = useState([]);
  const [balance, setBalance] = useState(0);
  const [bnbPrice, setBnbPrice] = useState(0);
  const [ticketAmount, setTicketAmount] = useState(1);
  const { addToast } = useToasts();
  const [permission, setPermission] = useState(null);
  const [media, setMedia] = useState("");
  const [twoStepStatus, setTwoStepStatus] = useState(false);
  const [offers, setOffers] = useState([]);
  const [trackId, setTrackId] = useState("");
  useEffect(() => {
    if (localStorage.getItem("consent") === null) setCookiePopup(true);
  }, []);

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        message,
        setMessage,
        country,
        setCountry,
        modal,
        setModal,
        settings,
        setSettings,
        isCookiePopup,
        setCookiePopup,
        searchStr,
        setSearchStr,
        scrollItem,
        ticketData,
        setTicketData,
        carouselItem,
        setCarouselItem,
        rateEURvsUSD,
        rateGBPvsUSD,
        rateTRYvsUSD,
        rateINRvsUSD,
        rateCLPvsUSD,
        rateDKKvsUSD,
        rateSEKvsUSD,
        setRateEURvsUSD,
        setRateGBPvsUSD,
        setRateTRYvsUSD,
        setRateINRvsUSD,
        setRateCLPvsUSD,
        setRateDKKvsUSD,
        setRateSEKvsUSD,
        pageNumber,
        setPageNumber,
        allItems,
        setAllItems,
        addToast,
        cPageNumber,
        setCPageNumber,
        paymentModal,
        setPaymentModal,
        selectedSeats,
        setSelectedSeats,
        discount,
        setDiscount,
        allCollections,
        setAllCollections,
        tablePackage,
        setTablePackage,
        digitalItems,
        setDigitalItems,
        tokens,
        setTokens,
        balance,
        setBalance,
        bnbPrice,
        setBnbPrice,
        transactions,
        setTransactions,
        erc20Transactions,
        setErc20Transactions,
        erc721Transactions,
        setErc721Transactions,
        permission,
        setPermission,
        ticketAmount,
        setTicketAmount,
        media,
        setMedia,
        twoStepStatus,
        setTwoStepStatus,
        offers,
        setOffers,
        trackId,
        setTrackId,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.object,
};

export default AppContextProvider;
export const useAppContext = () => useContext(AppContext);
