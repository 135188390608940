import { useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { sendDigitalItem } from "../../../api/ticket";
import { isVideoFile } from "../../../utils";
import TokenFailedModal from "../token_failed_modal";
import { getTicketsFromNFTs } from "../../../api/event";
import { validateEmail } from "../../../utils";

import styles from "./index.module.css";

const TransferNFTModal = ({ ticket }) => {
  const { setModal, setLoading, setDigitalItems, twoStepStatus } = useAppContext();
  const { userInfo } = useUserContext();
  const isMobile = useMediaQuery("(max-width: 576px)");
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  const [token, setToken] = useState("");
  const [step, setStep] = useState(0);
  const [password, setPassword] = useState("");
  const [validation, setValidation] = useState("");
  const [passType, setPassType] = useState("password");

  const handleAddressChange = (value) => {
    setValidation("");
    setAddress(value);
  };

  const fetchNfts = async () => {
    axios
      .get(
        "https://deep-index.moralis.io/api/v2/" +
          userInfo?.user?.wallet_address +
          "/nft?chain=bsc&format=decimal",
        {
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": process.env.REACT_APP_MORALIS,
          },
        }
      )
      .then(async (response) => {
        console.log(response.data.result);
        let tokenURLs = [];
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          tokenURLs.push(
            "https://bscscan.com/token/" +
              element.token_address +
              "?a=" +
              element.token_id
          );
        }
        const res = await getTicketsFromNFTs({ tokenURLs });
        sortTickets(res.tickets);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const sortTickets = (array) => {
    setDigitalItems([
      ...array.sort(
        (item1, item2) =>
          new Date(item2.createdAt).getTime() -
          new Date(item1.createdAt).getTime()
      ),
    ]);
  };

  const checkValidation = () => {
    if (!validateEmail(address)) {
      setValidation("address");
      return false;
    } else if (!twoStepStatus && password === "") {
      setValidation("password");
      return false;
    } else if (twoStepStatus && token === "") {
      setValidation("token");
      return false;
    } else {
      setValidation("");
    }

    return true;
  };

  const cancel = () => {
    setModal({ open: false });
  };

  const transfer = async () => {
    if (step === 0) {
      if (validateEmail(address)) {
        setStep(1);
      } else {
        if (!checkValidation()) return;
      }
    } else {
      let url = new URL(ticket.tokenURL);
      let params = new URLSearchParams(url.search);
      let token_id = params.get("a");
      if (!checkValidation()) return;
      setLoading(true);
      const data = {
        userId: userInfo.user.id,
        email: userInfo.user.email,
        password,
        token,
        receipt_address: address,
        contract_address: ticket.nft_address,
        token_id,
        owner: userInfo.user.wallet_address,
        data: ticket,
        mobile: isMobile,
      };
      const res = await sendDigitalItem(data);
      setLoading(false);
      if (!res.success) {
        setModal({
          open: true,
          children: <TokenFailedModal />,
        });
      } else {
        if (res.status === "sent") {
          setStep(2);
          fetchNfts();
        } else {
          setStep(3);
        }
      }
    }
  };

  return (
    <div className={styles.modal_contain}>
      <div
        className={
          step === 3
            ? styles.pending_part_contain
            : step === 2
            ? styles.success_part_contain
            : styles.container
        }
      >
        {step !== 2 && (
          <div style={{ paddingBottom: 4 }}>
            <h4 className="modal__title">{t("Send Digital Item")}</h4>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.btn_close}
              onClick={() => setModal({ open: false })}
            >
              <path
                d="M18 6L6 18"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        {step === 2 && (
          <div style={{ textAlign: "center", marginBottom: 48 }}>
            <img
              alt="success"
              className={styles.success_icon}
              src="/img/wallet/check-circle.svg"
            />
            <p className={styles.success_title}>{t("success")}!</p>
            <p className={styles.success_text}>
              {t("Your transaction has been successfully processed.")}
            </p>
          </div>
        )}
        {step === 3 && (
          <div style={{ textAlign: "center", marginBottom: 48 }}>
            <img
              alt="success"
              className={styles.success_icon}
              src="/img/wallet/check-circle_pending.svg"
            />
            <p className={styles.success_title}>{t("Success, transaction pending")}</p>
            <p className={styles.success_text}>
              The recipient of this transaction ({address}) does not have a
              Backstage Marketplace account. They can claim this transaction
              after creating one.
            </p>
            <p className={styles.success_text}>{t("We have sent them an email with a signup link.")}</p>
          </div>
        )}
        {step !== 0 && (
          <div className={styles.contain_wallet}>
            <div className={styles.wallet_item}>
              <div>
                {userInfo.user.avatar ? (
                  <img
                    src={`${userInfo.user.avatar}`}
                    className={styles.avatar}
                    alt="user"
                  />
                ) : (
                  <img
                    src="/img/avatars/avatar5.jpg"
                    className={styles.avatar}
                    alt="user"
                  />
                )}
              </div>
              <div className={styles.user_name}>
                <p style={{ marginBottom: 0, fontSize: 14 }}>
                  {userInfo.user.name.split(" ")[0]}
                </p>
                <img src="/img/icons/verified.svg" alt="data" />
              </div>
            </div>
            <img alt="arror" src="/img/wallet/arrow-right.svg" />
            <div className={styles.wallet_item}>
              <div>
                <img
                  src="/img/wallet/user.svg"
                  className={styles.avatar}
                  alt="user"
                />
              </div>
              <div className={styles.user_name}>
                <p style={{ marginBottom: 0, fontSize: 14 }}>
                  {address.substring(0, 10) + " .."}
                </p>
              </div>
            </div>
          </div>
        )}
        <div className={styles.digital__item__block}>
          <div className={styles.top_part}>
            {ticket.eventcard.category === "Category1" ? (
              <>
                <div className={styles.ticket_item}>
                  <img src="/img/wallet/smart_ticket.svg" alt="item" />
                </div>
                <p className={styles.item_title}>{t("smart ticket")}</p>
              </>
            ) : ticket.eventcard.category === "Category3" ? (
              <>
                <div className={styles.service_item}>
                  <img src="/img/wallet/service_item.svg" alt="item" />
                </div>
                <p className={styles.item_title}>{t("service")}</p>
              </>
            ) : (
              <>
                <div className={styles.digital_item}>
                  <img src="/img/wallet/digital_item.svg" alt="item" />
                </div>
                <p className={styles.item_title}>{t("digital art")}</p>
              </>
            )}
          </div>
          <div className={styles.ticket_content}>
            <div className={styles.ticket__img_block}>
              {isVideoFile(ticket.eventcard.picture_large) ? (
                <video autoPlay playsInline loop muted className={styles.video}>
                  <source
                    src={`${process.env.REACT_APP_ASSET_BASE_URL}/${ticket.eventcard.picture_large}`}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  style={{ width: 80, height: 80, borderRadius: 6 }}
                  src={`${process.env.REACT_APP_ASSET_BASE_URL}/${ticket.eventcard.picture_large}`}
                  alt="NFT"
                />
              )}
              <div className={styles.ticket__title_block_sm}>
                <p className={styles.item_name}>{ticket.eventcard.name}</p>
                <p className={styles.item_location}>
                  {ticket.eventcard.location}
                </p>
              </div>
            </div>
          </div>
        </div>
        {step === 0 && (
          <div className={styles.address_container}>
            <p style={{ marginBottom: 0, fontSize: 14 }}>To</p>
            <input
              type="text"
              className="sign__input address__input"
              placeholder="Enter recipient email address..."
              value={address}
              onChange={(e) => handleAddressChange(e.target.value)}
            />
            {validation === "address" && (
              <span className="text-error">{t("valid email required")}*</span>
            )}
          </div>
        )}
        {step !== 0 && (
          <div>
            <div className={styles.contain_fee}>
              <p className={styles.txt_network}>{t("Network Fee")}</p>
              <p style={{ fontSize: 14 }}>$0.00048</p>
            </div>
          </div>
        )}
        {(step === 2 || step === 3) && (
          <button className={styles.btn_exit} onClick={cancel}>
            {t("close")}
          </button>
        )}
      </div>
      {step !== 2 && step !== 3 && (
        <div className={styles.bottom}>
          {step === 1 && (
            <form>
            {twoStepStatus ? (
              <div className="sign__group password__input">
                <p style={{ marginBottom: 0, color: "white" }}>
                  {t("Enter passcode to confirm")}:
                </p>
                <input
                  type="text"
                  className="sign__input"
                  placeholder="Enter passcode..."
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                />
                {validation === "token" && (
                  <span className="text-error">
                    {t("passcode required")}*
                  </span>
                )}
              </div>
            ) : (
              <div className="sign__group password__input">
                <p style={{ marginBottom: 0, color: "white" }}>
                  {t("Enter your password to confirm")}:
                </p>
                <input
                  type={passType}
                  className="sign__input"
                  placeholder="Enter password..."
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {passType === "password" && (
                  <img
                    src="/img/eye.png"
                    onClick={() => setPassType("text")}
                    className="img_eye"
                    style={{ top: 46 }}
                    alt="eye"
                  />
                )}
                {passType === "text" && (
                  <img
                    src="/img/eye-off.png"
                    onClick={() => setPassType("password")}
                    className="img_eye"
                    style={{ top: 46 }}
                    alt="eye"
                  />
                )}
                {validation === "password" && (
                  <span className="text-error">
                    {t("password required")}*
                  </span>
                )}
              </div>
            )}
          </form>
          )}
          {step !== 2 && (
            <div style={{ display: "flex", gap: 14 }}>
              <button className={styles.btn_cancel} onClick={cancel}>
                {t("cancel")}
              </button>
              <button
                style={{ opacity: validateEmail(address) ? 1 : 0.5 }}
                className={styles.btn_next}
                onClick={transfer}
              >
                {step === 0 ? t("next") : step === 1 ? t("send") : t("confirm")}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TransferNFTModal;
