// import { useTranslation } from "react-i18next";
import { Checkbox } from "@mui/material";
import React, { useState, useEffect } from "react";
import styles from "./index.module.css";

const OfferCreateTable = ({ data, page, count, updateOfferItems }) => {
  //   const { t } = useTranslation();
  const initialChildStates = Array.from({ length: data.length }, () => false);
  const [childStates, setChildStates] = useState(initialChildStates);

  function getQueryParamA(url) {
    const urlObj = new URL(url);
    return urlObj.searchParams.get("a");
  }

  const getTokenID = (url) => {
    let value = "";
    if (url) {
      const urlArray = url.split("___");
      urlArray.forEach((url, index) => {
        const id = getQueryParamA(url);
        value += id;
      });
      return value;
    }
  };

  const handleChangeChild = (index) => (event) => {
    setChildStates((prevChildStates) => {
      const updatedChildStates = [...prevChildStates];
      updatedChildStates[index] = event.target.checked;
      return updatedChildStates;
    });
  };

  const handleParentChange = (event) => {
    setChildStates(
      Array.from({ length: data.length }, () => event.target.checked)
    );
  };

  useEffect(() => {
    updateOfferItems(childStates);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childStates]);

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th style={{ width: 70 }}>
            <Checkbox
              checked={childStates.every((state) => state)}
              indeterminate={
                childStates.some((state) => state) &&
                !childStates.every((state) => state)
              }
              onChange={handleParentChange}
            />
          </th>
          <th>Name</th>
          <th>Email</th>
          <th>Contract</th>
          <th className="transaction__last_col">Token ID</th>
        </tr>
      </thead>
      <tbody>
        {data.map(
          (item, index) =>
            index >= (page - 1) * count &&
            index < page * count && (
              <tr className="transaction_row" key={index}>
                <td>
                  <Checkbox
                    checked={childStates[index]} onChange={handleChangeChild(index)} 
                  />
                </td>
                <td>{item.buyer.name}</td>
                <td>{item.buyer.email}</td>
                <td>{item.nft_address}</td>
                <td className="transaction__last_col">
                  {getTokenID(item?.tokenURL)}
                </td>
              </tr>
            )
        )}
      </tbody>
    </table>
  );
};

export default OfferCreateTable;
