import { useEffect } from "react";
import { useAppContext } from "../../../context/AppContext";
import GetStarted from "./getstarted";
import Hotcollections from "./hotcollections";
import Pastcollections from "./pastcollections";
// import LastArts from "./lastarts";
// import Lastevents from "./lastevents";
// import LastServices from "./lastservices";
// import Nftevents from "./nftevents";
import Hero from "./hero";
// import BigHero from "./bighero";
import { getAllCollections } from "../../../api/event";
import { ICollection } from "../../../utils/interface";
import CustomHelmet from "../../../components/CustomHelmet";

const Home = () => {
  const {
    setLoading,
    setCPageNumber,
    allCollections,
    setAllCollections,
  } = useAppContext();
  useEffect(() => {
    setCPageNumber(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const sortCollections = (array: never[]) => {
    setAllCollections(
      array.sort(
        (item1: ICollection, item2: ICollection) =>
          new Date(item2.createdAt).getTime() -
          new Date(item1.createdAt).getTime()
      )
    );
  };

  const getAllData = async () => {
    setLoading(true);
    if (allCollections.length === 0) {
      await getAllCollections().then((res) => {
        if (res.success) {
          sortCollections(res.collections);
        }
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <main className="main">
      <CustomHelmet
        title="Backstage.global"
        description="Pioneering the event industry with blockchain technologies"
        keywords="Backstage.global, Ha-Ash, Tulum Crypto Festival, Wayra by Fuerza Bruta, Perfect Harmony Productions, Zingara, La Troya, Danish Music Awards, Zen Experience Tulum, Aspendos, Studio G, Emergence Festival, BLUE MARLIN IBIZA, 7 Towers, Ahau, AFL, ALTAR, Artelize, Backstage"
        image={`https://backstage.global/img/logo/Mark/backstage_mark.png`}
        canonical={window.location.href}
      />
      {/* <BigHero /> */}
      <Hero />
      <div className="home__container">
        <Hotcollections />
        <Pastcollections />
        {/* <Lastevents />
        <LastArts />
        <LastServices /> */}
        <GetStarted />
        <div className="bottom_block"></div>
      </div>
    </main>
  );
};

export default Home;
