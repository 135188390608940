import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import FilterItem from "./FilterItem";
import styles from "./index.module.css";

const FilterSort = ({
  currentBtn,
  currentTag,
  sort,
  setCurrentBtn,
  setCurrentTag,
  setSort,
}) => {
  const isMobile = useMediaQuery("(max-width: 450px)");
  const { t } = useTranslation();
  const filterList = [
    "all",
    "festival",
    "concert",
    "club",
    "show",
    "meet & greet",
    "awards",
    "experience",
    "sport",
    "hospitality",
    "platform",
    "art",
    "store",
    "cirque",
  ];
  return (
    <div className={styles.filter__sort_container}>
      <div>
        <button
          className={
            currentBtn === "filter" ? styles.btn_active : styles.btn_inactive
          }
          onClick={() =>
            currentBtn === "" ? setCurrentBtn("filter") : setCurrentBtn("")
          }
        >
          <img src="/img/icons/filter.svg" alt="filter" />
          <p className={styles.btn_text}>{t("filter")}</p>
          {!isMobile && <img src="/img/icons/dropdown.svg" alt="dropdown" />}
        </button>
        {currentBtn === "filter" && (
          <div className={styles.filter__options_contain}>
            {filterList.map((item, index) => (
              <FilterItem
                value={item}
                key={index}
                currentTag={currentTag}
                setCurrentBtn={setCurrentBtn}
                setCurrentTag={setCurrentTag}
              />
            ))}
          </div>
        )}
      </div>
      <div>
        <button
          className={
            currentBtn === "sort" ? styles.btn_active : styles.btn_inactive
          }
          onClick={() =>
            currentBtn === "" ? setCurrentBtn("sort") : setCurrentBtn("")
          }
        >
          <img src="/img/icons/sort.svg" alt="sort" />
          <p className={styles.btn_text}>{t("sort")}</p>
          {!isMobile && <img src="/img/icons/dropdown.svg" alt="dropdown" />}
        </button>
        {currentBtn === "sort" && (
          <div className={styles.sort__options_contain}>
            <div className="explorer__top-checkbox">
              <input
                id="recent"
                name="recent"
                type="checkbox"
                checked={sort === 0}
                className="empty"
                onChange={() => {
                  setSort(0);
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="recent">{t("recently listed")}</label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterSort;
