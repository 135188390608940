const Faqs = [
  {
    question: "How do I create an account?",
    answer: `Click the Sign in button. If you don’t have an account, click the Sign
        Up button. Then, create a name for yourself. Add your personal
        information and create a password. Agree to the Privacy Policy and
        agree to the Terms and Conditions. Finally click ‘I’m Not a Robot’. 
        <br />
        <br />
        After clicking sign up, you’ll see a new email in your account. Verify
        the email by clicking the link. (If you don't find the email check
        your spam also)
        <br />
        <br />
        That’s it! Congratulations, you’ve been verified.`,
  },
  {
    question: "How to buy a smart ticket on the Backstage platform?",
    answer: `
    <ol>
        <li>
          Visit backstage.global and register if you don't have an account. Find
          the event and ticket you're interested in.
        </li>
        <li>
          Click on "BUY" and select your desired position on the venue map.
          Choose the number of tickets.
        </li>
        <li>
          Click on "BUY TICKET/S" to proceed.
        </li>
        <li>
          Confirm the terms & conditions and select your preferred payment
          method. Fill out the payment form.
        </li>
        <li>
          After completing the purchase, you will receive an email with a QR
          code in the pdf attachment.
        </li>
      </ol>
    You need to show the QR code at the entrance of the event.`,
  },
  {
    question: "What if I encounter a bug or issue while shopping?",
    answer: `
    <ol>
        <li>
            Clear the cache and cookies in your browser.
        </li>
        <li>
            Refresh the website to see if the correct price is displayed.
        </li>
        <li>
            If the issue persists, try using a different browser to access the website.
        </li>
      </ol>`,
  },
  {
    question: "What should I do if I didn't receive the confirmation email after the purchase?",
    answer: `
    <ol>
        <li>
            Log in to your Backstage account.
        </li>
        <li>
            In the upper right corner, you will see your username. Click on the downward arrow next to your username.
        </li>
        <li>
            From the dropdown menu, select "Activity".
        </li>
        <li>
            You should see your ticket listed along with an option to "Resend email". Click on that option to have the email resent to you.
        </li>
      </ol>`,
  },
  {
    question: "I received a message stating that my payment was unsuccessful. What can I do?",
    answer: `
    <ol>
        <li>
            Call your bank: Contact your bank to inquire about any potential restrictions or issues with your card. They can provide you with specific information regarding the transaction and verify if there are any problems on their end.
            <ol style="padding-left: 15px;">
                <li>
                    Check 3D Secure authentication: Confirm with your bank whether the 3D Secure authentication feature is enabled for your card. This additional security measure may be causing the payment failure, and your bank can guide you on how to proceed.
                </li>
            </ol>
        </li>
        <li>
            Avoid multiple attempts: It is important not to make multiple payment attempts before consulting with your bank and our team. Excessive failed attempts can trigger security measures that may result in your card being blocked. It's best to address the issue with the relevant parties first.
        </li>
        <li>
            Clear cache and cookies: After contacting your bank, it's a good practice to clear your browser's cache and cookies. Sometimes, outdated or corrupted data can interfere with the payment process. Clearing cache and cookies helps ensure a fresh start.
            <ol style="padding-left: 15px;">
                <li>
                    Refresh your browser: Once you have cleared your cache and cookies, refresh your browser before attempting the payment again. This ensures that any cached or stored information is updated, reducing the chances of encountering the same issue
                </li>
            </ol>
        </li>
        <li>
            Contact customer support: If you have followed the above steps and are still experiencing the same payment issue, please reach out to our customer support team. They will assist you further in resolving the problem and ensuring a successful payment transaction.
        </li>
      </ol>`,
  },
  {
    question: "What can I buy on the Backstage Marketplace?",
    answer: `You can buy smart tickets to a variety of live and virtual events, including sports games, music concerts, MMA fights and more. You can also purchase smart tickets, experiences and memberships for hotels, sports leagues, clubs, restaurants and more. You can even buy digital art. 
        <br />
        <br />
        Have a look at our current offerings here: <a style="color: white; text-decoration: underline;" href="https://backstage.global">backstage.global </a>`,
  },
  {
    question: "How do I navigate the Backstage Marketplace?",
    answer: `You can scroll through the ticket listings or use the Filter or Sort functions to find what you’re looking for.
    <img class="faq__item_img" src="/img/explorer.png" alt="explorer" />
    <br />
    <br />
    You can also use the Search function at the top to find the ticket or art collection you’re looking for.
    <img class="faq__item_img" src="/img/search.png" alt="search" />`,
    answer_mobile: `You can scroll through the ticket listings or use the Filter or Sort functions to find what you’re looking for.
    <img class="faq__item_img" src="/img/explorer_mobile.png" alt="explorer" />
    <br />
    <br />
    You can also use the Search function at the top to find the ticket or art collection you’re looking for.
    <img class="faq__item_img" src="/img/search_mobile.png" alt="search" />`,
  },
  {
    question: "Do I need a crypto wallet to use the Backstage Marketplace?",
    answer: `No, you can pay for tickets with a debit or credit card, and use the QR code in the email as your entry ticket to the event. 
        <br />
        <br />
        Note: To get the full experience of the Backstage Marketplace, it does help to have a crypto wallet for downloading and saving the smart tickets. These tickets do offer additional benefits and value after the initial event.`,
  },
  {
    question: "What crypto wallets does Backstage support?",
    answer: `We suggest to use the Backstage Wallet for the best user experience.
    But also, our Marketplace supports MetaMask and BitKeep. You can download either wallet at the following links:
        <br />
        <br />
        <ul style="list-style-type: disc; padding-left: 30px;">
            <li>
                BitKeep: <a style="color: white; text-decoration: underline;" href="https://bitkeep.com/en/index">https://bitkeep.com/en/index</a>
            </li>
            <li>
                MetaMask: <a style="color: white; text-decoration: underline;" href="https://metamask.io/">https://metamask.io</a>
            </li>
        </ul>`,
  },
  {
    question: "What fiat currencies do you accept?",
    answer: `We accept most major fiat currencies, such as USD, Euro and British Pound. We accept most major credit cards as well.`,
  },
  {
    question: "What blockchain is the BKS Marketplace on?",
    answer: `The Marketplace is powered by the Binance Smart Chain and NEAR Blockchain. If you want to store your NFT ticket in your wallet or pay with USDT, you’ll need a BEP-20 address.`,
  },
  {
    question: "How do I claim my ticket after purchase?",
    answer: `We’ll send you an email with a QR code ticket after purchase. This is enough to get you into the event. 
    <br/>
    <br/>
    If you want to claim the ticket as an NFT, go to your confirmation email, click the Claim Your NFT Here button. You’ll then be forwarded to the Backstage Marketplace. You’ll see yourself logged in under your username. You’ll also see the option to Collect Your NFT using a MetaMask or BitKeep wallet. Pay a small gas fee to confirm the transaction and you’ll see the ticket appear soon in your wallet.`,
  },
  {
    question: "Where can I view the NFT ticket?",
    answer: `After you complete the transaction of collecting your NFT, you’ll be able to view your NFT on your Backstage Marketplace profile. 
    <br/>
    <br/>
    Simply head to your profile activity page. Then, click the link to view your NFT ticket.`,
  },
  {
    question: "Some tickets come with additional benefits. Where can I view these?",
    answer: `You can view these benefits on the ticket description in our Marketplace, as well as on the ticket itself. Check the email confirmation or the ticket in your profile.`,
  },
  {
    question: "Where can I find out about upcoming events?",
    answer: `Check our Marketplace for new tickets. Stay updated to our News page as well for announcements: <a style="color: white; text-decoration: underline;" href="https://backstage.global/blog"> https://backstage.global/blog</a>. We have new events arriving continuously.`,
  },
  {
    question: "What if I need to return my ticket?",
    answer: `Please refer to the terms and conditions of the event. The refund policy varies depending on the event organizer. Some tickets may be non-refundable, while others may have refund options available.`,
  },
];

export default Faqs;
