import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../../context/UserContext";
import { useAppContext } from "../../../context/AppContext";
import { verify2FA, getTwoFactorStatus } from "../../../api/twofactor";
import styles from "./index.module.css";

const TwoFactorModal = ({ data }) => {
  const { userInfo } = useUserContext();
  const { setLoading, setModal, addToast, setTwoStepStatus } = useAppContext();
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [token, setToken] = useState("");
  const [isSecretCopied, setIsSecretCopied] = useState(false);
  const [wrongCode, setWrongCode] = useState(false);
  const updateStep = async () => {
    if (step === 0) {
      setStep(1);
    } else if (step === 1) {
      setLoading(true);
      const response = await verify2FA({
        token,
        secret: data.secret,
        user: userInfo.user,
      });
      await getStatus();
      setLoading(false);
      if (response.isValid) {
        setModal({ open: false });
        addToast("Two Factor Authentication is successfully enabled!", {
          appearance: "success",
          autoDismiss: true,
        });
      } else {
        setWrongCode(true);
      }
    }
  };

  const getStatus = async () => {
    const result = await getTwoFactorStatus({
      userId: userInfo.user.id,
    });
    setTwoStepStatus(result.status);
  };

  const copySecret = () => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(data.secret).then(
      function() {
        console.log("Async: Copying to clipboard was successful!");
      },
      function(err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
    setIsSecretCopied(true);
    setTimeout(() => {
      setIsSecretCopied(false);
    }, 2000);
  };

  const borderStyle = () => {
    return wrongCode
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };

  return (
    <div className={styles.container}>
      {step === 0 && (
        <div>
          <p className={styles.guideText}>
            Open Google Authenticator app and scan this QR code.
          </p>
          <div className={styles.qrcodeContainer}>
            <div className={styles.qrcodeBlock}>
              <img src={data.qrCode} alt="qrcode" />
            </div>
          </div>
          <p className={styles.guideText}>
            Or enther the following code manually:
          </p>
          <div className={styles.codeBlock}>
            <p className={styles.guideText}>{data.secret}</p>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={copySecret}
            >
              <path
                d="M16.6667 7.76019H9.16667C8.24619 7.76019 7.5 8.50638 7.5 9.42686V16.9269C7.5 17.8473 8.24619 18.5935 9.16667 18.5935H16.6667C17.5871 18.5935 18.3333 17.8473 18.3333 16.9269V9.42686C18.3333 8.50638 17.5871 7.76019 16.6667 7.76019Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.1665 12.7602H3.33317C2.89114 12.7602 2.46722 12.5846 2.15466 12.272C1.8421 11.9595 1.6665 11.5355 1.6665 11.0935V3.59352C1.6665 3.15149 1.8421 2.72757 2.15466 2.415C2.46722 2.10244 2.89114 1.92685 3.33317 1.92685H10.8332C11.2752 1.92685 11.6991 2.10244 12.0117 2.415C12.3242 2.72757 12.4998 3.15149 12.4998 3.59352V4.42685"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {isSecretCopied && <p className={styles.copiedText}>{t("copied")}</p>}
          </div>
        </div>
      )}
      {step === 1 && (
        <div>
          <p className={styles.guideText}>
            Enther a passcode from Google Authenticator app to continue
          </p>
          <div className={styles.wrapper}>
            <input
              type="text"
              value={token}
              onChange={(e) => {
                setWrongCode(false);
                setToken(e.target.value);
              }}
              placeholder="Input passcode"
              style={borderStyle()}
            />
            {wrongCode && <span className="text-error">Wrong passcode</span>}
          </div>
        </div>
      )}
      <button className={styles.add__btn} onClick={updateStep} type="button">
        {step === 0 ? "Next" : "Verify"}
      </button>
    </div>
  );
};

export default TwoFactorModal;
