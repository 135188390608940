import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "./index.module.css";

const PageContact = () => {
  const form = useRef<HTMLFormElement>();

  const recaptchaRef = React.createRef();
  const [captcha, setCaptcha] = useState(true);

  const { t } = useTranslation();

  const onChangeCaptcha = (e: string | null) => {
    setCaptcha(!!e);
  };

  const submit = () => {
    if (captcha === null) return;
    emailjs
      .sendForm(
        "service_erw2ld9",
        "template_75tt6ir",
        form.current as any,
        "-dCe7JmPnoBko-5QM"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    emailjs
      .sendForm(
        "service_erw2ld9",
        "template_v29fwqf",
        form.current as any,
        "-dCe7JmPnoBko-5QM"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Thanks for contacting us");
          form.current?.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div style={{ width: "100%", zIndex: 999 }}>
      <div className="sign__content">
        <form
          action="#"
          ref={form as any}
          className="sign__form"
          style={{ maxWidth: 312, padding: 0 }}
        >
          <p className={styles.contact__main_title}>{t("contact us")}</p>
          <div className="sign__group">
            <p className="sign__form-label">{t("name")}</p>
            <input
              type="text"
              name="name"
              className="sign__input"
              onChange={(e) => console.log("name", e.target.value)}
            />
          </div>

          <div className="sign__group">
            <p className="sign__form-label">{t("email")}</p>
            <input
              type="text"
              name="email"
              className="sign__input"
              onChange={(e) => console.log("email", e.target.value)}
            />
          </div>

          <div className="sign__group">
            <p className="sign__form-label">{t("subject")}</p>
            <input
              type="text"
              name="subject"
              className="sign__input"
              onChange={(e) => console.log("subject", e.target.value)}
            />
          </div>

          <div className="sign__group">
            <p className="sign__form-label"> {t("Your message")}</p>
            <textarea
              name="message"
              className="sign__input"
              style={{ height: 175, resize: "none", padding: "10px 20px" }}
            />
          </div>
          {!captcha && (
            <div
              style={{
                color: "white",
                marginBottom: "20px",
                fontSize: 12,
                lineHeight: "12px",
              }}
            >
              {t("capcha text")}
            </div>
          )}
          {!captcha && (
            <div style={{ padding: 0 }}>
              <ReCAPTCHA
                ref={recaptchaRef as any}
                // size="invisible"
                sitekey="6LeaLwUgAAAAAIBN0ef2xzTx0rIfuLb1POyzr_ei"
                // sitekey="6Lf4RAUgAAAAAJbw7qXWVBfVtM2Ocggfs0KYGPjv"
                onChange={onChangeCaptcha}
              />
            </div>
          )}
          <button
            className={captcha ? "sign__btn" : "sign__btn-inactive"}
            type="button"
            onClick={() =>
              captcha
                ? submit()
                : console.log("You should complete the captcha!")
            }
          >
            {t("send")}
          </button>
        </form>
        <div className={styles.contact__socials}>
          <p style={{ letterSpacing: "0.02em" }}>
            {t("You can also reach us through our social media channels")}
          </p>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="about__follow-icons">
              <a
                href="https://t.me/BKSBackstage"
                target="_blank"
                rel="noreferrer"
              >
                <LazyLoadImage src="/img/icons/telegram.svg" alt="data" />
              </a>

              <a
                href="https://bksbackstageofficial.medium.com/"
                target="_blank"
                rel="noreferrer"
              >
                <LazyLoadImage src="/img/icons/medium.svg" alt="data" />
              </a>
              <a
                href="https://twitter.com/BackstageBks"
                target="_blank"
                rel="noreferrer"
              >
                <LazyLoadImage src="/img/icons/twitter.svg" alt="data" />
              </a>
            </div>
            <div
              className="about__follow-icons"
              style={{ justifyContent: "center" }}
            >
              <a
                href="https://www.facebook.com/BKSBackstage"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: 15 }}
              >
                <LazyLoadImage src="/img/icons/facebook.svg" alt="data" />
              </a>
              <a
                href="https://www.instagram.com/bksbackstage/?hl=en"
                target="_blank"
                rel="noreferrer"
              >
                <LazyLoadImage src="/img/icons/instagram.svg" alt="data" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContact;
