import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "./index.module.css";

const ImageUpload = ({
  validations,
  values,
  largeFile,
  largeImageChange,
  largeImagePreview,
  largeImageLoaded,
  smallFile,
  smallImageChange,
  smallImagePreview,
  nftFile,
  nftImageChange,
  nftImagePreview,
  floorMapFile,
  floorMapImageChange,
  floorMapImagePreview,
  imageDelete,
  multiImageChange,
  multiImagesPreview,
  multiImageDelete,
  multiVideoChange,
  multiVideoPreview,
}) => {
  const { t } = useTranslation();
  const largeFileRef = useRef(null);
  const nftFileRef = useRef(null);

  useEffect(() => {
    if (
      validations.picture_large === "has-empty" ||
      validations.picture_small === "has-empty" ||
      validations.picture_small === "has-invalid"
    ) {
      largeFileRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (validations.picture_nft === "has-empty") {
      nftFileRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [validations]);
  return (
    <div className="cr__evcard-subItem">
      <h4 className="cr__evcard--subtitle">
        <span></span>
        {t("upload item image")}
      </h4>
      <div
        className="cr__evcard-rowParent"
        ref={largeFileRef}
        style={{ marginBottom: "30px" }}
      >
        <div className="cr__evcard-50">
          <p className="cr__evcard-labelTitle">{t("large picture")}*</p>
          <p className="cr__evcard-labelDesc">{t("image size")} 1080 x 1080</p>
          <div className="cr__evcard-uploadImg">
            <label
              id="file1"
              htmlFor="largeimage-upload"
              className="input__upload"
              style={{
                border:
                  validations.picture_large === "has-empty"
                    ? "#FF4E4E 1px solid"
                    : "",
                display: largeImagePreview === "" ? "flex" : "none",
              }}
            >
              {largeImagePreview ? (
                largeFile?.name
              ) : (
                <>
                  <img src="/img/selfie/upload.png" alt="upload" />
                  <span>{t("upload")}</span>
                </>
              )}
            </label>
            <input
              data-name="#file1"
              id="largeimage-upload"
              name="file"
              type="file"
              accept=".png,.jpg,.jpeg,.gif,.mp3,.wav,.mp4,.mov,.avi"
              onChange={largeImageChange}
              src={largeImagePreview}
            />

            {validations.picture_large === "has-empty" ? (
              <span className="text-error">{t("large image required")}*</span>
            ) : (
              validations.picture_large === "has-invalid" && (
                <span className="text-error">{t("wrong image size")}*</span>
              )
            )}
          </div>
          {largeImagePreview && (
            <div className={styles.previewBlock}>
              {largeImagePreview.includes("image") ? (
                <LazyLoadImage
                  src={largeImagePreview}
                  onLoad={largeImageLoaded}
                  className={styles.largeImgPreview}
                  alt="large preview"
                />
              ) : (
                <video
                  autoPlay
                  playsInline
                  loop
                  controls
                  muted
                  className={styles.largeImgPreview}
                  id="video"
                >
                  <source src={largeImagePreview} type="video/mp4" />
                  {t("Your browser does not support thte video tag.")}
                </video>
              )}
              <img
                onClick={() => imageDelete("large")}
                className={styles.trashImg}
                src="/img/trash.png"
                alt="delete"
              />
            </div>
          )}
        </div>
        <div className="cr__evcard-50">
          <p className="cr__evcard-labelTitle">{t("small picture")}*</p>
          <p className="cr__evcard-labelDesc">{t("image size")} 288 x 288</p>
          <div className="cr__evcard-uploadImg">
            <label
              id="file2"
              htmlFor="smallimage-upload"
              className="input__upload"
              style={{
                border:
                  validations.picture_small === "has-empty"
                    ? "#FF4E4E 1px solid"
                    : "",
                display: smallImagePreview === "" ? "flex" : "none",
              }}
            >
              {smallImagePreview ? (
                smallFile?.name
              ) : (
                <>
                  <img src="/img/selfie/upload.png" alt="upload" />{" "}
                  <span>{t("upload")}</span>
                </>
              )}
            </label>
            <input
              data-name="#file2"
              id="smallimage-upload"
              name="file"
              type="file"
              accept=".png,.jpg,.jpeg,.gif"
              onChange={smallImageChange}
              src={smallImagePreview}
            />

            {validations.picture_small === "has-empty" ? (
              <span className="text-error">{t("small image required")}*</span>
            ) : (
              validations.picture_small === "has-invalid" && (
                <span className="text-error">{t("small image rule")}*</span>
              )
            )}
          </div>
          {smallImagePreview && (
            <div className={styles.previewBlock}>
              <LazyLoadImage
                src={smallImagePreview}
                className={styles.smallImgPreview}
                alt="small preview"
              />
              <img
                onClick={() => imageDelete("small")}
                className={styles.trashImg}
                src="/img/trash.png"
                alt="delete"
              />
            </div>
          )}
        </div>
      </div>
      <div ref={nftFileRef} className="cr__evcard-rowParent">
        <div className="cr__evcard-50">
          <p className="cr__evcard-labelTitle">{t("NFT picture")}*</p>
          <p className="cr__evcard-labelDesc">
            {t("image to send as NFT")} ({t("max size")} 100MB)
          </p>
          <div className="cr__evcard-uploadImg">
            <label
              id="file3"
              htmlFor="nftimage-upload"
              className="input__upload"
              style={{
                border:
                  validations.picture_nft === "has-empty"
                    ? "#FF4E4E 1px solid"
                    : "",
                display: nftImagePreview === "" ? "flex" : "none",
              }}
            >
              {nftImagePreview ? (
                nftFile?.name
              ) : (
                <>
                  <img src="/img/selfie/upload.png" alt="upload" />
                  <span>{t("upload")}</span>
                </>
              )}
            </label>
            <input
              data-name="#file3"
              id="nftimage-upload"
              name="file"
              type="file"
              accept=".png,.jpg,.jpeg,.gif,.mp3,.wav,.mp4,.mov,.avi"
              onChange={nftImageChange}
              src={nftImagePreview}
            />

            {validations.picture_nft === "has-empty" && (
              <span className="text-error">{t("NFT image required")}*</span>
            )}
          </div>
          {nftImagePreview && (
            <div className={styles.previewBlock}>
              {nftImagePreview.includes("image") ? (
                <LazyLoadImage
                  src={nftImagePreview}
                  className={styles.largeImgPreview}
                  alt="nft preview"
                />
              ) : (
                <video
                  autoPlay
                  playsInline
                  loop
                  controls
                  muted
                  className={styles.largeImgPreview}
                  id="video"
                >
                  <source src={nftImagePreview} type="video/mp4" />
                  {t("Your browser does not support thte video tag.")}
                </video>
              )}
              <img
                onClick={() => imageDelete("nft")}
                className={styles.trashImg}
                src="/img/trash.png"
                alt="delete"
              />
            </div>
          )}
        </div>
      </div>
      <p className={styles.label}>{t("ADDITIONAL IMAGES AND VIDEO")}</p>
      <div className="cr__evcard-rowParent">
        <div className="cr__evcard-50">
          <p className="cr__evcard-labelTitle">{t("Additional Images")}</p>
          <p className="cr__evcard-labelDesc">
            {t("image size")} 1080 x 1080. {t("Up to 4 images")}
          </p>
          <div className="cr__evcard-uploadImg">
            <label
              id="file5"
              htmlFor="multiImage-upload"
              className="input__upload"
            >
              <>
                <img src="/img/selfie/upload.png" alt="upload" />
                <span>{t("upload")}</span>
              </>
            </label>
            <input
              data-name="#file5"
              id="multiImage-upload"
              name="file"
              type="file"
              accept=".png,.jpg,.jpeg,.gif"
              onChange={multiImageChange}
              src={multiImagesPreview[multiImagesPreview.length]}
            />
          </div>
          <div className={styles.multiContainer}>
            {multiImagesPreview.map((item, index) => (
              <div className={styles.previewBlock} key={index}>
                <LazyLoadImage
                  src={item}
                  className={styles.smallImgPreview}
                  alt="multi preview"
                />
                <img
                  onClick={() => multiImageDelete(index)}
                  className={styles.trashImg}
                  src="/img/trash.png"
                  alt="delete"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="cr__evcard-50">
          <p className="cr__evcard-labelTitle">{t("Additional Video")}</p>
          <p className="cr__evcard-labelDesc">
            {t("Video size 1080 x 1080. Maximum 1 file.")}
          </p>
          <div className="cr__evcard-uploadImg">
            <label
              id="file6"
              htmlFor="multiVideo-upload"
              className="input__upload"
            >
              <>
                <img src="/img/selfie/upload.png" alt="upload" />
                <span>{t("upload")}</span>
              </>
            </label>
            <input
              data-name="#file6"
              id="multiVideo-upload"
              name="file"
              type="file"
              accept=".mp4,.mov,.avi"
              onChange={multiVideoChange}
              src={multiVideoPreview}
            />
          </div>
          {multiVideoPreview && (
            <div className={styles.previewBlock}>
              <video
                autoPlay
                playsInline
                loop
                controls
                muted
                className={styles.smallImgPreview}
                id="video"
              >
                <source src={multiVideoPreview} type="video/mp4" />
                {t("Your browser does not support thte video tag.")}
              </video>
              <img
                onClick={() => imageDelete("multiVideo")}
                className={styles.trashImg}
                src="/img/trash.png"
                alt="delete"
              />
            </div>
          )}
        </div>
      </div>
      {values.category === "Category1" && (
        <>
          <p className={styles.label}>FLOOR MAP</p>
          <div className="cr__evcard-rowParent">
            <div className="cr__evcard-50">
              <p className="cr__evcard-labelTitle">{t("floor map")}</p>
              <p className="cr__evcard-labelDesc">
                {t("image size up to")} 2000 x 2000
              </p>
              <div className="cr__evcard-uploadImg">
                <label
                  id="file4"
                  htmlFor="floormapimage-upload"
                  className="input__upload"
                  style={{
                    display: floorMapImagePreview === "" ? "flex" : "none",
                  }}
                >
                  {floorMapImagePreview ? (
                    floorMapFile?.name
                  ) : (
                    <>
                      <img src="/img/selfie/upload.png" alt="upload" />{" "}
                      <span>{t("upload")}</span>
                    </>
                  )}
                </label>
                <input
                  data-name="#file4"
                  id="floormapimage-upload"
                  name="file"
                  type="file"
                  accept=".png,.jpg,.jpeg,.gif,.mp3,.wav,.mp4,.mov,.avi"
                  onChange={floorMapImageChange}
                  src={floorMapImagePreview}
                />

                {validations.picture_floormap === "has-empty" && (
                  <span className="text-error">
                    {t("Floor Map image required")}*
                  </span>
                )}
              </div>
              {floorMapImagePreview && (
                <div className={styles.previewBlock}>
                  {floorMapImagePreview.includes("image") ? (
                    <LazyLoadImage
                      src={floorMapImagePreview}
                      className={styles.largeImgPreview}
                      alt="floorMapImage preview"
                    />
                  ) : (
                    <video
                      autoPlay
                      playsInline
                      loop
                      controls
                      muted
                      className={styles.largeImgPreview}
                      id="video"
                    >
                      <source src={floorMapImagePreview} type="video/mp4" />
                      {t("Your browser does not support thte video tag.")}
                    </video>
                  )}
                  <img
                    onClick={() => imageDelete("floorMap")}
                    className={styles.trashImg}
                    src="/img/trash.png"
                    alt="delete"
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageUpload;
