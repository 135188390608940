import { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useUserContext } from "../../../context/UserContext";
import Author from "./author";
import Profile from "./profile";

const PageAuthor = () => {
  const { userInfo } = useUserContext();
  const [background, setBackground] = useState("");
  const [wallet, setWallet] = useState<string>(userInfo?.user?.wallet_address || "");
  const [walletNear, setWalletNear] = useState<string>(
    userInfo?.user?.wallet_address_near || "test.net"
  );

  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 450px)");

  useEffect(() => {
    if (userInfo && userInfo?.user?.background) {
      const backgroundUrl = `${process.env.REACT_APP_SITE_URL}${userInfo?.user?.background}`;
      setBackground(backgroundUrl);
      setWallet(userInfo?.user?.wallet_address);
    } else {
      if (!userInfo)
        navigate("/Signin");
      setWallet(userInfo?.user?.wallet_address);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <div className="profile__setting_container">
      {/* banner image */}
      <div
        className="main__author"
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        {isMobile && (
          <div className="back_arrow" onClick={() => navigate("/")}>
            <LazyLoadImage
              src="/img/icons/arrow-left.svg"
              alt="data"
              style={{ width: 24, height: 24 }}
            />
          </div>
        )}
        &nbsp;
      </div>

      <div className="profile__container">
        <div
          className={isMobile ? " " : "row row--grid"}
          style={{ width: "100%", marginLeft: 0 }}
        >
          <Author
            wallet={wallet}
            setWallet={setWallet}
            walletNear={walletNear}
            setWalletNear={setWalletNear}
          />
          <Profile wallet={wallet} walletNear={walletNear} />
        </div>
      </div>
    </div>
  );
};

export default PageAuthor;
