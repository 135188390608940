// @ts-nocheck
import { ColDef, RowClassParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useMemo, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useUserContext } from "../../../../context/UserContext";
import { useAppContext } from "../../../../context/AppContext";
import { allTickets, businessTickets } from "../../../../api/event";
import { getNotVerifiedUsers } from "../../../../api/user";
import { verifyEmailByAdmin } from "../../../../api/user";
import { IUser, ITicket } from "../../../../utils/interface";
import Search from "../../../../components/search";
import PeriodFilter from "../../../../components/filter/PeriodFilter";
import FilterCategory from "../../../../components/filter/FilterCategory";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import styles from "./index.module.css";

const PageAdminReport = () => {
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [originTickets, setOriginTickets] = useState([]);
  const [artTicketList, setArtTicketList] = useState<ITicket[]>([]);

  const [artFilter, setArtFilter] = useState("");
  const [artPeriod, setArtPeriod] = useState("all");
  const [search, setSearch] = useState("");
  const [currentTag, setCurrentTag] = useState("all");
  const [currentBtn, setCurrentBtn] = useState("");
  const { userInfo } = useUserContext();
  const isMobile = useMediaQuery("(max-width: 450px)");
  const navigate = useNavigate();
  const artReport = useRef(null);
  const userReport = useRef(null);
  const [values, setValues] = useState({
    today: 0,
    week: 0,
    month: 0,
    all: 0,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchText, setSearchText] = useState("");

  const { setLoading, addToast } = useAppContext();
  const { t } = useTranslation();

  const getAllTickets = async () => {
    setLoading(true);
    await allTickets({
      email: userInfo.user.email,
      id: userInfo.user.id,
    }).then((res) => {
      console.log(res.tickets);
      if (res.success === true) {
        sortTickets(res.tickets);
        setOriginTickets(res.tickets);
      }
    });
    setLoading(false);
  };

  const getBusinessTickets = async () => {
    setLoading(true);
    await businessTickets({
      owner_id: userInfo.user.id,
    }).then((res) => {
      console.log(res.tickets);
      if (res.success === true) {
        sortTickets(res.tickets);
        setOriginTickets(res.tickets);
      }
    });
    setLoading(false);
  };

  const sortUsers = (array: never[]) => {
    setUsers(
      array.sort(
        (item1: IUser, item2: IUser) =>
          new Date(item2.createdAt).getTime() -
          new Date(item1.createdAt).getTime()
      )
    );
  };

  const sortTickets = (array: never[]) => {
    setTickets(
      array.sort(
        (item1: ITicket, item2: ITicket) =>
          new Date(item2.createdAt).getTime() -
          new Date(item1.createdAt).getTime()
      )
    );
  };

  const notVerifiedUsers = async () => {
    getNotVerifiedUsers().then((res) => {
      if (res.success === true) {
        if (res.success) {
          sortUsers(res.users);
          let _users: {
            Name: string;
            Email: string;
            Email_verified: boolean;
            Followers: number;
            "Wallet address": string;
          }[] = [];
          res.users.map((item: IUser) => {
            let past_time = (new Date() - new Date(item.createdAt)) / 60000;
            if (past_time > 20 && past_time / 60 / 24 < 14) {
              let user = {
                Name: item.name,
                Email: item.email,
                "Mobile Number": item.phone,
                Gender: item.gender,
                "Birth Year": item.date,
                Email_verified: item.email_verified ? "Yes" : "No",
                Register_Date: item.createdAt.substring(0, 10),
              };
              _users.push(user);
            }
            return _users;
          });
          setUsers(_users);
        }
      }
    });
    // getAllQRtickets().then((res) => {
    //   console.log(res);
    //   let enriues = res.filter((qrcode) => qrcode.collection_name.includes("Enrique") && qrcode.valid === 0);
    //   console.log(enriues);
    // })
  };

  const refreshData = () => {
    if (userInfo?.user.user_type === "ADMIN") {
      getReferralLinks();
      notVerifiedUsers();
      getAllTickets();
    } else {
      getBusinessTickets();
    }
  };

  const columnDefsForUsers = useMemo<ColDef[]>(
    () => [
      { field: "Name", width: 170 },
      { field: "Email", width: 240 },
      { field: "Mobile Number", width: 180 },
      { field: "Gender", width: 120 },
      { field: "Birth Year", width: 140 },
      { field: "Email_verified", width: 180 },
      { field: "Register_Date", width: 200 },
      {
        headerName: "Verify",
        field: t("email"),
        width: 200,
        cellRenderer: "ButtonActionRenderer",
      },
    ],
    [t]
  );

  const startOfDate = (inp: Date) => {
    const res = new Date(inp);
    res.setHours(0);
    res.setMinutes(0);
    res.setSeconds(0);
    return res;
  };

  const endOfDate = (inp: Date) => {
    const res = new Date(inp);
    res.setHours(23);
    res.setMinutes(59);
    res.setSeconds(59);
    return res;
  };

  const firstWeekDay = (inp: Date) => {
    const res = new Date(inp);
    res.setDate(res.getDate() - res.getDay());
    return startOfDate(res);
  };

  const endWeekDay = (inp: Date) => {
    const res = new Date(inp);
    res.setDate(res.getDate() - res.getDay() + 6);
    return endOfDate(res);
  };

  const firstMonthDay = (inp: Date) => {
    const res = new Date(inp);
    res.setDate(1);
    return startOfDate(res);
  };

  const endMonthDay = (inp: Date) => {
    const res = new Date(inp);
    const today = new Date();
    const daysInMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    ).getDate();
    res.setDate(daysInMonth);
    return endOfDate(res);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSearch = () => {
    if (searchText !== "") {
      const _tickets = originTickets.filter(
        (ticket) =>
          ticket.eventcard.name
            .toLowerCase()
            .search(searchText.toLowerCase()) !== -1 ||
          ticket.buyer.name.toLowerCase().search(searchText.toLowerCase()) !==
            -1
      );
      setTickets(_tickets);
    } else {
      setTickets(originTickets);
    }
  };

  const artTicketExport = () => {
    artReport.current.api.exportDataAsCsv({ fileName: "soldItems.csv" });
  };

  const userExport = () => {
    userReport.current.api.exportDataAsCsv({ fileName: "notVerifiedUser.csv" });
  };

  const allExport = () => {
    artTicketExport();
    userExport();
  };

  const columnDefs = useMemo<ColDef[]>(
    () => [
      { field: "Sold Date", width: 210 },
      { field: "Item Name", width: 350 },
      { field: "Name", width: 150 },
      { field: "Email", width: 220 },
      { field: "Phone", width: 180 },
      { field: "Price", width: 130 },
      { field: "Tax", width: 130 },
      { field: "Discount", width: 150 },
      { field: "Count", width: 110 },
      { field: "Payment Method", width: 200 },
    ],
    []
  );
  const getRowClass = (params: RowClassParams) => {
    if (params.rowIndex % 2 === 0) return "odd-row";
  };
  const verifyUser = async (email) => {
    const res = await verifyEmailByAdmin({
      email: email,
      isMobile: isMobile,
      country: "",
    });
    if (res.success) {
      notVerifiedUsers();
      addToast("The user is verified successfully!", {
        appearance: "success",
        autoDismiss: true,
      });
    }
  };

  const ButtonActionRenderer = (data) => {
    return (
      <button
        className="sign__input"
        style={{ marginTop: 0, height: 40, width: 88 }}
        onClick={(e) => verifyUser(data.value)}
      >
        Verify
      </button>
    );
  };

  const filterArtTicket = () => {
    let res = [];
    if (currentTag === "all") {
      res = tickets;
    } else {
      res = tickets.filter((ticket: any) =>
        currentTag === "Stripe"
          ? ticket.pay_order_id === "stripe payment"
          : currentTag === "Mercado"
          ? ticket.pay_order_id === "mercado payment"
          : currentTag === "Cash"
          ? ticket.pay_order_id === "cash payment"
          : currentTag === "Free"
          ? ticket.pay_order_id.includes("free")
          : ticket.pay_order_id.includes("0x")
      );
    }
    res = res.filter((card: any) =>
      search === ""
        ? card
        : card.eventcard.name
            .toLowerCase()
            .includes(search.trim().toLowerCase()) ||
          card.collection.name.toLowerCase() === search.trim().toLowerCase() ||
          (card?.buyer_email === null
            ? card.buyer?.name
                .toLowerCase()
                .includes(search.trim().toLowerCase()) ||
              card.buyer?.email
                .toLowerCase()
                .includes(search.trim().toLowerCase())
            : card?.buyer_email
                .toLowerCase()
                .includes(search.trim().toLowerCase()) ||
              card?.buyer_name
                .toLowerCase()
                .includes(search.trim().toLowerCase()))
    );
    setFilteredTickets([...res]);
    let _tickets = [];
    if (artPeriod === "all") {
      _tickets = res;
    } else if (artPeriod === "month") {
      _tickets = res.filter(
        (ticket: ITicket) =>
          Math.ceil(
            (new Date() - new Date(ticket.createdAt).getTime()) /
              (1000 * 60 * 60 * 24)
          ) < 31
      );
    } else if (artPeriod === "week") {
      _tickets = res.filter(
        (ticket: ITicket) =>
          Math.ceil(
            (new Date() - new Date(ticket.createdAt).getTime()) /
              (1000 * 60 * 60 * 24)
          ) < 7
      );
    } else if (artPeriod === "today") {
      _tickets = res.filter(
        (ticket: ITicket) =>
          new Date(ticket.createdAt).getTime() >=
            startOfDate(new Date()).getTime() &&
          new Date(ticket.createdAt).getTime() <=
            endOfDate(new Date()).getTime()
      );
    }
    let totalPrice = 0;
    for (let index = 0; index < _tickets.length; index++) {
      const element = _tickets[index];
      totalPrice += (element.price * (100 - element.discount)) / 100;
    }
    console.log(totalPrice);
    setArtTicketList(
      _tickets.map((ticket: ITicket) => {
        const soldDate = new Date(ticket.createdAt).toString().substring(0, 21);
        return {
          "Sold Date": soldDate,
          "Item Name": ticket.eventcard.name,
          Name: ticket.buyer_name || ticket.buyer.name,
          Email: ticket.buyer_email || ticket.buyer.email,
          Phone: ticket.buyer_phone || ticket.buyer.phone,
          Price:
            (ticket.price * (100 - ticket.discount)) / 100 +
            `${
              ticket.eventcard.currency === "USD"
                ? " $"
                : ticket.eventcard.currency === "TRY"
                ? " ₺"
                : ticket.eventcard.currency === "EUR"
                ? " €"
                : ticket.eventcard.currency === "CLP"
                ? " $(CLP)"
                : ticket.eventcard.currency === "DKK"
                ? " kr."
                : ticket.eventcard.currency === "SEK"
                ? " kr"
                : " ₹"
            }`,
          Tax:
            (!ticket.eventcard.tax_include
              ? (((ticket.price * (100 - ticket.discount)) / 100) *
                  ticket.eventcard.tax) /
                100
              : 0) +
            `${
              ticket.eventcard.currency === "USD"
                ? " $"
                : ticket.eventcard.currency === "TRY"
                ? " ₺"
                : ticket.eventcard.currency === "EUR"
                ? " €"
                : ticket.eventcard.currency === "CLP"
                ? " $(CLP)"
                : ticket.eventcard.currency === "DKK"
                ? " kr."
                : ticket.eventcard.currency === "SEK"
                ? " kr"
                : " ₹"
            }`,
          Discount: ticket.discount + "%",
          Count: ticket.count,
          "Payment Method":
            ticket.pay_order_id.search("0x") !== -1
              ? "Crypto payment"
              : ticket.pay_order_id,
        };
      })
    );
  };

  useEffect(() => {
    const today = new Date();
    const start_date = startOfDate(today).getTime();
    const end_date = endOfDate(today).getTime();
    const start_week = firstWeekDay(today).getTime();
    const end_week = endWeekDay(today).getTime();
    const start_month = firstMonthDay(today).getTime();
    const end_month = endMonthDay(today).getTime();
    let res = {
      today: 0,
      week: 0,
      month: 0,
      all: 0,
    };
    console.log(filteredTickets);
    filteredTickets.map((ticket: ITicket) => {
      const current = new Date(ticket.createdAt).getTime();
      res.all += Number(ticket.count);
      if (current >= start_date && current <= end_date)
        res.today += Number(ticket.count);
      if (current >= start_week && current <= end_week)
        res.week += Number(ticket.count);
      if (current >= start_month && current <= end_month)
        res.month += Number(ticket.count);
      return 0;
    });
    setValues({ ...res });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTickets]);

  useEffect(() => {
    filterArtTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artPeriod, search, currentTag, tickets]);

  useEffect(() => {
    if (userInfo && userInfo.user.user_type === "ADMIN") {
      notVerifiedUsers();
      getAllTickets();
    } else if (userInfo && userInfo.user.user_type === "BUSINESS") {
      getBusinessTickets();
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (!userInfo) {
      navigate("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.report__container}>
      {userInfo?.user?.user_type === "ADMIN" && (
        <div className={styles.down__all_contain}>
          <button
            onClick={allExport}
            className="download__btn down__all_report"
          >
            {t("download all reports")}
          </button>
        </div>
      )}
      <div className="see__users--header">
        <h1 className="see__users--title">{t("number of items sold")}</h1>
        <button
          type="button"
          className="sign__btn refresh__btn"
          onClick={refreshData}
        >
          {t("refresh")}
        </button>
      </div>
      <div className={styles.report_data}>
        <div className={styles.report_item}>
          {t("number of items sold today")}: {values.today}
        </div>
        <div className={styles.report_item}>
          {t("number of items sold this week")}: {values.week}
        </div>
        <div className={styles.report_item}>
          {t("number of items sold this month")}: {values.month}
        </div>
        <div className={styles.report_item}>
          {t("number of items sold from beginning")}: {values.all}
        </div>
      </div>
      {/* Arts/Event tickets report */}
      <div className="see__users--header">
        <h1 className="see__users--title">{t("list of items sold")}</h1>
        <button onClick={artTicketExport} className="download__btn">
          {t("download CSV")}
        </button>
      </div>
      <div className="search__container">
        <Search
          onChange={(val) => setSearch(val)}
          placeholder={t("search tickets")}
        />
        <div className="filter__download_container">
          <FilterCategory
            currentBtn={currentBtn}
            setCurrentBtn={setCurrentBtn}
            currentTag={currentTag}
            setCurrentTag={setCurrentTag}
          />
          <PeriodFilter
            serviceFilter={artFilter}
            servicePeriod={artPeriod}
            setServiceFilter={setArtFilter}
            setServicePeriod={setArtPeriod}
          />
        </div>
      </div>
      <div className="ag-theme-alpine table-wrapper mb-5 mt-4 report__table">
        <AgGridReact
          className="w-full h-full ag-grid-table"
          rowClass={["custom-row"]}
          rowData={artTicketList}
          headerHeight={60}
          rowHeight={60}
          debounceVerticalScrollbar
          columnDefs={columnDefs}
          getRowClass={getRowClass}
          suppressRowHoverHighlight={false}
          ref={artReport}
        ></AgGridReact>
      </div>
      {userInfo?.user?.user_type === "ADMIN" && (
        <>
          <div className="see__users--header">
            <h1 className="see__users--title">
              {t("list of not verified users")}
            </h1>

            <button
              onClick={userExport}
              className="download__btn download__csv"
            >
              {t("download CSV")}
            </button>
          </div>
          <div className="ag-theme-alpine table-wrapper mb-5 mt-4 report__table">
            <AgGridReact
              className="w-full h-full ag-grid-table"
              rowClass={["custom-row"]}
              rowData={users}
              headerHeight={60}
              rowHeight={60}
              debounceVerticalScrollbar
              columnDefs={columnDefsForUsers}
              getRowClass={getRowClass}
              suppressRowHoverHighlight={false}
              frameworkComponents={{
                ButtonActionRenderer,
              }}
              ref={userReport}
            ></AgGridReact>
          </div>
        </>
      )}
    </div>
  );
};

export default PageAdminReport;
