import React, { useState, useRef } from "react";
// import { isValidPhoneNumber } from "react-phone-number-input/input";
// import { formatPhoneNumberIntl } from "react-phone-number-input/input";
import Input from "react-phone-number-input/input";

const PhoneNumberInput = ({ phoneNumber, handlePhone, borderStyle }) => {
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(phoneNumber);
  // const [realNumber, setRealNumber] = useState(
  //   formatPhoneNumberIntl(phoneNumber)
  // );
  // const [trigger, setTrigger] = useState(false);
  const numberRef = useRef();
  // const phoneRef = useRef();

  const handleInput = (val) => {
    handlePhone(val || "");
    // console.log(isValidPhoneNumber(val));
    // if (
    //   !isValidPhoneNumber(val || "") &&
    //   trigger &&
    //   (val || "").length > (value || "").length
    // ) {
    //   setTrigger(false);
    // } else if (isValidPhoneNumber(val || "")) {
    //   setTrigger(true);
    //   setValue(val);
    //   setRealNumber(numberRef?.current?.value);
    //   handlePhone(val || "");
    // } else {
    //   setValue(val);
    //   setRealNumber(numberRef?.current?.value);
    //   handlePhone(val || "");
    // }
  };

  // const handleNumberPhone = (val) => {
  //   if (val.replaceAll(" ", "").length < value.length) {
  //     setValue(val.replaceAll(" ", ""));
  //     setRealNumber(val);
  //     handlePhone(val.replaceAll(" ", "") || "");
  //   } else if (isValidPhoneNumber(val)) {
  //     setValue(val.replaceAll(" ", ""));
  //     setRealNumber(val);
  //     handlePhone(val.replaceAll(" ", "") || "");
  //     setTrigger(true);
  //   }
  // };

  // useEffect(() => {
  //   if (numberRef.current) {
  //     numberRef.current.focus();
  //   } else {
  //     phoneRef.current.focus();
  //   }
  // }, [value, realNumber, trigger]);

  return (
    // <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
    //   {isValidPhoneNumber(value || "") && !trigger ? (
    //     <input
    //       className="sign__input"
    //       placeholder="+00 000 000 0000"
    //       style={borderStyle}
    //       type="tel"
    //       onChange={(e) => {
    //         handleNumberPhone(e.target.value);
    //       }}
    //       value={realNumber}
    //       ref={phoneRef}
    //     />
    //   ) : (
    //     <Input
    //       className="sign__input"
    //       placeholder="+00 000 000 0000"
    //       ref={numberRef}
    //       style={borderStyle}
    //       value={value}
    //       onChange={handleInput}
    //     />
    //   )}
    // </div>
    <Input
      className="sign__input"
      placeholder="+00 000 000 0000"
      ref={numberRef}
      style={borderStyle}
      value={value}
      onChange={handleInput}
    />
  );
};

export default PhoneNumberInput;
