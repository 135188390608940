import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../context/AppContext";
import styles from "./index.module.css";

const AddonsModal = ({ addons }) => {
  const { setModal } = useAppContext();
  const { t } = useTranslation();
  return (
    <div className={styles.addon__modal_contain}>
      <span className={styles.btn_close} onClick={() => setModal({ open: false })}>
        <svg
          style={{ marginTop: "-4px" }}
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            fill="#ffffff"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
        </svg>
      </span>
      <p className={styles.event_name}>{t("benefits included")} ({addons.length})</p>
      <div className={styles.addon_content}>
        {addons.map((item, index) => (
          <div className={styles.addon_block} key={index}>
            <div
              className={
                index <= 1
                  ? styles.addon__gold_effect
                  : index <= 3
                  ? styles.addon__blue_effect
                  : styles.addon__gray_effect
              }
            ></div>
            <img src="/img/benefit.png" className={styles.addon_icon} alt={item.name} />
            <p className={styles.addon_name}>{item.name}</p>
            <p className={styles.addon_description}>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddonsModal;
