import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../context/UserContext";
import { useAppContext } from "../../../context/AppContext";
import { verifyArticle, getPendingArticles } from "../../../api/article";
import { IArticle } from "../../../utils/interface";

import VerifyBlogItem from "../../../components/blog_item/verifyItem";
import styles from "./index.module.css";

const PageVerifyBlog = () => {
  const [articles, setArticles] = useState<IArticle[]>([] as IArticle[]);
  const navigate = useNavigate();
  const { userInfo } = useUserContext();
  const { setLoading, addToast } = useAppContext();

  useEffect(() => {
    if (userInfo && userInfo.user.user_type === "ADMIN") {
      setLoading(true);
      getPendingArticles()
        .then((res) => {
          setLoading(false);
          setArticles(res.articles);
        })
        .catch((error) => {
          setLoading(false);
          addToast("Network error occured", {
            appearance: "error",
            autoDismiss: true,
          });
        });
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVerifyArticle = (articleId: string) => {
    verifyArticle({ id: articleId })
      .then((res) => {
        const updatedArticles = [...articles].filter(
          (article: IArticle) => article.id !== articleId
        );
        setArticles(updatedArticles);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CArticles = () => {
    return articles.map((article: IArticle, i) => (
      <VerifyBlogItem
        key={article.id}
        article={article}
        handleVerifyArticle={handleVerifyArticle}
      />
    ));
  };

  return (
    <div className={styles.blog__container}>
      <div>
        <p className={styles.title}>Pending Article</p>
      </div>

      <div className={styles.blog__block}>{CArticles()}</div>
    </div>
  );
};

export default PageVerifyBlog;
