const timeZones = [
  {
    value: 0,
    text: "(GMT) Universal Coordinated Time",
  },
  {
    value: 1,
    text: "(GMT+1:00) European Central Time",
  },
  {
    value: 2,
    text: "(GMT+2:00) Eastern European Time",
  },
  {
    value: 3,
    text: "(GMT+3:00) Eastern African Time",
  },
  {
    value: 3.5,
    text: "(GMT+3:30) Middle East Time",
  },
  {
    value: 4,
    text: "(GMT+4:00) Near East Time",
  },
  {
    value: 5,
    text: "(GMT+5:00) Pakistan Lahore Time",
  },
  {
    value: 5.5,
    text: "(GMT+5:30) India Standard Time",
  },
  {
    value: 6,
    text: "(GMT+6:00) Bangladesh Standard Time",
  },
  {
    value: 7,
    text: "(GMT+7:00) Vietnam Standard Time",
  },
  {
    value: 8,
    text: "(GMT+8:00) China Taiwan Time",
  },
  {
    value: 9,
    text: "(GMT+9:00) Japan Standard Time",
  },
  {
    value: 9.5,
    text: "(GMT+9:30) Australia Central Time",
  },
  {
    value: 10,
    text: "(GMT+10:00) Australia Eastern Time",
  },
  {
    value: 11,
    text: "(GMT+11:00) Solomon Standard Time",
  },
  {
    value: 12,
    text: "(GMT+12:00) New Zealand Standard Time",
  },
  {
    value: -11,
    text: "(GMT-11:00) Midway Islands Time",
  },
  {
    value: -10,
    text: "(GMT-10:00) Hawaii Standard Time",
  },
  {
    value: -9,
    text: "(GMT-9:00) Alaska Standard Time",
  },
  {
    value: -8,
    text: "(GMT-8:00) Pacific Standard Time",
  },
  {
    value: -7,
    text: "(GMT-7:00) Phoenix Standard Time",
  },
  {
    value: -6,
    text: "(GMT-6:00) Central Standard Time",
  },
  {
    value: -5,
    text: "GMT-5:00) Eastern Standard Time",
  },
  {
    value: -4,
    text: "(GMT-4:00) Puerto Rico Time",
  },
  {
    value: -3.5,
    text: "(GMT-3:30) Canada Newfoundland Time",
  },
  {
    value: -3,
    text: "(GMT-3:00) Argentina Standard Time",
  },
  {
    value: -1,
    text: "(GMT-1:00) Central African Time",
  },
];

export default timeZones;
