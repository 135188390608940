import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styles from "./index.module.css";

const About = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.about__container}>
      <div className={styles.about__block}>
        <h1 className={styles.about__title}>{t("about.title")}</h1>
        <LazyLoadImage src="/img/icons/logo-circle.svg" alt="data" />
        <p className={styles.about__description}>
          {t("about.text1")} <br /> <br />
          {t("about.text2")}{" "}
        </p>
      </div>
      <div className={styles.about__block}>
        <p className={styles.about__follow_title}>{t("follow us")}</p>
        <div className={styles.follow__block}>
          <div className={styles.about__follow_icons}>
            <a href="https://bksbackstage.io/" target="_blank" rel="noreferrer">
              <LazyLoadImage src="/img/icons/global.svg" alt="data" />
            </a>
            <a
              href="https://t.me/BKSBackstage"
              target="_blank"
              rel="noreferrer"
            >
              <LazyLoadImage src="/img/icons/telegram.svg" alt="data" />
            </a>

            <a
              href="https://bksbackstageofficial.medium.com/"
              target="_blank"
              rel="noreferrer"
            >
              <LazyLoadImage src="/img/icons/medium.svg" alt="data" />
            </a>
          </div>
          <div className={styles.about__follow_icons}>
            <a
              href="https://twitter.com/BackstageBks"
              target="_blank"
              rel="noreferrer"
            >
              <LazyLoadImage src="/img/icons/twitter.svg" alt="data" />
            </a>
            <a
              href="https://www.facebook.com/BKSBackstage"
              target="_blank"
              rel="noreferrer"
            >
              <LazyLoadImage src="/img/icons/facebook.svg" alt="data" />
            </a>
            <a
              href="https://www.instagram.com/bksbackstage/?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <LazyLoadImage src="/img/icons/instagram.svg" alt="data" />
            </a>
          </div>
        </div>
      </div>
      <div className="bottom_block"></div>
    </div>
  );
};

export default About;
