import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";

const PaymentFailedModal = () => {
  const { setModal } = useAppContext();
  const { t } = useTranslation();
  return (
    <div style={style.container}>
      <div>
        <img
          alt="payment success"
          style={style.success_img}
          src="/img/payment/x-circle.png"
        />
      </div>
      <p style={style.title}>{t("Payment Unsuccessful")}</p>
      <p style={style.info}>
      {t("payment Unsuccessful text1")}
      </p>
      <p style={style.info}>
      {t("payment Unsuccessful text2")} <a style={style.support} href="mailto:support@bksbackstage.io">support@bksbackstage.io</a>
      </p>
      <button onClick={() => setModal({ open: false })} style={style.mint_btn}>
        {t("close")}
      </button>
    </div>
  );
};

const style = {
  container: {
    width: "483px",
    maxWidth: "98%",
    textAlign: "center",
    backgroundColor: "#14142f",
    color: "white",
    padding: "57px 41px 41px 41px",
    borderRadius: "10px",
    border: "1px solid #534f77",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  success_img: {
    width: "68px",
    height: "68px",
  },
  title: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.03em",
    color: "#FFFFFF",
    margin: "22px auto auto auto",
  },
  support: {
    color: "rgba(255, 255, 255, 0.66)",
    textDecoration: "underline"
  },
  info: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.03em",
    color: "rgba(255, 255, 255, 0.66)",
    marginTop: "10px",
  },
  mint_btn: {
    border: "1px solid rgba(255, 255, 255, 0.33)",
    borderRadius: "4px",
    width: "100%",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.15em",
    padding: "14px 0",
    color: "#FFFFFF",
    marginTop: "30px",
    textTransform: "uppercase",
  },
};

export default PaymentFailedModal;
