import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

const SelfieSuccessModal = () => {
  const { setModal } = useAppContext();
  const navigate = useNavigate();
  return (
    <div className="payment__success_container">
      <div>
        <img
          alt="payment success"
          style={style.success_img}
          src="/img/payment/check-circle.png"
        />
      </div>
      <p style={style.title}>Payment Successful!</p>
      <p style={style.info}>
        A confirmation email has been sent to your email address.
      </p>
      <div style={style.mint_container}>
        <img alt="selfie" src="/img/selfie/traced.png" />
        <p style={style.selfie__title}>
          Take or upload a selfie and share with your friends!
        </p>
        <p style={style.info}>
          Share your Ha-Ash raffle ticket purchase
        </p>
        <button
          onClick={() => {
            setModal({ open: false });
            navigate("/haash-giveaway-selfie");
          }}
          style={style.mint_btn}
        >
          <p className="camera__text">START</p>
        </button>
      </div>
      <button onClick={() => setModal({ open: false })} style={style.close_btn}>
        DONE
      </button>
    </div>
  );
};

const style = {
  container: {
    width: "490px",
    maxWidth: "98%",
    textAlign: "center",
    backgroundColor: "#14142f",
    color: "white",
    padding: "57px 41px 41px 41px",
    borderRadius: "10px",
    border: "1px solid #534f77",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  success_img: {
    width: "68px",
    height: "68px",
  },
  title: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.03em",
    color: "#FFFFFF",
    margin: "22px auto auto auto",
  },
  info: {
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.03em",
    color: "rgba(255, 255, 255, 0.66)",
    margin: "10px auto 26px auto",
    maxWidth: 304,
  },
  mint_container: {
    width: "100%",
    background:
      "radial-gradient(50% 50% at 50% 0%, #2e266d 0%, rgba(255, 255, 255, 0.05) 100%)",
    borderRadius: "8px",
    padding: "18px 18px 26px 18px",
  },
  mint_info: {
    display: "flex",
  },
  info_text: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.03em",
    color: "#FFFFFF",
    textAlign: "center",
    display: "inline",
  },
  mint_btn: {
    border: "1px solid rgba(255, 255, 255, 0.33)",
    borderRadius: "4px",
    width: "100%",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.15em",
    padding: "12px 10px",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
  },
  close_btn: {
    background: "#6A4DFD",
    borderRadius: "4px",
    padding: "14px 0",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "20px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.15em",
    color: "#FFFFFF",
    marginTop: "22px",
  },
  info_image: {
    display: "inline-block",
    marginLeft: "5px",
  },
  selfie__title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.03em",
    color: "#FFFFFF",
    margin: "0 auto auto auto",
    maxWidth: "273px",
  },
};

export default SelfieSuccessModal;
