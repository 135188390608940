import { useTranslation } from "react-i18next";

const CategoryFilter = ({
  currentTag,
  setCurrentTag,
}) => {
  const { t } = useTranslation();
  return (
    <div className="explorer__top-tags">
      <button
        className={
          currentTag === "all"
            ? "category__active--tag"
            : "category__inactive--tag"
        }
        onClick={() => setCurrentTag("all")}
      >
        {t("all")}
      </button>
      <button
        className={
          currentTag === "Category1"
            ? "category__active--tag"
            : "category__inactive--tag"
        }
        onClick={() => setCurrentTag("Category1")}
      >
        {t("ticket")}
      </button>
      <button
        className={
          currentTag === "Category2"
            ? "category__active--tag"
            : "category__inactive--tag"
        }
        onClick={() => setCurrentTag("Category2")}
      >
        {t("digital art")}
      </button>
      <button
        className={
          currentTag === "Category3"
            ? "category__active--tag"
            : "category__inactive--tag"
        }
        onClick={() => setCurrentTag("Category3")}
      >
        {t("service")}
      </button>
    </div>
  );
};

export default CategoryFilter;
