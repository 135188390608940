import { useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useTranslation } from "react-i18next";

import { useUserContext } from "../../../context/UserContext";
import { useAppContext } from "../../../context/AppContext";
import PhoneNumberInput from "../../PhoneNumberInput";
import { createPermission } from "../../../api/permission";
import styles from "./index.module.css";

const PermissionModal = ({ eventCard }) => {
  const { userInfo } = useUserContext();
  const { setModal, setLoading, addToast, ticketAmount } = useAppContext();
  const { t } = useTranslation();
  const [values, setValues] = useState({
    name: "",
    surname: "",
    company_name: "",
    cvrr: "",
    phone_number: "",
    bus: false,
    email: userInfo.user.email,
  });
  const initialValidation = {
    name: true,
    surname: true,
    company_name: true,
    cvrr: true,
    phone_number: true,
    bus: true,
  };
  const [validations, setValidations] = useState(initialValidation);
  const handleChange = (prop, value) => {
    setValidations((prevState) => ({ ...prevState, [prop]: true }));
    setValues({ ...values, [prop]: value });
  };
  const handlePhone = (value) => {
    handleChange("phone_number", value);
  };
  const borderStyle = (validation) => {
    return !validation
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };
  const checkValidation = () => {
    if (values.name === "") {
      setValidations({ ...initialValidation, name: false });
      return false;
    } else if (values.surname === "") {
      setValidations({ ...initialValidation, surname: false });
      return false;
    } else if (values.company_name === "") {
      setValidations({ ...initialValidation, company_name: false });
      return false;
    // } else if (values.cvrr === "") {
    //   setValidations({ ...initialValidation, cvrr: false });
    //   return false;
    } else if (values.phone_number === "" || !isValidPhoneNumber(values.phone_number)) {
      setValidations({ ...initialValidation, phone_number: false });
      return false;
    } else {
      setValidations({ ...initialValidation });
    }
    return true;
  };

  const submit = async (e) => {
    e.preventDefault();
    if (!checkValidation()) return;
    setLoading(true);
    await createPermission({
      ...values,
      eventcard_id: eventCard.id,
      eventcard_name: eventCard.name,
      ticket_amount: ticketAmount,
    })
    setLoading(false);
    addToast(
      "Your request has been sent to the administrator. Please wait.",
      {
        appearance: "success",
        autoDismiss: true,
      }
    );
    setModal({ open: false })
  };

  return (
    <form method="post" action="#" className={styles.container}>
      <div className={styles.top}>
        <div
          className={styles.btn_close}
          onClick={() => setModal({ open: false })}
        >
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill="#ffffff"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
          </svg>
        </div>
      </div>
      <div className={styles.title}>{t("Input your information")}</div>
      <div className={styles.wrapper}>
        <div className={styles.input_wrapper_full}>
          {t("name")}
          <input
            value={values.name}
            onChange={(e) => handleChange("name", e.target.value)}
            style={borderStyle(validations.name)}
            placeholder="Please input your name"
          />
          {!validations.name && (
            <p className={styles.warning_text}>{t("name required")}*</p>
          )}
        </div>
        <div className={styles.input_wrapper_full}>
          {t("surname")}
          <input
            value={values.surname}
            onChange={(e) => handleChange("surname", e.target.value)}
            style={borderStyle(validations.surname)}
            placeholder="Please input your surname"
          />
          {!validations.surname && (
            <p className={styles.warning_text}>{t("surname required")}*</p>
          )}
        </div>
        <div className={styles.input_wrapper_full}>
          {t("Company Name")}
          <input
            value={values.company_name}
            onChange={(e) => handleChange("company_name", e.target.value)}
            style={borderStyle(validations.company_name)}
            placeholder="Please input your company name"
          />
          {!validations.company_name && (
            <p className={styles.warning_text}>{t("Company Name Required")}*</p>
          )}
        </div>
        <div className={styles.input_wrapper_full}>
          Cvrr
          <input
            value={values.cvrr}
            onChange={(e) => handleChange("cvrr", e.target.value)}
            // style={borderStyle(validations.cvrr)}
            placeholder="Please input your cvrr"
          />
          {/* {!validations.cvrr && (
            <p className={styles.warning_text}>Cvrr Required*</p>
          )} */}
        </div>
        <div className={styles.input_wrapper_full}>
          {t("Phone Number")}
          <PhoneNumberInput
            phoneNumber={values.phone_number}
            handlePhone={handlePhone}
            borderStyle={borderStyle(validations.phone_number)}
          />
          {!validations.phone_number && (
            <p className={styles.warning_text}>{t("phone number required")}*</p>
          )}
        </div>
       
      </div>
      <div className="sign__group sign__group--checkbox">
        <input
          id="_remember"
          name="_remember"
          type="checkbox"
          checked={values.bus}
          onChange={() => {
            handleChange("bus", !values.bus);
          }}
        />
        <label htmlFor="_remember">{t("Yes I would like to be transported by bus directly to Eblers Roskilde at 11.00 from Forum")}</label>
      </div>
      <button className="asset__btn" onClick={submit} type="button">
        {t("send request")}
      </button>
    </form>
  );
};

export default PermissionModal;
