/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

import { useUserContext } from "../../../context/UserContext";
import styles from "./index.module.css";

const PageGenerateLink = () => {
  const { userInfo } = useUserContext();
  const [custom, setCustom] = useState("");
  const [seller, setSeller] = useState("");
  const [searchParams] = useSearchParams();
  const [validation, setValidation] = useState(true);
  const [validationSeller, setValidationSeller] = useState(true);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const exportFile = (media: string) => {
    if (seller !== "") {
      const origin = searchParams.get("origin");
      let referral_links = "";
      referral_links += origin + "?media=" + media + "&trackId=" + seller;

      var blob = new Blob([referral_links], {
        type: "text/plain;charset=utf-8",
      });
      saveAs(blob, "referral_links.txt");
    } else {
      setValidationSeller(false);
    }
  };

  // const exportTrackFile = (trackId: string) => {
  //   const origin = searchParams.get("origin");
  //   let referral_links = "";
  //   referral_links += origin + "?trackId=" + trackId;

  //   var blob = new Blob([referral_links], { type: "text/plain;charset=utf-8" });
  //   saveAs(blob, "referral_links.txt");
  // };

  const generateCustomLink = () => {
    if (custom !== "") {
      exportFile(custom.replaceAll(" ", "_"));
    } else {
      setValidation(false);
    }
  };

  const borderStyle = (validation: boolean) => {
    return !validation
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };

  useEffect(() => {
    if (
      userInfo?.user?.user_type === "ADMIN" ||
      (userInfo?.user?.user_type === "BUSINESS" &&
        userInfo?.user?.business_allow)
    ) {
      return;
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{t("generate referral links")}</h2>

      <div style={{ paddingLeft: 0 }}>
        <input
          id="seller"
          type="text"
          name="seller"
          className={styles.input}
          value={seller}
          onChange={(e) => {
            setValidationSeller(true);
            setSeller(e.target.value);
          }}
          style={borderStyle(validationSeller)}
          placeholder="Please input seller Id"
        />
        {!validationSeller && (
          <p className={styles.error_text}>{t("seller Id is required")} *</p>
        )}
        <button
          className={styles.generate_btn}
          onClick={() => exportFile("facebook")}
        >
          Facebook Link
        </button>
        <button
          className={styles.generate_btn}
          onClick={() => exportFile("instagram")}
        >
          Instagram Link
        </button>
        <button className={styles.generate_btn} onClick={() => exportFile("X")}>
          X Link
        </button>
        <button
          className={styles.generate_btn}
          onClick={() => exportFile("youtube")}
        >
          Youtube Link
        </button>
        <div style={{ marginTop: 30 }}>
          <input
            id="name"
            type="text"
            name="name"
            className={styles.input}
            value={custom}
            onChange={(e) => {
              setValidation(true);
              setCustom(e.target.value);
            }}
            style={borderStyle(validation)}
            placeholder="Please input custom social media"
          />
          {!validation && (
            <p className={styles.error_text}>{t("social media is required")} *</p>
          )}
          <button className={styles.generate_btn} onClick={generateCustomLink}>
            Custom SOCIAL MEDIA Link
          </button>
        </div>
      </div>
    </div>
  );
};

export default PageGenerateLink;
