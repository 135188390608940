import api from "./api";

const createCollection = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/create_collection", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const createReferralLinks = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/create_referral", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const updateCollectionCategory = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/update_collection_category", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const updateCollectionReferral = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/update_collection_referral", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getAllAddonIcons = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/event/all_addonicons")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getAllCollections = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/event/all_collections")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const createEventCard = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/create_eventcard", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getCollectionById = (id) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/event/collection/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getEventCardById = (id) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/event/eventcard/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getEventCardSeatById = (id) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/event/eventcard/seat/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getEventCardInCollection = (id) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/event/in_collection/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getLatestEventCards = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/event/eventcard_multi/latest")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getAllEventCards = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/event/eventcard_multi")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getVerifyEventCards = () => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard_multi/verify")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const allEventCards = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/event/eventcard_multi/all")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getAllLikeNumbers = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/event/eventcard_multi/like_numbers")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const deleteEventCardById = (id) => {
  return new Promise((resolve, reject) => {
    api
      .delete("/api/event/eventcard/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const verifyEventCardById = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard/verify", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const saleStopEventCard = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/sale_stop", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const deleteCollectionById = (id) => {
  return new Promise((resolve, reject) => {
    api
      .delete("/api/event/collection/" + id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const buyTicket = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard/buy_ticket", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const userTickets = (data) => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/event/eventcard_multi/user_tickets")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getTicketsFromNFTs = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard_multi/tickets_nfts", {
        tokenURLs: data.tokenURLs,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const updateUserTickets = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard_multi/user_tickets", {
        id: data.id,
        tokenURL: data.tokenURL,
        ipfsURL: data.ipfsURL,
        is_minted: data.is_minted,
        nft_address: data.nft_address,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const findUserTickets = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard_multi/find_tickets", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getTicketById = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard_multi/ticket_by_id", {
        data,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const updateEventLike = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard_multi/update_like", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const updateEventDescription = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard_multi/update_description", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const increaseViews = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard_multi/increase_views", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const updateEventPrice = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard_multi/update_price", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const makeEventEnable = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard_multi/make_enable", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getAvailableEvents = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/event/eventcard_multi/available_events")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const allTickets = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard_multi/tickets", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const businessTickets = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard_multi/business_tickets", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const eventsTickets = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard_multi/find_tickets_events", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const businessEvents = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/event/eventcard_multi/business_events", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export {
  createCollection,
  createReferralLinks,
  updateCollectionCategory,
  updateCollectionReferral,
  getAllCollections,
  getAllAddonIcons,
  getCollectionById,
  getEventCardInCollection,
  createEventCard,
  getEventCardById,
  getEventCardSeatById,
  getAllEventCards,
  getLatestEventCards,
  buyTicket,
  userTickets,
  updateUserTickets,
  updateEventLike,
  updateEventDescription,
  updateEventPrice,
  allTickets,
  getAvailableEvents,
  deleteEventCardById,
  verifyEventCardById,
  deleteCollectionById,
  makeEventEnable,
  getTicketsFromNFTs,
  getTicketById,
  businessTickets,
  saleStopEventCard,
  findUserTickets,
  getAllLikeNumbers,
  eventsTickets,
  increaseViews,
  businessEvents,
  allEventCards,
  getVerifyEventCards,
};
