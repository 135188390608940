import { Link } from "react-router-dom";
import styles from "./index.module.css"

const PageTermsPrivacy = () => {
  return (
    <div className="container">
      <div className="row row--grid">
        <div className="col-12">
          <ul className="breadcrumb">
            <li className="breadcrumb__item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb__item breadcrumb__item--active">
              Terms And Privacy
            </li>
          </ul>
        </div>

        <div className="col-12">
          <div className="main__title main__title--page">
            <h1>TERMS OF USE AND PRIVACY POLICY</h1>
            <p>Bksbackstage</p>
            <p>Revision of 16 September 2021</p>
            <p>
              The Terms of Use and Privacy Policy are a Public Agreement and
              generally is a Public Offer Agreement. All its Terms are the same
              for all users, regardless of their legal status and form.
            </p>
            <p>
              The Terms of Use and the Privacy Policy apply to access and use of
              the http://bksbackstage.io website and/or Mobile Application and
              all other Backstage products and services (hereinafter referred to
              as the services, service, application, website,
              http://bksbackstage.io website, "we", "us", "our").
            </p>
            <p>
              By downloading, using, registering on the Website and/or
              Application, viewing, submitting content or otherwise accessing
              and/or using the service, the user (hereinafter referred to as
              "you", "to you", "your") agrees to the Terms and Policy set out
              below. If you do not wish to agree to the Terms and Policy set out
              in this document, please stop using our service.
            </p>
            <p>Minimum age.</p>
            <p>
              The Website and/or Application (App) services are available only
              to persons who are of legal age under the laws of their country of
              residence and/or nationality and capable of entering into legally
              binding agreements following the applicable law. Without limiting
              the foregoing, the Services on the Application and/or the Website
              are not intended for use by minors. If you do not comply with
              these requirements, please do not use the Services and/or
              Application and do not access the Website.
            </p>
            <p>
              Any further use of the Website and/or Application, or any part
              thereof, indicates that you have read and understood the Terms of
              Use and Privacy Policy and agree to comply with all parts of the
              Terms of Use and Privacy Policy.
            </p>
            <p>Changes to the Terms of Use.</p>
            <p>
              The stated here Terms of Use and Privacy Policy are subject to
              change. Each time we make changes to this Privacy Policy and Terms
              of Use, we will post the revised Policy on this page and indicate
              the date of the revision.
            </p>
            <p>
              Any changes to the Terms and Privacy Policy will take effect
              immediately upon publication. Your continued use of the Service
              after changes to the Terms and Policy shall constitute your
              acceptance of such changes. If you do not wish to agree to any
              changes to the Terms and Policy, you should stop using the Service
              immediately.
            </p>
            <p>
              The outlined Terms and Policy will also govern any updates or new
              versions of the Website or Application that may replace and/or
              supplement the original Application, or the Website. If an update
              or new version is not accompanied by the Terms and Policy, the
              provided Terms and Policy shall take precedence.
            </p>
            <p>Terminology and definitions.</p>
            <p>
              Backstage services refer to the various services provided to you
              by the Backstage that are based on Internet and/or blockchain
              technologies and are offered via the http://bksbackstage.io
              website, or Mobile Application, as well as other forms (including
              new ones that will become possible due to future technological
              developments). Backstage services include, among others, Backstage
              components such as digital asset trading platforms.
            </p>
            <p className={styles.mt__zero}>
              Digital currencies are encrypted or digital tokens or
              cryptocurrencies with a certain value that are based on blockchain
              and cryptography technologies and are issued and managed in a
              decentralized form. Digital assets are digital currencies, their
              derivatives or other types of digital assets with a certain value.
            </p>
            <p className={styles.mt__zero}>
              Backstage account refers to the underlying virtual accounts,
              including master accounts and subaccounts, which are opened in the
              Backstage for users and to record on the Backstage their usage,
              transactions, asset changes and basic information.
            </p>
            <p className={styles.mt__zero}>
              Account is an access account that contains the information that
              the user has provided to the Administration during the
              registration process at http://bksbackstage.io or during the
              account. Backstage access account serves as the basis for the user
              to exercise their rights in Backstage.
            </p>
            <p className={styles.mt__zero}>
              Login is a unique identifier of the user, used to designate and
              identify the user on the http://bksbackstage.io website.
            </p>
            <p className={styles.mt__zero}>
              Password is a set of symbols used to confirm the user's identity
              now of entering the account (authorization).
            </p>
            <p className={styles.mt__zero}>
              User is any natural or legal person using the Services on the
              http://bksbackstage.io website or in the Appendix.
            </p>
            <p className={styles.mt__zero}>
              Administrator is Bksbackstage OÜ, Tartu mnt 67/1-13b 10115 Tallinn
              Estonia Administration – employees of the Administrator who manage
              the http://bksbackstage.io website, perform technical,
              organizational and other functions to ensure the functioning of
              the http://bksbackstage.io website services, as well as provide
              information, consultancy and other support to the
              http://bksbackstage.io website.
            </p>
            <p className={styles.mt__zero}>
              Cryptocurrency exchange refers to transactions in which digital
              currency is exchanged for matching goods, works, services or other
              cryptocurrencies or cash.
            </p>
            <p>Registration and account.</p>
            <p>
              To receive Services on the Website or Application, the user must
              register an account. Registration is carried out by filling in the
              relevant information (electronic form) on the Website or
              Application and submitting the application for account
              registration, which must be approved by the Administration.
            </p>
            <p className={styles.mt__zero}>
              Account registration is deemed to be approved by the
              Administration from the moment the user is notified of the
              creation of their account by sending a corresponding email to the
              details provided by the user during registration.
            </p>
            <p className={styles.mt__zero}>
              Please note that certain Services may not be available in certain
              jurisdictions or regions or for certain users. We reserve the
              right to change, modify or impose additional restrictions at our
              sole discretion at any time on your account.
            </p>
            <p className={styles.mt__zero}>
              Information that is required to be completed includes: Surname,
              First Name, email address, country of location. You may not create
              more than one user account for the same person. The user's account
              cannot be transferred to third parties.
            </p>
            <p className={styles.mt__zero}>
              Your account registration will be deemed to be your agreement to
              provide, if necessary, information for identity verification. Such
              information will be used to verify the identity of user(s), detect
              money laundering, terrorist financing, fraud and other financial
              crimes through our service, or for other lawful purposes stated by
              us. We will collect, use and share such information following our
              Privacy Policy. In addition to providing such information, you
              agree to allow us to maintain a record of that information for the
              period that your account is active and for five (5) years after
              your account is closed, under global industry standards for data
              retention. You also authorize us to conduct necessary
              investigations directly or through third parties to verify your
              identity or protect you and/or us from financial crimes such as
              fraud. The information we require to verify your identity may
              include, but is not limited to, contact details, telephone number,
              government-issued identity card, date of birth and other
              information. Providing the required information, you confirm that
              it is correct and accurate. Once you have completed registration
              for your Backstage account, you can use various Backstage
              services, including but not limited to cryptocurrency trading.
            </p>
            <p>Account security.</p>
            <p>
              The user undertakes not to disclose to third parties the username
              and password indicated or obtained during registration. All
              activities carried out on his behalf, i.e. using his username and
              password, are the sole responsibility of the user.
            </p>
            <p className={styles.mt__zero}>
              You also agree to be solely responsible for taking the necessary
              security measures to protect your account and Personal Data on the
              Backstage.
            </p>
            <p className={styles.mt__zero}>
              By creating a Backstage account, you agree that:
            </p>
            <p className={styles.mt__zero}>
              - you will notify the Backstage immediately if you become aware of
              any unauthorized use of your account and password or any other
              breach of security;
            </p>
            <p className={styles.mt__zero}>
              - you will strictly comply with all the Backstage mechanisms and
              procedures relating to security, authentication, buying-trading,
              charging and withdrawals;
            </p>
            <p className={styles.mt__zero}>
              - you will take appropriate steps to properly log out of your
              account at the end of each visit session.
            </p>
            <p>Prohibited use.</p>
            <p>
              You agree not to upload, post, email or otherwise transmit any
              material that contains software viruses or any other computer
              code, files or programs designed to interrupt, damage, destroy or
              limit the functionality of any computer software or hardware or
              equipment associated directly or indirectly with the Website or
              Application.
            </p>
            <p className={styles.mt__zero}>
              You agree not to interfere with the servers or networks
              underlying, connected to the Website or Application, or violate
              any procedures, policies or rules of the networks connected to the
              Website and/or Application.
            </p>
            <p className={styles.mt__zero}>
              Furthermore, you agree not to rent, sublicense, translate, merge,
              adapt or modify the Backstage Application or any related
              documentation, use the Backstage Application only for your
              personal use (if you are a consumer) or for internal business
              purposes (if you are a business).
            </p>
            <p>
              You agree not to permit any third party to copy, adapt, reverse
              engineer or create derivative works from all or any part of the
              Backstage Application and\or Website, or attempt to do any such
              thing. The Application and Website may contain certain third party
              software, plug-ins or application interface (collectively referred
              to as "Third Party Software") for which the separate Terms of
              Agreement are required.
            </p>
            <p>
              You agree not to impersonate or attempt to impersonate the
              Backstage, Backstage service, Backstage employee, another user or
              any other person or entity (including but not limited to the use
              of email addresses or aliases associated with any of the
              foregoing).
            </p>
            <p className={styles.mt__zero}>
              You agree not to transmit or cause to be sent any promotional or
              marketing material without our prior written consent, including
              any "junk mail", "chain letters", "spam" or any other similar
              requests.
            </p>
            <p className={styles.mt__zero}>
              Likewise, you agree not to use the Services to manipulate the
              market
            </p>
            <p>Intellectual property rights</p>
            <p>
              The software, data, trademarks, intellectual property, including
              copyright, and other objects used on the Website or Application
              are the intellectual property of its legal owners and are
              protected by intellectual property legislation as well as by the
              relevant international legal treaties and conventions.
            </p>
            <p>
              Any use of the elements forming part of the Website or the
              Application (symbols, text, graphics, software and any other
              objects), other than those permitted by this document, without
              permission, is unlawful and may give rise to civil, administrative
              and criminal penalties under applicable law.
            </p>
            <p>
              If you are the owner of the trademark and/or other intellectual
              property and believe that materials on the Website or in the
              Application infringe your rights, please write to us at
              stage@bksbackstage.io.
            </p>
            <p className={styles.mt__zero}>
              In your request, please include the following information:
            </p>
            <p className={styles.mt__zero}>
              ● identify yourself (name, correspondence address, email);
            </p>
            <p className={styles.mt__zero}>
              ● describe the nature of the intellectual property infringement
              and identify the relevant intellectual property item on the
              Website or in the Application.
            </p>
            <p>Reliance on published information.</p>
            <p>
              The information provided on or through the http://bksbackstage.io
              website and in the Appendix is provided for general information
              purposes only. We do not guarantee the accuracy, completeness or
              usefulness of this information. Your reliance on such information
              is entirely at your own risk. We disclaim all liability and
              responsibility arising out of your or any other visitor to the
              Website and/or Application, or anyone else who may be informed of
              its content, in connection with the use of such material.
            </p>
            <p>Links to websites and social media functions.</p>
            <p>
              You may link to our Website, provided you do so in a way that is
              fair and legal and does not damage our reputation or take
              advantage of it, but you must not establish a link in such a way
              as to suggest any form of association or endorsement on our part
              without our express written consent. If the websites contain links
              to other sites and resources provided by third parties, these
              links are provided for your convenience only. This includes links
              contained in advertisements, including banner ads and sponsored
              links. We do not control the content of these sites or resources
              and are not responsible for them or for any loss or damage that
              may arise from your use of them. If you decide to access any of
              the third-party websites linked to this Website, you do so
              entirely at your own risk and follow such websites Terms of use
              of.
            </p>
            <p>Limitation of liability</p>
            <p>
              The Backstage will use its reasonable endeavours to operate the
              http://bksbackstage.io website and Application appropriately but
              will not be liable to the user or any third party for any direct
              and/or indirect damages, including loss of profits or lost data,
              damage to honour, dignity or goodwill incurred in connection with
              the use or inability to use the http://bksbackstage.io website or
              Application, or the unauthorized access of third parties to the
              user's communications, or inability to obtain Services, in
              particular:
            </p>
            <p>
              a&#41; in the presence of technical failures of public
              communication channels or loss of access to the Internet – until
              such failures are eliminated or access is restored, respectively;
            </p>
            <p>
              b&#41; if there is evidence of unauthorized access to the user's
              account management – for the duration of such circumstances;
            </p>
            <p>
              c&#41; when the security of the equipment (personal computer,
              smartphone) used by the user to receive Services on the
              http://bksbackstage.io website (access to the Account) is breached
              or malfunctions.
            </p>
            <p>
              The Backstage will not be liable for any loss incurred by the user
              because of the user not having read or not having read promptly
              the Terms set out in this agreement.
            </p>
            <p className={styles.mt__zero}>
              The user agrees that the Terms of the limitation of liability set
              out above reflect an intelligent and fair allocation of risk. For
              the avoidance of any misinterpretation of the provisions on
              limitation of liability, the parties explicitly point out that the
              user will not be denied any legal right or right that arises from
              the contractual relationship between the user and Backstage.
            </p>
            <p className={styles.mt__zero}>
              The parties are exempt from liability for delay and/or partial or
              complete failure to fulfil obligations under this Agreement if
              this failure was the result of force majeure circumstances (Force
              Majeure), which are beyond the control of the parties, and which
              cannot be overcome by reasonable methods.
            </p>
            <p>Disclaimer of Warranties.</p>
            <p>
              The Backstage is not your broker, agent or advisor and has no
              fiduciary relationship or obligation to you in connection with any
              transactions or other decisions or actions made by you using the
              Backstage services. None of the communications or information
              provided to you by http://bksbackstage.io is intended or should be
              construed as investment, financial, trading or any other advice.
              Unless otherwise specified in the Terms, all transactions are
              executed automatically based on the parameters of your
              order-asking instructions and following published procedures for
              executing transactions, and you are solely responsible for
              determining whether any investment, investment strategy or related
              transaction is appropriate for you under your investment
              objectives, financial circumstances and possible risk, and you are
              solely responsible for any loss or liability in connection
              therewith. You should consult lawyers or tax professionals
              regarding your particular situation. The Backstage does not
              recommend buying, earning, selling or holding any Digital Assets.
              You should conduct your own due diligence and consult with your
              financial advisors before making any investment decision to buy,
              sell or hold any Digital Asset. The Backstage is not responsible
              for decisions you make about buying, selling or owning Digital
              Assets based on information provided by the Backstage.
            </p>
            <p className={styles.mt__zero}>
              You should conduct your due diligence and consult with your
              financial advisers before making any investment decision to buy,
              sell or hold any Digital Asset. The Backstage is not responsible
              for decisions you make about buying, selling or owning Digital
              Assets based on information provided by the Backstage. You also
              understand and acknowledge that we cannot and do not guarantee or
              warrant that files available for downloading from the internet or
              websites will be free of viruses or other destructive code. You
              are responsible for implementing sufficient procedures and
              checkpoints to satisfy your particular requirements for antivirus
              protection and accuracy of data input and output, and for
              maintaining a means external to our site for the recovery of any
              lost data.
            </p>
            <p>Geographical restrictions.</p>
            <p>
              The Administrator of the http://bksbackstage.io website is located
              in the EU. We make no representations that the Website or any
              content is available or appropriate outside the EU. Accessing the
              http://bksbackstage.io website may be illegal for certain
              individuals or in certain countries. If you access the
              http://bksbackstage.io website from outside the EU, it will be on
              your initiative, and you are responsible for complying with local
              laws.
            </p>
            <p>Reimbursement.</p>
            <p>
              You agree to indemnify and hold Backstage, its affiliates,
              licensors and service providers and its officers, directors,
              employees, contractors, agents, successors and assigns harmless
              from any claims and liabilities, damages, judgments, costs,
              expenses or fees (including reasonable attorneys' fees) arising
              out of or relating to your breach of the Terms of Use or your use
              of the http://bksbackstage.io website, including, without
              limitation, any use of content, service or product, except where
              expressly disclosed.
            </p>
            <p>Legislation and regulatory jurisdiction.</p>
            <p>
              All matters relating to the http://bksbackstage.io website and the
              Terms of Use and any dispute or claim arising out of or in
              connection therewith (in each case including non-contractual
              disputes or claims) shall be governed by and construed following
              Estonian domestic law, without giving effect to any choice or
              provision or rule of conflict of law.
            </p>
            <p>
              Any legal action or proceeding arising out of the Terms of Use or
              the http://bksbackstage.io website or its affiliates shall be
              brought exclusively in Estonia, although we reserve the right to
              bring any legal action, or initiate legal proceedings, against you
              for violations of the Terms of Use in your country of residence or
              any other relevant country.
            </p>
            <p className={styles.mt__zero}>
              You waive any objection to the exercise of jurisdiction over you
              by such courts and the hearing of your case in such courts.
            </p>
            <p>Other Terms.</p>
            <p>Using the Application while driving</p>
            <p className={styles.mt__zero}>
              Always drive carefully and per road conditions and applicable
              traffic regulations. Any direct interaction with the Application
              while driving is prohibited. If you wish to interact with the
              Application while driving, you must first stop your vehicle
              properly and legally and in the correct place. You acknowledge
              that you are responsible for complying with all applicable laws
              when you use the Application in any way while driving, and that
              you use the Application in any way while driving at your own risk.
            </p>
            <p className={styles.mt__zero}>
              The Backstage CANNOT BE LIABLE FOR ANY DIRECT OR CONSEQUENTIAL
              DAMAGES, INCLUDING INCIDENTS, INJURY OR OTHER DAMAGES, ARISING OUT
              OF THE USE OF THE APPLICATION WHILE DRIVING.
            </p>
            <p>Details.</p>
            <p>Bksbackstage OÜ,</p>
            <p className={styles.mt__zero}>Tartu mnt 67/1-13b 10115 Tallinn Estonia</p>
            <p className={styles.mt__zero}>Email: stage@bksbackstage.io</p>
            <p>Backstage Privacy and Personal Data Protection Policy</p>
            <p>Revision of 16 September 2021</p>
            <p>
              This Privacy Policy describes how the Backstage (the "Company" or
              "we", "us") uses and shares information collected by us or
              provided by you during your visit to the Website, which is located
              at: http://bksbackstage.io or in the Backstage Application&#41;.
            </p>
            <p>
              The owner of Personal Data is Bksbackstage OÜ ("Backstage"),
              located at Tartu mnt 67/1-13b 10115 Tallinn Estonia
            </p>
            <p>
              This Privacy Policy also explains your rights and options
              regarding your Personal Data and how you can contact us to make
              changes to your Personal Data or get answers to questions you may
              have about our privacy practices.
            </p>
            <p className={styles.mt__zero}>
              The terms of this Privacy Policy are subject to change from time
              to time. Each time we make changes to this Privacy Policy we will
              post the revised version on this page and indicate the date of the
              new version.
            </p>
            <p>
              Please read this Privacy Policy carefully and contact us by email
              if you have any questions.
            </p>
            <p className={styles.mt__zero}>
              By using the Website and/or Application, by receiving Services on
              the http://bksbackstage.io website or by checking the appropriate
              box (e.g. by clicking on "Agree") you agree to all the terms of
              this Privacy Policy, confirm that you have read the terms set out
              herein and give us your free and explicit consent to use your
              Personal Data following the terms of this Privacy Policy.
            </p>
            <p>DEFINITION OF TERMS</p>
            <p>
              Owner of Personal Data is the legal entity that determines the
              purpose of Personal Data processing, establishes the composition
              of such data and the processing procedures, unless otherwise
              specified by law.
            </p>
            <p className={styles.mt__zero}>
              Personal Data is information or a set of information about an
              individual that is or can be specifically identified.
            </p>
            <p className={styles.mt__zero}>
              Non-Personal Data is information and data that, in itself, does
              not directly identify a specific individual.
            </p>
            <p className={styles.mt__zero}>
              Information means both Personal Data and Non-Personal Data.
            </p>
            <p className={styles.mt__zero}>
              User means a natural person who accesses, uses and/or orders the
              http://bksbackstage.io website and/or Application.
            </p>
            <p>
              Processing – any action with Personal Data including, but not
              limited to: access, provision, distribution, collection,
              systematization, storage, accumulation, recording, transfer,
              blocking, deletion, clarification, updating and/or amendment,
              depersonalization and other uses of Personal Data carried out by
              the Company.
            </p>
            <p>1. GENERAL PROVISIONS</p>
            <p>
              We process your Personal Data solely to fulfil your order,
              application, commission and to provide you with necessary
              information about the status of your order, to register for an
              account, to participate in our promotional activities when you
              contact our customer support, and when you subscribe to our
              newsletters, participate in surveys or provide feedback about our
              Services.
            </p>
            <p>
              The information you provide will be treated confidentially and
              will not be disclosed to any third party, except to fulfil your
              order, request, instruction or as otherwise required by law.
            </p>
            <p>
              The provisions of this Privacy Policy apply only to the Website
              and/or Backstage Application.
            </p>
            <p className={styles.mt__zero}>
              The Company has no control over and accepts no responsibility for
              any third-party websites that you may link to from the Website.
              Please note that the Company does not verify the accuracy of the
              information provided by the user.
            </p>
            <p>
              The information about you is processed from the moment you log on
              to the Website and start using the website, and from the moment
              the Company receives your Personal Data sent by email, through the
              Website or provided through other means of communication.
            </p>
            <p>2. INFORMATION WE MAY COLLECT OR RECEIVE</p>
            <p>
              The Company only collects and uses the Personal Data of users that
              is necessary to fulfil their service order and/or to register on
              the Website and/or in the Application to create an
              account/account. It is the first name, surname and e-mail address
              for the user.
            </p>
            <p>
              The user at his or her sole discretion may provide other
              information (unless such information is required to enable the
              performance of an agreement or is required by law).
            </p>
            <p>
              In addition, we use cookie technology to collect additional data
              about the use of the Website and to improve the Website and the
              quality of our Services.
            </p>
            <p>
              Cookies are small files that a Site, network software services or
              service providers place on your device through your web browser
              (if you allow them to), which allows the Site or service providers
              to recognize your browser and store and remember certain
              information.
            </p>
            <p>We can use cookies to process information such as:</p>
            <p>
              Device data: Hardware model, unique device identifiers, MAC
              address, IP address, operating system version and device settings;
            </p>
            <p className={styles.mt__zero}>
              Information from Log files: time and duration of use of the
              website, search queries, location information and any other
              information stored in cookies that allows you to uniquely identify
              your browser or account;
            </p>
            <p className={styles.mt__zero}>
              Other data: data about your use of the Website that we may process
              if you visit or use third-party websites or Applications to
              contact us, how you interact with content on the Website and/or
              Application.
            </p>
            <p>
              We use cookies for many important things – for example, to help
              you log in and to improve your experience of using our Website
              and/or Application or Services. We may use cookies to better
              understand how you interact with the Website and/or Application,
              to monitor the overall number of users of the Website and/or
              Application and the routing of web traffic to and from the Website
              and/or Application, to improve the Website and/or Application and
              our Services to understand your preferences based on previous or
              current activity on the Website and/or Application, which enables
              us to provide you with better services.
            </p>
            <p>
              We do not collect and ask you not to provide us with any
              information for the processing of which certain restrictions and
              requirements are established by the Law, namely, information about
              racial or ethnic origin, political, religious or ideological
              beliefs, membership in political parties and trade unions,
              convictions in criminal cases or on suspicion of committing crimes
              in criminal cases, as well as data related to health, sexuality,
              biometric and genetic data.
            </p>
            <p>3. HOW WE CAN USE PERSONAL DATA</p>
            <p>
              We may use the information we collect as described above for the
              following purposes:
            </p>
            <p>Contractual relationship.</p>
            <p className={styles.mt__zero}>
              We may process your Personal Data to fulfil contractual
              obligations – to pass on information about you to Service
              Providers, to process your order, application, order
            </p>
            <p>Enquiries and other communications.</p>
            <p className={styles.mt__zero}>
              Where you have contacted us through our contact forms and/or by
              email/phone, we may process the information you have given us to
              respond to your question, complaint or enquiry.
            </p>
            <p>Marketing.</p>
            <p className={styles.mt__zero}>
              We may use the information we receive from you (e.g. your email)
              for marketing purposes. For example, by subscribing to our
              newsletter, you may receive publications and announcements
              directly to your email. We may send you promotional material
              relating to our Services, promotional offers which we think you
              may be interested in.
            </p>
            <p>
              You can unsubscribe from receiving our email marketing
              communications (whether in whole or in part) by clicking on the
              'unsubscribe link in the emails you receive from us. In addition,
              to unsubscribe, you can contact us at any time by emailing us to
              the email address set out in this Privacy Policy.
            </p>
            <p>
              We may also use the information generated by the cookies to
              conduct market research, to analyse the characteristics of the
              users of the Website, to evaluate the effectiveness of our
              marketing communication and to adjust to current trends, to plan
              our future marketing campaigns, business intelligence, to
              personalize the Services and communications for you.
            </p>
            <p>Protection of our interests, security.</p>
            <p className={styles.mt__zero}>
              We may use your Personal Data where we believe it is necessary to
              take measures to prevent possible liability, to investigate and
              protect the Company from any third-party claims or allegations, to
              investigate and protect the Company from fraud, to protect the
              security or integrity of the Website and/or Application and to
              protect the property rights of our Company, users and/or partners.
            </p>
            <p>Compliance with Law</p>
            <p className={styles.mt__zero}>
              We may also use/disclose your information we collect to comply
              with legal requirements, industry standards and our policies. We
              may disclose your information in situations that we believe (1)
              are emergencies involving a potential threat to the physical
              safety of any person or property if we believe your information is
              in any way related to such a threat; (2) are related to illegal or
              inappropriate, in our opinion, use of the Site, Application and/or
              Services.
            </p>
            <p>Information processing includes:</p>
            <p className={styles.mt__zero}>
              Collecting, accumulating, storing, adapting, verifying, changing,
              restoring, using, depersonalizing, destroying Personal Data,
              including using information (automated) systems.
            </p>
            <p className={styles.mt__zero}>
              Personal Data may be collected verbally, in writing and/or
              electronically, through requests or by submitting the necessary
              information and documents yourself. Personal Data is collected and
              stored in written and/or electronic form.
            </p>
            <p>4. HOW WE CAN PASS ON PERSONAL DATA</p>
            <p>
              We pay special attention to the privacy and protection of your
              Personal Data. The information you provide will be kept
              confidential and will not be passed on to third parties, except
              for the performance or provision of the service(s) and as required
              by law.
            </p>
            <p>
              The user agrees that the Company has the right to pass on users'
              Personal Data to suppliers - solely to perform the relevant
              service.
            </p>
            <p>
              We may disclose your Personal Data if required to do so by law or
              to comply with a legal obligation or if we believe that such
              action is necessary to: investigate, respond to and defend against
              claims; if necessary in legal proceedings (including subpoena) to
              protect the rights and property of the Company or third parties;
              to prevent the potential occurrence of liability; for public
              safety or the safety of individuals; to prevent or stop any
              illegal, unethical, fraudulent, abusive or other activities that
              may have negative legal consequences; for the integrity and
              security of the Website and/or Application, our Services, any
              equipment used in the operation of the Website and/or Application
              in providing Services; to comply with applicable law.
            </p>
            <p>5. HOW WE STORE AND PROTECT YOUR PERSONAL DATA</p>
            <p>
              Protecting your Personal Data is extremely important to us, so we
              take all necessary steps to take so. We store users' Personal Data
              in a secure environment. Your Personal Data is protected from
              unauthorized access, disclosure, use, alteration or destruction.
            </p>
            <p>
              The Company monitors the security measures taken on an ongoing
              basis.
            </p>
            <p>
              We will store your Personal Data for as long as is necessary to
              allow you access to the Site and/or to fulfil our legal
              obligations (fulfilment of your orders and services, tax and
              financial reporting), resolving disputes and complying with our
              policies.
            </p>
            <p>
              The storage period is determined by the type of information
              collected and the purpose for which it was collected, depending on
              the situation and the need to remove outdated or unnecessary
              information as soon as possible.
            </p>
            <p>
              If you stop using the Website and/or Application and our Services
              by deleting your account on the Website and/or Application, your
              Personal Data will also be automatically deleted (unless we are
              obliged to store such data to comply with statutory obligations).
            </p>
            <p>6. YOUR RIGHTS</p>
            <p>Concerning your Personal Data, you have the right:</p>
            <p className={styles.mt__zero}>
              - at any time to contact the Personal Data controller with any
              questions or complaints about the processing of Personal Data, and
              to ask whether your Personal Data is being processed and about the
              content of such Personal Data;
            </p>
            <p className={styles.mt__zero}>
              - to access your Personal Data free of charge;
            </p>
            <p className={styles.mt__zero}>
              - at any time to request the Personal Data controller to cancel or
              limit the processing of Personal Data and to request that Personal
              Data be amended if it is inaccurate or no longer relevant;
            </p>
            <p className={styles.mt__zero}>
              - at any time to ask the owner of the Personal Data to delete the
              Personal Data insofar as it is no longer necessary for the
              purposes specified in this notice or for other purposes for which
              the owner of the Personal Data needs to continue processing it, or
              when the owner of the Personal Data no longer has the legal right
              to process it;
            </p>
            <p className={styles.mt__zero}>
              - to withdraw your consent to the processing of Personal Data at
              any time;
            </p>
            <p>- to file complaints about the processing of Personal Data;</p>
            <p>
              - to know the sources of collection, the location of Personal
              Data, the purpose of its processing, the location of the Personal
              Data controller or disposer, or give an appropriate instruction to
              obtain this information to persons authorized by them, except in
              cases established by law;
            </p>
            <p>
              - to receive information on the conditions of access to Personal
              Data, including information on third parties to whom Personal Data
              is transferred;
            </p>
            <p>
              - to make a reasoned request to the owner objecting to the
              processing of his/her Personal Data;
            </p>
            <p>
              - to protect Personal Data against unlawful processing and
              accidental loss, destruction, damage due to deliberate
              concealment, failure to provide or untimely provision, as well as
              to protect against providing information that is inaccurate or
              that defames the honour, dignity and business reputation of a
              natural person;
            </p>
            <p>
              - to apply legal remedies in the event of a breach of Personal
              Data protection legislation;
            </p>
            <p>
              - to make reservations about the right to restrict the processing
              of one's Personal Data at the time of giving consent;
            </p>
            <p>
              - to know the mechanism of automatic processing of Personal Data;
            </p>
            <p>
              - to be protected against an automated decision that has legal
              consequences for him or her.
            </p>
            <p>
              If you would like to exercise any of the above rights or would
              like further information, please contact us at the details below
              in this Privacy Policy.
            </p>
            <p>
              Please note, however, that these rights are not absolute and may
              be somewhat limited by regulatory requirements and our legitimate
              interests.
            </p>
            <p>
              To respond to your request, we need to identify you so that your
              information is not shared with an unauthorized person.
            </p>
            <p>
              The Company must provide you with information within ten days of
              receiving your request about the action taken to your request. The
              time limit for providing a final response to your request is up to
              30 days from receipt of the request. However, for third parties
              responding to an enquiry, the time limit may be extended to 45
              days from receipt of the enquiry.
            </p>
            <p>
              If you become aware that someone has unlawfully provided us with
              your Personal Data, please notify us as soon as possible by using
              the contact details provided in this Privacy Policy. Upon your
              request, we will delete your Personal Data as soon as possible,
              but in any event no later than one month after we receive your
              request. We will only store such copies of the information as may
              be necessary for us to comply with the requirements of Law for
              such cases.
            </p>
            <p>
              In addition, you may amend (update, supplement or delete) the
              Personal Data you have provided at any time via your account (if
              you are a registered user of the Website and/or Application) or by
              contacting us with a request.
            </p>
            <p>7. CHILDREN</p>
            <p>
              We understand how important it is to take additional measures to
              protect the privacy and safety of children using the Site.
              Children under the age of 18 years are not allowed to use our
              Website and/or Application and receive our Services unless their
              parents have given their consent, which must be supported by
              appropriate evidence. We do not purposefully collect Personal Data
              from children under the age of 18 years. If we learn that we have
              received Personal Data of a child under the age of 18 years, other
              than in the above circumstances, we will take all necessary steps
              to delete such information as soon as possible, only if we are not
              required to store such information to comply with legal
              requirements.
            </p>
            <p>8. UPDATING THIS PRIVACY POLICY</p>
            <p>
              Each time we make changes to this Privacy Policy the new version
              will be available on the Website and in the Application marked
              "New Version of ... ".
            </p>
            <p>
              You will have access to the revised Privacy Policy from the day it
              takes effect, so you will have time to decide whether to continue
              using the site and to raise objections to changes in the
              processing of your Personal Data. A draft of a new Privacy Policy
              may be published in advance.
            </p>
            <p>9. RIGHTS OF THIRD PARTIES</p>
            <p>
              To ensure its obligations to users, the Company may transmit user
              data to Providers and vice versa, but only to the extent necessary
              to perform the relevant Services, which are carried out on the
              Website and/or in the Application.
            </p>
            <p>10. QUESTIONS AND COMPLAINTS</p>
            <p>
              If you have any questions, concerns or complaints about our
              methods of collecting and processing your Personal Data, or if you
              have any suggestions or questions about this Privacy Policy,
              please contact us:
            </p>
            <p>11. HOW TO CONTACT US</p>
            <p>Our contact details:</p>
            <p className={styles.mt__zero}>Bksbackstage OÜ,</p>
            <p className={styles.mt__zero}>Tartu mnt 67/1-13b 10115 Tallinn Estonia</p>
            <p className={styles.mt__zero}>Email: stage@bksbackstage.io</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTermsPrivacy;
