import React from "react";
import { Link } from "react-router-dom";
// import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { isVideoFile } from "../../utils";

import styles from "./index.module.css";

const ReportItem = ({ eventcard }) => {
  const { t } = useTranslation();

  return (
    <Link to={`/sales/report/${eventcard.id}`}>
      <div className={styles.digital__item__block}>
        <div className={styles.top_part}>
          {eventcard.category === "Category1" ? (
            <>
              <div className={styles.ticket_item}>
                <img src="/img/wallet/smart_ticket.svg" alt="item" />
              </div>
              <p className={styles.item_title}>{t("smart ticket")}</p>
            </>
          ) : eventcard.category === "Category3" ? (
            <>
              <div className={styles.service_item}>
                <img src="/img/wallet/service_item.svg" alt="item" />
              </div>
              <p className={styles.item_title}>{t("service")}</p>
            </>
          ) : (
            <>
              <div className={styles.digital_item}>
                <img src="/img/wallet/digital_item.svg" alt="item" />
              </div>
              <p className={styles.item_title}>{t("digital asset")}</p>
            </>
          )}
        </div>
        <div className={styles.ticket_content}>
          <div className={styles.ticket__img_block}>
            <div className={styles.item_avatar}>
              {isVideoFile(eventcard?.picture_large) ? (
                <video autoPlay playsInline loop muted className={styles.video}>
                  <source
                    src={`${process.env.REACT_APP_ASSET_BASE_URL}/${eventcard.picture_large}`}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <img
                  src={`${process.env.REACT_APP_ASSET_BASE_URL}/${eventcard.picture_large}`}
                  alt="NFT"
                />
              )}
            </div>
          </div>
          <div className={styles.ticket__content_block}>
            <div>
              <div className={styles.ticket__title_block}>
                <p className={styles.item_name}>{eventcard.name}</p>
                <p className={styles.item_location}>{eventcard.location}</p>
              </div>
              <div className={styles.created_purchased}>
                <div>
                  <p className={styles.created_txt}>{t("created by")}</p>
                  <p className={styles.user_info}>{eventcard.creator.name}</p>
                </div>
              </div>
            </div>
          </div>
          <button className={styles.rightBtn}>
            <img src="/img/icons/chevron-right.svg" alt="right" />
          </button>
        </div>
      </div>
    </Link>
  );
};

export default ReportItem;
