import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../../context/UserContext";
import { useAppContext } from "../../../../context/AppContext";
import { allEventCards, businessEvents } from "../../../../api/event";

import ReportItem from "../../../../components/report_item";
import Pagination from "../../../../components/pagination";
import { IEventCard } from "../../../../utils/interface";

import styles from "./index.module.css";

const PageSalesOverview = () => {
  const { userInfo } = useUserContext();
  const navigate = useNavigate();
  const [allEvents, setAllEvents] = useState([]);
  const [currentTag, setCurrentTag] = useState("all");
  const [search, setSearch] = useState("");
  const [eventCards, setEventCards] = useState<IEventCard[]>([]);
  const [itemIndex, setItemIndex] = useState(0);
  const pageCount = 8;
  const { setLoading } = useAppContext();

  const sortEvents = (events: IEventCard[]) => {
    let $temp = [];
    if (currentTag === "all") {
      $temp = [...events];
    } else {
      $temp = events.filter((event) => event.category === currentTag);
    }

    let temp = $temp.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );

    let _res = temp.filter((event) =>
      search === ""
        ? event
        : event.name.toLowerCase().includes(search.toLowerCase())
    );
    setEventCards([..._res]);
  };

  const leftClick = () => {
    if (itemIndex > 0) {
      let _itemIndex = itemIndex;
      _itemIndex--;
      setItemIndex(_itemIndex);
    }
  };

  const rightClick = () => {
    if ((itemIndex + 1) * pageCount < eventCards.length) {
      let _itemIndex = itemIndex;
      _itemIndex++;
      setItemIndex(_itemIndex);
    }
  };

  useEffect(() => {}, []);

  useEffect(() => {
    sortEvents(allEvents);
    setItemIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEvents, currentTag, search]);

  useEffect(() => {
    if (userInfo && userInfo.user.user_type === "ADMIN") {
      setLoading(true);
      allEventCards()
        .then((res) => {
          setAllEvents(res.eventcards);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else if (userInfo && userInfo.user.user_type === "BUSINESS" && userInfo.user.business_allow) {
      setLoading(true);
      businessEvents({id: userInfo.user.id})
        .then((res) => {
          setAllEvents(res.eventcards);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      navigate("/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.overview__container}>
      <div className={styles.title__container}>
        <img src="/img/icons/sales.svg" alt="sales" />
        <p className={styles.title}>Sales Reports</p>
      </div>
      <div className={styles.top__part}>
        <div className={styles.btn__group}>
          <button
            onClick={() => setCurrentTag("all")}
            className={
              currentTag === "all" ? styles.active__btn : styles.category__btn
            }
          >
            All
          </button>
          <button
            onClick={() => setCurrentTag("Category1")}
            className={
              currentTag === "Category1"
                ? styles.active__btn
                : styles.category__btn
            }
          >
            Smart Tickets
          </button>
          <button
            onClick={() => setCurrentTag("Category3")}
            className={
              currentTag === "Category3"
                ? styles.active__btn
                : styles.category__btn
            }
          >
            Services
          </button>
          <button
            onClick={() => setCurrentTag("Category2")}
            className={
              currentTag === "Category2"
                ? styles.active__btn
                : styles.category__btn
            }
          >
            Digital Items
          </button>
        </div>
        <div className={styles.input__container}>
          <input
            type="text"
            placeholder="Search all reports"
            className={styles.search__input}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <img
            src="/img/icons/search.svg"
            className={styles.search__icon}
            alt="search"
          />
        </div>
      </div>
      {eventCards.map(
        (item, i) =>
          i >= (itemIndex) * pageCount &&
          i < (itemIndex + 1) * pageCount && (
            <ReportItem eventcard={item} key={i} />
          )
      )}
      <Pagination
        items={eventCards}
        setItemIndex={setItemIndex}
        pageIndex={0}
        itemIndex={itemIndex}
        leftClick={leftClick}
        rightClick={rightClick}
        count={pageCount}
      />
    </div>
  );
};

export default PageSalesOverview;
