import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../../context/AppContext";
import { useUserContext } from "../../../../context/UserContext";
import { signup } from "../../../../api/auth";

const PageUserCreate = () => {
  const { userInfo } = useUserContext();
  const navigate = useNavigate();
  const { setLoading, addToast } = useAppContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (userInfo?.user?.user_type !== "ADMIN") {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    confirm_pwd: "",
  });

  const initialValidation = {
    name: "",
    email: "",
    password: "",
    confirm_pwd: "",
  };

  const [validations, setValidations] = useState(initialValidation);

  const checkvalidations = () => {
    if (values.name === "") {
      setValidations({ ...initialValidation, name: "has-empty" });
      return false;
    } else if (values.email === "") {
      setValidations({ ...initialValidation, email: "has-empty" });
      return false;
    } else if (values.password === "") {
      setValidations({ ...initialValidation, password: "has-empty" });
      return false;
    } else if (values.confirm_pwd === "") {
      setValidations({ ...initialValidation, confirm_pwd: "has-empty" });
      return false;
    } else if (values.confirm_pwd !== values.password) {
      setValidations({ ...initialValidation, confirm_pwd: "not-match" });
      return false;
    } else {
      setValidations({ ...initialValidation });
    }
    return true;
  };

  const handleChange = (prop: string, value: string) => {
    setValidations((prevState) => ({ ...prevState, [prop]: "" }));
    setValues({ ...values, [prop]: value });
  };

  const handleCreate = async () => {
    if (!checkvalidations()) return;

    setLoading(true);

    signup({ ...values, user_type: "SUPER" })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          addToast(
            "Register success. Email was sent. Please verify the email",
            { appearance: "success", autoDismiss: true }
          );
          return;
        } else {
          addToast(res.message, { appearance: "error", autoDismiss: true });
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast("failed", { appearance: "error", autoDismiss: true });
      });
  };

  return (
    <div className="create__user_container">
      <h2 className="cr__email--title">{t("create super user")}</h2>
      <div className="cr__email--formContainer">
        <div className="create__user_item">
          <div>
            <p className="cr__email--subtitle">{t("name")}</p>
            <div className="sign__group cr__email-50">
              <input
                id="name"
                type="text"
                name="name"
                className="sign__input"
                value={values.name}
                onChange={(e) => handleChange("name", e.target.value)}
              />
              {validations.name === "has-empty" && (
                <span className="text-error">{t("name required")}*</span>
              )}
            </div>
          </div>
          <div>
            <p className="cr__email--subtitle">{t("email")}</p>
            <div className="sign__group cr__email-50">
              <input
                id="email"
                type="text"
                name="email"
                className="sign__input"
                value={values.email}
                onChange={(e) => handleChange("email", e.target.value)}
              />
              {validations.email === "has-empty" && (
                <span className="text-error">{t("email required")}*</span>
              )}
            </div>
          </div>
        </div>
        <div className="create__user_item">
          <div>
            <p className="cr__email--subtitle">{t("password")}</p>
            <div className="sign__group cr__email-50">
              <input
                id="password"
                type="password"
                name="password"
                className="sign__input"
                value={values.password}
                onChange={(e) => handleChange("password", e.target.value)}
              />
              {validations.password === "has-empty" && (
                <span className="text-error">{t("password required")}*</span>
              )}
            </div>
          </div>
          <div>
            <p className="cr__email--subtitle">{t("confirm password")}</p>
            <div className="sign__group cr__email-50">
              <input
                id="confirm_pwd"
                type="password"
                name="confirm_pwd"
                className="sign__input"
                value={values.confirm_pwd}
                onChange={(e) => handleChange("confirm_pwd", e.target.value)}
              />
              {validations.confirm_pwd === "has-empty" && (
                <span className="text-error">
                  {t("confirm password required")}*
                </span>
              )}
              {validations.confirm_pwd === "not-match" && (
                <span className="text-error">
                  {t("confirm password does not match")}*
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="create__user_btn">
        <button
          type="button"
          className="sign__btn"
          style={{ width: 300 }}
          onClick={handleCreate}
        >
          {t("create user")}
        </button>
      </div>
    </div>
  );
};

export default PageUserCreate;
