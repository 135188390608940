import { useAppContext } from "../context/AppContext";
import { useMemo } from "react";

const useTicketFiat = (price, currency, region, discount) => {
  const {
    rateEURvsUSD,
    rateGBPvsUSD,
    rateTRYvsUSD,
    rateINRvsUSD,
    rateCLPvsUSD,
    rateDKKvsUSD,
    rateSEKvsUSD,
  } = useAppContext();
  const convertedFiat = useMemo(() => {
    switch (region) {
      case "TR":
        if (currency === "USD") {
          return Number(price) / rateTRYvsUSD;
        } else if (currency === "TRY") {
          return Number(price);
        } else if (currency === "INR") {
          return (Number(price) * rateINRvsUSD) / rateTRYvsUSD;
        } else if (currency === "GBP") {
          return (Number(price) * rateGBPvsUSD) / rateTRYvsUSD;
        } else if (currency === "DKK") {
          return (Number(price) * rateDKKvsUSD) / rateTRYvsUSD;
        } else if (currency === "SEK") {
          return (Number(price) * rateSEKvsUSD) / rateTRYvsUSD;
        } else if (currency === "CLP") {
          return (Number(price) * rateCLPvsUSD) / rateTRYvsUSD;
        } else {
          return (Number(price) * rateEURvsUSD) / rateTRYvsUSD;
        }
      case "GB":
        if (currency === "USD") {
          return Number(price) / rateGBPvsUSD;
        } else if (currency === "INR") {
          return (Number(price) * rateINRvsUSD) / rateGBPvsUSD;
        } else if (currency === "TRY") {
          return (Number(price) * rateTRYvsUSD) / rateGBPvsUSD;
        } else if (currency === "DKK") {
          return (Number(price) * rateDKKvsUSD) / rateGBPvsUSD;
        } else if (currency === "SEK") {
          return (Number(price) * rateSEKvsUSD) / rateGBPvsUSD;
        } else if (currency === "GBP") {
          return Number(price);
        } else if (currency === "CLP") {
          return (Number(price) * rateCLPvsUSD) / rateGBPvsUSD;
        } else {
          return (Number(price) * rateEURvsUSD) / rateGBPvsUSD;
        }
      case "US":
        if (currency === "USD") {
          return Number(price);
        } else if (currency === "INR") {
          return Number(price) * rateINRvsUSD;
        } else if (currency === "TRY") {
          return Number(price) * rateTRYvsUSD;
        } else if (currency === "DKK") {
          return Number(price) * rateDKKvsUSD;
        } else if (currency === "SEK") {
          return Number(price) * rateSEKvsUSD;
        } else if (currency === "GBP") {
          return Number(price) * rateGBPvsUSD;
        } else if (currency === "CLP") {
          return Number(price) * rateCLPvsUSD;
        } else {
          return Number(price) * rateEURvsUSD;
        }
      case "IN":
        if (currency === "INR") {
          return Number(price);
        } else if (currency === "USD") {
          return Number(price) / rateINRvsUSD;
        } else if (currency === "TRY") {
          return (Number(price) * rateTRYvsUSD) / rateINRvsUSD;
        } else if (currency === "DKK") {
          return (Number(price) * rateDKKvsUSD) / rateINRvsUSD;
        } else if (currency === "SEK") {
          return (Number(price) * rateSEKvsUSD) / rateINRvsUSD;
        } else if (currency === "GBP") {
          return (Number(price) * rateGBPvsUSD) / rateINRvsUSD;
        } else if (currency === "CLP") {
          return (Number(price) * rateCLPvsUSD) / rateINRvsUSD;
        } else {
          return (Number(price) * rateEURvsUSD) / rateINRvsUSD;
        }
      case "CL":
        if (currency === "CLP") {
          return Number(price);
        } else if (currency === "USD") {
          return Number(price) / rateCLPvsUSD;
        } else if (currency === "TRY") {
          return (Number(price) * rateTRYvsUSD) / rateCLPvsUSD;
        } else if (currency === "DKK") {
          return (Number(price) * rateDKKvsUSD) / rateCLPvsUSD;
        } else if (currency === "SEK") {
          return (Number(price) * rateSEKvsUSD) / rateCLPvsUSD;
        } else if (currency === "GBP") {
          return (Number(price) * rateGBPvsUSD) / rateCLPvsUSD;
        } else if (currency === "INR") {
          return (Number(price) * rateINRvsUSD) / rateCLPvsUSD;
        } else {
          return (Number(price) * rateEURvsUSD) / rateCLPvsUSD;
        }
      case "EU":
        if (currency === "USD") {
          return Number(price) / rateEURvsUSD;
        } else if (currency === "INR") {
          return (Number(price) * rateINRvsUSD) / rateEURvsUSD;
        } else if (currency === "TRY") {
          return (Number(price) * rateTRYvsUSD) / rateEURvsUSD;
        } else if (currency === "DKK") {
          return (Number(price) * rateDKKvsUSD) / rateEURvsUSD;
        } else if (currency === "SEK") {
          return (Number(price) * rateSEKvsUSD) / rateEURvsUSD;
        } else if (currency === "GBP") {
          return (Number(price) * rateGBPvsUSD) / rateEURvsUSD;
        } else if (currency === "CLP") {
          return (Number(price) * rateCLPvsUSD) / rateEURvsUSD;
        } else {
          return Number(price);
        }
      case "DK":
        if (currency === "USD") {
          return Number(price) / rateDKKvsUSD;
        } else if (currency === "INR") {
          return (Number(price) * rateINRvsUSD) / rateDKKvsUSD;
        } else if (currency === "TRY") {
          return (Number(price) * rateTRYvsUSD) / rateDKKvsUSD;
        } else if (currency === "EUR") {
          return (Number(price) * rateEURvsUSD) / rateDKKvsUSD;
        } else if (currency === "GBP") {
          return (Number(price) * rateGBPvsUSD) / rateDKKvsUSD;
        } else if (currency === "SEK") {
          return (Number(price) * rateSEKvsUSD) / rateDKKvsUSD;
        } else if (currency === "CLP") {
          return (Number(price) * rateCLPvsUSD) / rateDKKvsUSD;
        } else {
          return Number(price);
        }
      case "SE":
        if (currency === "USD") {
          return Number(price) / rateSEKvsUSD;
        } else if (currency === "INR") {
          return (Number(price) * rateINRvsUSD) / rateSEKvsUSD;
        } else if (currency === "TRY") {
          return (Number(price) * rateTRYvsUSD) / rateSEKvsUSD;
        } else if (currency === "EUR") {
          return (Number(price) * rateEURvsUSD) / rateSEKvsUSD;
        } else if (currency === "GBP") {
          return (Number(price) * rateGBPvsUSD) / rateSEKvsUSD;
        } else if (currency === "DKK") {
          return (Number(price) * rateDKKvsUSD) / rateSEKvsUSD;
        } else if (currency === "CLP") {
          return (Number(price) * rateCLPvsUSD) / rateSEKvsUSD;
        } else {
          return Number(price);
        }
      default:
        return Number(price);
    }
  }, [
    price,
    currency,
    region,
    rateEURvsUSD,
    rateGBPvsUSD,
    rateTRYvsUSD,
    rateINRvsUSD,
    rateCLPvsUSD,
    rateDKKvsUSD,
    rateSEKvsUSD,
  ]);
  return Number(convertedFiat.toFixed(2)) * (1 - discount);
};

export default useTicketFiat;
