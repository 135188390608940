import React, { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import { useMediaQuery } from "usehooks-ts";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as queryString from "query-string";

import EmailVerifyModal from "../../../components/custom_modals/email_verify_modal";
import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import { login, verifyEmail } from "../../../api/auth";
import { validateEmail } from "../../../utils";
import { movePendingItems } from "../../../api/ticket";
import { getTwoFactorStatus, verifySign2FA } from "../../../api/twofactor";

const PageSignin = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookies] = useCookies();
  const { userInfo, setUserInfo } = useUserContext();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setLoading, setModal, addToast, setTwoStepStatus } = useAppContext();
  const [twoFactorInput, setTwoFactorInput] = useState(false);
  const [data, setData] = useState<any>(null);
  const [passType, setPassType] = useState("password");
  const [emailVerify, setEmailVerify] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    passcode: "",
  });
  const [validations, setValidations] = useState({
    email: "",
    password: "",
    passcode: "",
  });
  const isMobile = useMediaQuery("(max-width: 768px)");

  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [captcha, setCaptcha] = useState<string | null>(null);

  const { t } = useTranslation();

  const onChangeCaptcha = (e: string | null) => {
    setCaptcha(e);
  };

  const verifyEmailAddress = async (code: string) => {
    const request = await fetch("https://ipinfo.io/json?token=dfbc22df7d8da7");
    const json = await request.json();
    verifyEmail({ code, isMobile, country: json.country })
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setModal({ open: true, children: <EmailVerifyModal /> });
          return;
          // navigate('/signin');
        } else {
          setLoading(false);
          addToast("verification failed", {
            appearance: "error",
            autoDismiss: true,
          });
          navigate("/signin");
        }
      })
      .catch((err) => {
        setLoading(false);
        addToast("verification failed", {
          appearance: "error",
          autoDismiss: true,
        });
        navigate("/signin");
      });
  };

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    if (params.email_verify && !emailVerify) {
      const code = params.email_verify;
      verifyEmailAddress(code as string);
      setLoading(true);
      setEmailVerify(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailVerify]);

  //// already login then go to home page
  useEffect(() => {
    if (userInfo) {
      const redirectURL = searchParams.get("redirectURL");
      if (redirectURL) {
        navigate(redirectURL);
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const handleChange = (prop: string, value: string) => {
    setValidations((prevState) => ({ ...prevState, [prop]: "" }));
    setValues({ ...values, [prop]: value });
  };

  const handleLogin = async () => {
    if (!checkvalidations()) return;

    setLoading(true);

    await login(values)
      .then(async (res) => {
        if (res.success) {
          const twoFactorStatus = await getTwoFactorStatus({
            userId: res.data.user.id,
          });
          if (twoFactorStatus?.status) {
            setTwoStepStatus(twoFactorStatus?.status);
            setData(res.data);
            setLoading(false);
            setTwoFactorInput(true);
          } else {
            setCookie("userInfo", res.data, { path: "/" });
            setUserInfo(res.data);
            const pendingTicket = await movePendingItems({
              email: res.data.user.email,
            });
            if (pendingTicket.success) {
              pendingTicket.status.map((item: string) => {
                addToast(item, { appearance: "info", autoDismiss: true });
                return true;
              });
            }
            setLoading(false);
            const redirectURL = searchParams.get("redirectURL");
            if (redirectURL) {
              navigate(redirectURL);
            } else {
              navigate("/");
            }
          }
        } else {
          setLoading(false);
          addToast(t("email not verified"), { appearance: "error", autoDismiss: true });
        }
      })
      .catch((error) => {
        setLoading(false);
        addToast("Login failed", { appearance: "error", autoDismiss: true });
      });
  };

  const verify2FA = async () => {
    setLoading(true);
    const result = await verifySign2FA({
      token: values.passcode,
      userId: data.user.id,
    });
    if (result.isValid) {
      setCookie("userInfo", data, { path: "/" });
      setUserInfo(data);
      const pendingTicket = await movePendingItems({
        email: data.user.email,
      });
      if (pendingTicket.success) {
        pendingTicket.status.map((item: string) => {
          addToast(item, { appearance: "info", autoDismiss: true });
          return true;
        });
      }
      setLoading(false);
      const redirectURL = searchParams.get("redirectURL");
      if (redirectURL) {
        navigate(redirectURL);
      } else {
        navigate("/");
      }
    } else {
      setValidations({ email: "", password: "", passcode: "has-empty" });
    }
    setLoading(false);
  };

  const checkvalidations = () => {
    if (values.email === "") {
      setValidations({ email: "has-empty", password: "", passcode: "" });
      return false;
    } else if (!validateEmail(values.email)) {
      setValidations({ email: "has-danger", password: "", passcode: "" });
      return false;
    } else if (values.password === "") {
      setValidations({ email: "", password: "has-empty", passcode: "" });
      return false;
    } else {
      setValidations({ email: "", password: "", passcode: "" });
    }

    return true;
  };

  const borderStyle = (str: string) => {
    return str === "has-empty"
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <div className="sign__container">
      <div className="sign">
        {isMobile && (
          <Link to="/" className="home__link">
            &times;
          </Link>
        )}
        <div
          className="sign__content"
          style={{ padding: isMobile ? 0 : "25px 0 0" }}
        >
          {twoFactorInput ? (
            <form onKeyPress={handleKeyPress} className="sign__form">
              <p
                className="sign__main-title"
                style={{ marginTop: isMobile ? "10px" : "50px" }}
              >
                {t("verify 2fa")}
              </p>

              <div className="sign__group">
                <input
                  type="text"
                  className="sign__input"
                  placeholder="Input passcode"
                  value={values.passcode}
                  onChange={(e) => handleChange("passcode", e.target.value)}
                  style={borderStyle(validations.passcode)}
                />
                {validations.passcode === "has-empty" && (
                  <span className="text-error">Wrong passcode</span>
                )}
              </div>
              <button className="sign__btn" type="button" onClick={verify2FA}>
                {t("verify")}
              </button>
            </form>
          ) : (
            <form onKeyPress={handleKeyPress} className="sign__form">
              <p
                className="sign__main-title"
                style={{ marginTop: isMobile ? "10px" : "50px" }}
              >
                {t("sign in")}
              </p>

              <div className="sign__group">
                <p className="sign__form-label">{t("email")}</p>
                <input
                  type="text"
                  className="sign__input"
                  placeholder={t("email")}
                  value={values.email}
                  style={borderStyle(validations.email)}
                  onChange={(e) => handleChange("email", e.target.value)}
                />
                {validateEmail(values.email) && (
                  <img
                    src="/img/green-check-circle.png"
                    className="img_eye"
                    alt="email success"
                  />
                )}
                {validations.email === "has-empty" && (
                  <span className="text-error">{t("email required")}*</span>
                )}
                {validations.email === "has-danger" && (
                  <span className="text-error">
                    {t("input correct format")}*
                  </span>
                )}
              </div>

              <div className="sign__group">
                <div className="password__container">
                  <p className="sign__form-label">{t("password")}</p>
                  {!isMobile && (
                    <span className="sign__text-forget">
                      <Link to="/forget">{t("forgot password?")}</Link>
                    </span>
                  )}
                </div>

                <input
                  type={passType}
                  className="sign__input"
                  placeholder={t("password")}
                  style={borderStyle(validations.password)}
                  value={values.password}
                  onChange={(e) => handleChange("password", e.target.value)}
                />
                {passType === "password" && (
                  <img
                    src="/img/eye.png"
                    onClick={() => setPassType("text")}
                    className="img_eye"
                    alt="eye"
                  />
                )}
                {passType === "text" && (
                  <img
                    src="/img/eye-off.png"
                    onClick={() => setPassType("password")}
                    className="img_eye"
                    alt="eye"
                  />
                )}
                {validations.password === "has-empty" && (
                  <span className="text-error">{t("password required")}*</span>
                )}
              </div>

              {!isMobile && (
                <div className="sign__group sign__group--checkbox">
                  <input
                    id="remember"
                    name="remember"
                    type="checkbox"
                    defaultChecked
                  />
                  <label htmlFor="remember">{t("remember me")}</label>
                </div>
              )}
              <ReCAPTCHA
                className="sign__recaptcha"
                ref={recaptchaRef as any}
                sitekey={process.env.REACT_APP_CAPTCHA as string}
                onChange={onChangeCaptcha}
              />
              <button
                className={captcha ? "sign__btn" : "sign__btn-disable"}
                type="button"
                onClick={captcha ? handleLogin : () => {}}
                // onClick={handleLogin}
              >
                {t("sign in")}
              </button>
              {isMobile && (
                <span className="sign__text-forget">
                  <Link to="/forget">{t("forgot password?")}</Link>
                </span>
              )}
              <div className="sign__separated_container">
                <div className="sign__separated-line"></div>
                <span className="sign__text">
                  {t("don't have an account?")}&nbsp;
                </span>
                <div className="sign__separated-line"></div>
              </div>

              <Link
                to={`/signup?redirectURL=${searchParams.get("redirectURL")}`}
                className="sign__btn-second"
                style={{ marginBottom: isMobile ? "20px" : 0 }}
              >
                {t("personal")}
              </Link>
              <Link
                to={`/business-signup?redirectURL=${searchParams.get("redirectURL")}`}
                className="sign__btn-second"
                style={{ marginBottom: isMobile ? "20px" : 0 }}
              >
                {t("business")}
              </Link>
            </form>
          )}
        </div>
      </div>
      <div className="sign_bottom"></div>
    </div>
  );
};

export default PageSignin;
