import { useState, useEffect, useMemo, useRef } from "react";
import Checkbox from '@mui/material/Checkbox';
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useUserContext } from "../../context/UserContext";
import { useAppContext } from "../../context/AppContext";
import { getAllPermission, updatePermission } from "../../api/permission";
import { getEventCardInCollection } from "../../api/event";
import Pagination from "../pagination";
import styles from "./index.module.css";

const PermissionTable = () => {
  const { userInfo } = useUserContext();
  const { setLoading } = useAppContext();
  const [permissions, setPermissions] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [itemIndex, setItemIndex] = useState(0);
  const tableRef = useRef(null);
  const busUsers = useMemo(() => {
    return permissions.filter(
      (item) => item.allow && item.bus && item.permission_ticket !== "decline"
    );
  }, [permissions]);
  const getData = async () => {
    setLoading(true);
    const tickets_res = await getEventCardInCollection(
      process.env.REACT_APP_LARS_COLLECTION
    );
    setTickets(tickets_res.eventcards);
    const res = await getAllPermission({
      owner_email: userInfo.user.email,
    });
    sortPermissions(res);
    setLoading(false);
  };
  const handleUpdate = async (item) => {
    setLoading(true);
    const res = await updatePermission({
      id: item.id,
      owner_email: userInfo.user.email,
      allow: item.allow,
      email: item.email,
      name: item.name,
      eventcard_name: item.eventcard_name,
      eventcard_id: item.eventcard_id,
      permission_ticket: item.permission_ticket,
    });
    sortPermissions(res);
    setLoading(false);
  };
  const selectTicket = (id, ticket_id) => {
    const $permissions = [...permissions];
    for (let index = 0; index < $permissions.length; index++) {
      const element = $permissions[index];
      if (element.id === id) {
        $permissions[index].permission_ticket = ticket_id;
      }
    }
    sortPermissions($permissions);
  };
  const sortPermissions = (data) => {
    const sorted_data = data.sort((a, b) => a.name.localeCompare(b.name));
    console.log(sorted_data);
    setPermissions([...sorted_data]);
  };
  const leftClick = () => {
    if (itemIndex > 0) {
      let _itemIndex = itemIndex;
      _itemIndex--;
      setItemIndex(_itemIndex);
    }
  };

  const rightClick = () => {
    if ((itemIndex + 1) * 10 < permissions.length) {
      let _itemIndex = itemIndex;
      _itemIndex++;
      setItemIndex(_itemIndex);
    }
  };
  useEffect(() => {
    if (userInfo && userInfo.user.email === process.env.REACT_APP_PERMISSION) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);
  return (
    <div>
      <div className={styles.top_container}>
        <p className={styles.bus__users}>Bus Travel Users: {busUsers.length}</p>
        <DownloadTableExcel
          filename="All Guests"
          sheet="guests"
          currentTableRef={tableRef.current}
        >
          <button className={styles.btn_download}> Download </button>
        </DownloadTableExcel>
      </div>
      <div className={styles.container}>
        <table className={styles.permission_table}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Surname</th>
              <th>Phone</th>
              <th>Company Name</th>
              <th>Email</th>
              <th>Amount</th>
              <th>Bus</th>
              <th>Ticket</th>
              <th>Paid</th>
              <th>Permission</th>
            </tr>
          </thead>
          <tbody>
            {permissions.map(
              (item, index) =>
                index >= itemIndex * 10 &&
                index < (itemIndex + 1) * 10 && (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.surname}</td>
                    <td>{item.phone_number}</td>
                    <td>{item.company_name}</td>
                    <td>{item.email}</td>
                    <td>{item.ticket_amount}</td>
                    <td>{item.bus ? "Yes" : "No"}</td>
                    <td className={styles.ticket_field}>
                      <select
                        id="ticket"
                        name="ticket"
                        value={item.permission_ticket}
                        className={styles.select}
                        disabled={item.allow}
                        onChange={(e) => {
                          selectTicket(item.id, e.target.value);
                        }}
                      >
                        <option disabled hidden value="">
                          Select Ticket
                        </option>
                        <option value="decline">Decline</option>
                        {tickets.map(
                          (ticket, index) =>
                            ticket.id !==
                              process.env.REACT_APP_LARS_EVENT && (
                              <option value={ticket?.id} key={index}>
                                {ticket?.name}
                              </option>
                            )
                        )}
                      </select>
                      <img
                        src="/img/icons/arrow-down.svg"
                        alt="arrow down"
                        className={styles.bottom_arrow}
                      />
                    </td>
                    <td>{item.paid ? "Yes" : "No"}</td>
                    <td>
                      <Checkbox
                        checked={item.allow}
                        disabled={item.permission_ticket === "" || item.paid}
                        onChange={() => handleUpdate(item)}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        color="primary"
                        className={styles.check_box}
                      />
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
        <table ref={tableRef} style={{ display: "none" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Surname</th>
              <th>Phone</th>
              <th>Company Name</th>
              <th>Email</th>
              <th>Amount</th>
              <th>Bus</th>
              <th>Ticket</th>
              <th>Paid</th>
              <th>Permission</th>
            </tr>
          </thead>
          <tbody>
            {permissions.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.surname}</td>
                <td>{item.phone_number}</td>
                <td>{item.company_name}</td>
                <td>{item.email}</td>
                <td>{item.ticket_amount}</td>
                <td>{item.bus ? "Yes" : "No"}</td>
                <td className={styles.ticket_field}>
                  {(tickets.find(ticket => ticket.id === item.permission_ticket))?.name}
                </td>
                <td>{item.paid ? "Yes" : "No"}</td>
                <td>{item.allow && item.permission_ticket !== "decline" ? "Yes" : "No"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        items={permissions}
        setItemIndex={setItemIndex}
        pageIndex={0}
        itemIndex={itemIndex}
        leftClick={leftClick}
        rightClick={rightClick}
        count={10}
      />
    </div>
  );
};

export default PermissionTable;
