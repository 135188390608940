import api from "./api";

const addToken = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/token", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getUserToken = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/token/user_token", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export { addToken, getUserToken };
