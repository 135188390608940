import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";

const SignupSuccessModal = ({email = "your@email.com"}) => {
  const { setModal } = useAppContext();
  const { t } = useTranslation();
  return (
    <div style={style.container}>
      <div>
        <img alt="mailbox" src="/img/mailbox.svg" />
      </div>
      <p style={{ fontSize: 20, fontWeight: 700, marginTop: 10 }}>
        {t("Verify your email address")}
      </p>
      <p style={style.text}>
        {t("Before you can make purchases you will need to verify your email address.")}
      </p>
      <p style={style.text}>
        {t("We have sent a confirmation email to")} <span style={{ color: "#6A4DFD" }}>{email}</span>. 
        {t("Follow the link in the email to complete the signup process.")}
      </p>
      <div style={style.divide_line}></div>
      <p style={style.text}>
        {t("Haven’t received the confirmation email yet? Check your spam folder or")}
        <span style={{ color: "#6A4DFD" }}> {t("send a new email")}</span>
      </p>
      <span style={style.btn_close} onClick={() => setModal({ open: false })}>
        <svg
          style={{ marginTop: "-4px" }}
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            fill="#ffffff"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
        </svg>
      </span>
    </div>
  );
};

const style = {
  container: {
    maxWidth: "94%",
    textAlign: "center",
    background:
      "radial-gradient(50% 50% at 50% -10%, rgba(106, 77, 253, 0.4) 0%, rgba(106, 77, 253, 0) 100%)",
    backgroundColor: "#14142f",
    color: "white",
    padding: "50px 45px",
    borderRadius: "10px",
    border: "1px solid #534f77",
    margin: "auto",
  },
  btn_close: {
    width: "20px",
    height: "25px",
    position: "absolute",
    top: 25,
    right: 35,
    cursor: "pointer",
  },
  text: {
    maxWidth: "321px",
    margin: "15px auto",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 100,
  },
  divide_line: {
    background: "rgba(255, 255, 255, 0.33)",
    height: 0.5,
    width: "85%",
    margin: "28px auto 24px auto",
  }
};

export default SignupSuccessModal;
