import React, { useState, useMemo, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { isVideoFile } from "../../utils";
import styles from "./index.module.css";

const EventAvatar = ({ eventCard }) => {
  const carouselRef = useRef(null);
  const [active, setActive] = useState(0);
  const [showBtn, setShowBtn] = useState(false);
  const imageData = useMemo(() => {
    let data = [{ type: "", src: eventCard?.picture_large }];
    if (eventCard.multiImages) {
      const multiImages = JSON.parse(eventCard.multiImages);
      for (let index = 0; index < multiImages.length; index++) {
        const element = multiImages[index];
        data.push({
          type: "",
          src: element,
        });
      }
    }
    if (eventCard.multiVideo) {
      data.push({ type: "", src: eventCard.multiVideo });
    }
    if (eventCard.benefitImages) {
      const addons = JSON.parse(eventCard.addons);
      const benefitImages = JSON.parse(eventCard.benefitImages);
      for (let index = 0; index < benefitImages.length; index++) {
        const element = benefitImages[index];
        if (element !== "") {
          data.push({
            type: addons[index].name,
            src: element,
          });
        }
      }
    }
    return data;
  }, [eventCard]);

  // const isSafari = () => {
  //   const ua = navigator.userAgent.toLowerCase();
  //   return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
  // };

  const prevClick = () => {
    if (active !== 0) {
      carouselRef.current.previous();
    }
  };

  const nextClick = () => {
    carouselRef.current.next();
  };

  const handleAfterChange = (previousSlide, { currentSlide }) => {
    if (currentSlide >= 2) {
      setActive(currentSlide - 2);
    } else {
      setActive(currentSlide + imageData.length - 2);
    }
  };

  const setActiveItemManually = (index) => {
    if (carouselRef.current) {
      carouselRef.current.goToSlide(index + 2);
      setActive(index);
    }
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div className={styles.asset__item}>
      {imageData.length > 1 ? (
        <Carousel
          swipeable={true}
          draggable={true}
          responsive={responsive}
          showDots={false}
          ssr
          infinite
          ref={carouselRef}
          afterChange={handleAfterChange}
          removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
        >
          {imageData.map((item, index) =>
            isVideoFile(item.src) ? (
              <video
                autoPlay
                playsInline
                loop
                controls
                muted
                className={styles.event__image_item}
                onMouseEnter={() => setShowBtn(true)}
                onMouseLeave={() => setShowBtn(false)}
                id="video"
                draggable={false}
                key={index}
              >
                <source
                  src={`${process.env.REACT_APP_ASSET_BASE_URL}/${item.src}`}
                  type="video/mp4"
                />
                Your browser does not support thte video tag.
              </video>
            ) : (
              <div className={styles.event__image_contain} key={index}>
                <LazyLoadImage
                  src={`${process.env.REACT_APP_ASSET_BASE_URL}/${item.src}`}
                  alt="large image"
                  className={styles.event__image_item}
                  draggable={false}
                  onMouseEnter={() => setShowBtn(true)}
                  onMouseLeave={() => setShowBtn(false)}
                />
              </div>
            )
          )}
        </Carousel>
      ) : isVideoFile(imageData[0].src) ? (
        <video
          autoPlay
          playsInline
          loop
          controls
          muted
          className={styles.event__image_item}
          id="video"
          draggable={false}
        >
          <source
            src={`${process.env.REACT_APP_ASSET_BASE_URL}/${imageData[0].src}`}
            type="video/mp4"
          />
          Your browser does not support thte video tag.
        </video>
      ) : (
        <div className={styles.event__image_contain}>
          <LazyLoadImage
            src={`${process.env.REACT_APP_ASSET_BASE_URL}/${imageData[0].src}`}
            alt="large image"
            className={styles.event__image_item}
            draggable={false}
          />
        </div>
      )}
      {imageData?.[active].type !== "" && (
        <div className={styles.benefitImg}>
          <img
            src="/img/addon.png"
            className={styles.benefitIcon}
            alt="benefit"
          />
          <p className={styles.benefitText}>{imageData?.[active].type}</p>
        </div>
      )}
      {imageData.length > 1 && (
        <div className={styles.pageNumber}>
          <p className={styles.pageText}>
            {active + 1 + "/" + imageData.length}{" "}
          </p>
        </div>
      )}
      {imageData.length > 1 && (
        <button
          className={styles.leftBtn}
          style={{ display: showBtn ? "flex" : "none" }}
          onClick={prevClick}
          onMouseEnter={() => setShowBtn(true)}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 6L9 12L15 18"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
      {imageData.length > 1 && (
        <button
          className={styles.rightBtn}
          style={{ display: showBtn ? "flex" : "none" }}
          onClick={nextClick}
          onMouseEnter={() => setShowBtn(true)}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      )}
      {imageData.length > 1 && (
        <div className={styles.previewImages}>
          {imageData.map((item, index) => (
            <div style={{ position: "relative" }} key={index}>
              {isVideoFile(item?.src) ? (
                <video
                  key={index}
                  autoPlay
                  playsInline
                  loop
                  muted
                  className={styles.smallPreivew}
                  onClick={() => setActiveItemManually(index)}
                  style={{ border: active === index ? "2px solid white" : "" }}
                  id="video"
                >
                  <source
                    src={`${process.env.REACT_APP_ASSET_BASE_URL}/${item.src}`}
                    type="video/mp4"
                  />
                  "Your browser does not support thte video tag."
                </video>
              ) : (
                <img
                  key={index}
                  onClick={() => setActiveItemManually(index)}
                  className={styles.smallPreivew}
                  style={{ border: active === index ? "2px solid white" : "" }}
                  src={`${process.env.REACT_APP_ASSET_BASE_URL}/${item.src}`}
                  alt="multi"
                />
              )}
              {item.type !== "" && (
                <img
                  src="/img/addon.png"
                  className={styles.smallIcon}
                  alt="benefit"
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EventAvatar;
