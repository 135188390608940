import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { findQRBenefit } from "../../../api/ticket";
import { useAppContext } from "../../../context/AppContext";
import styles from "./index.module.css";

const CheckBenefit = () => {
  const { id } = useParams();
  const { key } = useParams();
  const { addToast, loading, setLoading } = useAppContext();
  const [isTicket, setIsTicket] = useState(false);
  const [pass, setPass] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [ticket, setTicket] = useState<any>(null);
  const { t } = useTranslation();
  const data = { id, key };

  const checkValidation = async () => {
    await findQRBenefit(data)
      .then((res) => {
        if (res !== "failed") {
          console.log(res);
          setTicket(res);
          setIsTicket(true);
        } else {
          setIsTicket(false);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const checkPassword = () => {
    if (pass === "vavire") {
      setIsAdmin(true);
      localStorage.setItem("IsBenefit", Date.now().toString());
      setLoading(true);
      checkValidation().catch(console.error);
    } else {
      setIsAdmin(false);
      addToast("Wrong password", { appearance: "error", autoDismiss: true });
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const getOwnerAddress = async () => {
    if (localStorage.getItem("IsBenefit")) {
      const duration = moment.duration(
        moment(Date.now()).diff(
          new Date(Number(localStorage.getItem("IsBenefit")!!))
        )
      );
      const hours = duration.asHours();
      if (hours <= 12) {
        setLoading(true);
        await findQRBenefit(data)
          .then((res) => {
            if (res !== "failed") {
              setTicket(res);
              setIsTicket(true);
            } else {
              setIsTicket(false);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
        setIsAdmin(true);
      }
    }
  };

  useEffect(() => {
    getOwnerAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, []);
  return (
    <div className={styles.full__container}>
      {!isAdmin && (
        <div
          className="sign__content"
          style={{ padding: isMobile ? 0 : "25px 0 0" }}
        >
          <form className="sign__form" onKeyPress={handleKeyPress}>
            <p className="sign__main-title" style={{ marginTop: 10 }}>
              {t("password")}
            </p>

            <div className="sign__group">
              <input
                type="password"
                autoFocus
                className="sign__input"
                placeholder={t("password")}
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              />
            </div>
            <button className="sign__btn" type="button" onClick={checkPassword}>
              {t("check")}
            </button>
          </form>
        </div>
      )}

      {isAdmin && !loading && (
        <>
          {isTicket ? (
            <div className={styles.content}>
              <div className={styles.top__part}>
                <p className={styles.name__text}>{ticket.event_name}</p>
                <p className={styles.content__text}>{ticket.content}</p>
                <p className={styles.number__text}>{ticket.serial_text}</p>
              </div>
              <div className={styles.body__part}>
                <img src="/img/approve.png" alt="approve" />
                <p className={styles.approve__text}>Approved</p>
                <p className={styles.number__text}>This QR code is valid.</p>
              </div>
            </div>
          ) : (
            <div className={styles.content}>
              <div className={styles.fail__part}>
                <img src="/img/fail.png" alt="fail" />
                <p className={styles.approve__text}>Invalid</p>
                <p className={styles.number__text}>
                  This QR code is invalid. The period for use has expired.
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CheckBenefit;
