import api from "./api";

const getAllQRtickets = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/btickets")
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const findQRticket = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/btickets/findOne", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const findQRBenefit = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/benefit/check", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const mintTicket = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/btickets/mint", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const sendDigitalItem = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/btickets/send_item", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const sendCoin = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/btickets/send_coin", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const movePendingItems = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/btickets/move_pending_item", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const buyTicketBKSUSDT = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/btickets/buy_ticket_bks", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const countryName = (code) => {
  let regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  return regionNames.of(code);
};

export {
  getAllQRtickets,
  findQRticket,
  countryName,
  mintTicket,
  sendDigitalItem,
  sendCoin,
  movePendingItems,
  buyTicketBKSUSDT,
  findQRBenefit,
};
