import { create } from "ipfs-http-client";

const projectId = "2EC4M8qwYW8D9Ymo0vVPVybMUOy";
const projectSecret = "16368dca14a5bb99a1619d6e997b84c1";
const projectIdAndSecret = `${projectId}:${projectSecret}`;
const client = create({
  host: "ipfs.infura.io",
  port: 5001,
  protocol: "https",
  headers: {
    authorization: `Basic ${Buffer.from(projectIdAndSecret).toString(
      "base64"
    )}`,
  },
});

export default client;
