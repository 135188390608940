const defaultTokens = [
  {
    token_address: "bnb",
    decimals: 8,
    symbol: "BNB",
    balance: 0,
    logo:
      `${process.env.REACT_APP_SITE_URL}/img/wallet/bnb.svg`,
  },
  {
    token_address: "0x55d398326f99059fF775485246999027B3197955",
    decimals: 18,
    symbol: "USDT",
    balance: 0,
    logo:
      "https://r.poocoin.app/smartchain/assets/0x55d398326f99059fF775485246999027B3197955/logo.png",
  }
];

export { defaultTokens };
