import { useState, useEffect } from "react";
import styles from "./index.module.css";

const Pagination = ({
  items,
  setItemIndex,
  pageIndex,
  itemIndex,
  leftClick,
  rightClick,
  count,
}) => {
  const [pageItems, setPageItems] = useState([]);
  useEffect(() => {
    let _pageItems = [];
    for (let index = 0; index < Math.ceil(items.length / count); index++) {
      _pageItems.push(index);
    }
    setPageItems(_pageItems);
  }, [items, count]);

  return (
    <div>
      {items.length > 0 && (
        <div className={styles.paginator}>
          <span className={styles.paginator__pages}>
            {`Showing ${
              items.length > count * (itemIndex + 1)
                ? count
                : items.length - count * itemIndex
            } out of ${items.length} results`}
          </span>
          <ul className={styles.paginator__list}>
            <li>
              <button onClick={leftClick}>
                <img
                  src="/img/arrow-left.png"
                  className={styles.arrow__btn}
                  alt="left"
                />

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className={styles.arrow__svg}
                >
                  <path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z" />
                </svg>
              </button>
            </li>

            {pageItems.map(
              (item, index) =>
                index < itemIndex + 2 &&
                index > itemIndex - 2 && (
                  <li
                    key={index}
                    className={itemIndex === index ? styles.active__button : ""}
                  >
                    <button
                      onClick={() => setItemIndex(index)}
                      className={styles.number__btn}
                    >
                      {pageIndex + 1 + index}
                    </button>
                  </li>
                )
            )}
            <li>
              <button onClick={rightClick}>
                <img
                  src="/img/arrow-right.png"
                  alt="right"
                  className={styles.arrow__btn}
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className={styles.arrow__svg}
                >
                  <path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z" />
                </svg>
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Pagination;
