/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../../context/UserContext";
import { useAppContext } from "../../../context/AppContext";
import { createPromoCode } from "../../../api/promo";
import styles from "./index.module.css";

const PagePromoCodeGenerate = () => {
  const { userInfo } = useUserContext();
  const { setLoading } = useAppContext();
  const [code, setCode] = useState("");
  const { promoId } = useParams();
  const { eventcardId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const generate = async () => {
    setLoading(true);
    const data = {
      promoId,
      eventcardId,
      userId: userInfo.user.id,
    };
    const res = await createPromoCode(data);
    setLoading(false);
    if (res.success) {
      setCode(res.code.code);
    } else {
      setCode("Sorry, can't generate your code.")
    }
  };

  useEffect(() => {
    if (!userInfo) {
      const url = `/promo_code_generate/${eventcardId}/${promoId}`;
      navigate(`/signin?redirectURL=${url}`);
    } else {
      generate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>
        {
          code === "" ? t("generating your promo code") : t("here is your promo code")
        }
        </h2>
      <p className={styles.code_txt}>{code}</p>
      <div className="bottom_block"></div>
    </div>
  );
};

export default PagePromoCodeGenerate;
