import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";

const PeriodFilter = ({
  serviceFilter,
  servicePeriod,
  setServiceFilter,
  setServicePeriod,
}) => {
  const isMobile = useMediaQuery("(max-width: 450px)");
  const { t } = useTranslation();
  return (
    <div className="report__top-buttons">
      <button
        className={
          serviceFilter === "filter"
            ? "report__top-active--btn"
            : "report__top-inactive--btn"
        }
        onClick={() =>
          serviceFilter === ""
            ? setServiceFilter("filter")
            : setServiceFilter("")
        }
      >
        <img src="/img/icons/filter.svg" alt="filter" />
        <p className="explorer__btn-text">{t("period")}</p>
        {!isMobile && <img src="/img/icons/dropdown.svg" alt="dropdown" />}
      </button>
      {serviceFilter === "filter" && (
        <div
          className={
            isMobile ? "report__top-btn-opts-mb" : "report__top-btn-opts"
          }
        >
          <div className="explorer__top-checkbox">
            <input
              id="all"
              name="all"
              type="checkbox"
              checked={servicePeriod === "all"}
              onChange={() => {
                setServicePeriod("all");
                setServiceFilter("");
              }}
            />
            <label htmlFor="all">{t("all")}</label>
          </div>
          <div className="explorer__top-checkbox">
            <input
              id="event"
              name="event"
              type="checkbox"
              checked={servicePeriod === "month"}
              onChange={() => {
                setServicePeriod("month");
                setServiceFilter("");
              }}
            />
            <label htmlFor="event">{t("month")}</label>
          </div>
          <div className="explorer__top-checkbox">
            <input
              id="digital"
              name="digital"
              type="checkbox"
              checked={servicePeriod === "week"}
              onChange={() => {
                setServicePeriod("week");
                setServiceFilter("");
              }}
            />
            <label htmlFor="digital">{t("week")}</label>
          </div>
          <div className="explorer__top-checkbox">
            <input
              id="service"
              name="service"
              type="checkbox"
              checked={servicePeriod === "today"}
              onChange={() => {
                setServicePeriod("today");
                setServiceFilter("");
              }}
            />
            <label htmlFor="service">{t("today")}</label>
          </div>
        </div>
      )}
    </div>
  );
};

export default PeriodFilter;
