import React, { useState } from "react";
import { useUserContext } from "../../../context/UserContext";
import { useAppContext } from "../../../context/AppContext";
import { isURL } from "../../../utils";
import { createOffer } from "../../../api/offer";
import styles from "./index.module.css";

const OfferModal = ({ nfts, eventCard }) => {
  const { userInfo } = useUserContext();
  const { setModal, setLoading } = useAppContext();
  const [values, setValues] = useState({
    url: "",
    description: "",
    date: "",
  });
  const [validation, setValidation] = useState("");
  const handleChange = (prop, value) => {
    setValidation("");
    setValues({ ...values, [prop]: value });
  };
  const checkValidation = () => {
    if (values.url === "" || !isURL(values.url)) {
      setValidation("url");
      return false;
    } else if (values.description === "") {
      setValidation("description");
      return false;
    } else if (values.date === "") {
      setValidation("date");
      return false;
    } else {
      return true;
    }
  };
  const create = async () => {
    if (!checkValidation()) {
      return;
    }
    const data = {
      offer: {
        url: values.url,
        description: values.description,
        date: values.date,
        creator: userInfo.user.id,
        eventId: eventCard.id,
        eventName: eventCard.name,
      },
      nfts: nfts,
    };
    setLoading(true);
    await createOffer({ data });
    setModal({ open: false });
    setLoading(false);
  };
  return (
    <div className={styles.container}>
      <div
        className={styles.btn_close}
        onClick={() => setModal({ open: false })}
      >
        <svg
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            fill="#ffffff"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          ></path>
        </svg>
      </div>
      <p>Create Offers</p>
      <div className={styles.wrapper}>
        <div className={styles.input_wrapper_full}>
          Website URL
          <input
            value={values.url}
            onChange={(e) => handleChange("url", e.target.value)}
            placeholder="https://www.example.com"
            style={{ borderColor: validation === "url" && "red" }}
          />
          {validation === "url" && (
            <p className={styles.warning_text}>URL is not valid</p>
          )}
        </div>
        <div className={styles.input_wrapper_full}>
          Description
          <div>
            <textarea
              id="w3review"
              name="w3review"
              rows="4"
              cols="50"
              value={values.description}
              onChange={(e) => handleChange("description", e.target.value)}
              style={{ borderColor: validation === "description" && "red" }}
            ></textarea>
            {validation === "description" && (
              <p className={styles.warning_text}>Description can't be empty</p>
            )}
          </div>
        </div>
        <div className={styles.input_wrapper_full}>
          Limited Date
          <input
            type="date"
            value={values.date}
            onChange={(e) => handleChange("date", e.target.value)}
            style={{ borderColor: validation === "date" && "red" }}
          />
          {validation === "date" && (
            <p className={styles.warning_text}>Date is required</p>
          )}
        </div>
      </div>
      <button className={styles.add__btn} onClick={create} type="button">
        Create
      </button>
    </div>
  );
};

export default OfferModal;
