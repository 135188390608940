import { Button, Fade, Paper, Popper, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function DeletePopper(props) {
  const { onClickDelete } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.target);
  };

  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const id = open ? "transitions-popper" : undefined;

  return (
    <div
      onMouseLeave={() => {
        setAnchorEl(null);
      }}
    >
      <Button
        aria-describedby={id}
        className="card__delete_btn"
        sx={{
          color: "#fff",
          fontSize: "12px",
          padding: "5px 20px",
          backgroundColor: "#702FA0",
          borderRadius: "5px",
          border: "solid 1px #f0f0f0",
          textTransform: "uppercase",
          whiteSpace: "nowrap",
          "&:hover": {
            backgroundColor: "#702FA0",
          },
          "&:focus": {
            outline: "none",
          },
        }}
        onClick={handleClick}
        large="false"
        small="true"
      >
        {t("delete")}
      </Button>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="top-end"
        transition
        disablePortal={false}
        style={{
          zIndex: 1000,
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              style={{
                padding: 12,
                width: 250,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography style={{ fontSize: 14, marginBottom: 12 }}>
                {t("do you really want to delete?")}
              </Typography>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  onClick={() => {
                    setAnchorEl(null);
                  }}
                  sx={{
                    color: "#000"
                  }}
                >
                  {t("cancel")}
                </Button>
                <Button
                  small="true"
                  large="false"
                  onClick={() => {
                    onClickDelete();
                    setAnchorEl(null);
                  }}
                  sx={{
                    color: "#ffffff",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: 600,
                    padding: "0 30px",
                    backgroundColor: "#ec2425",
                    borderRadius: "6px",
                    textTransform: "inherit",
                    whiteSpace: "nowrap",
                    "&:hover": {
                      backgroundColor: "#d92424",
                    },
                    "&:focus": {
                      outline: "none",
                    },
                    "&.Mui-disabled": {
                      color: "#f0f0f0",
                      backgroundColor: "#d92424",
                      opacity: 0.6,
                    },
                  }}
                >
                  {t("delete")}
                </Button>
              </div>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
