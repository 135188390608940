import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";
import styles from "./index.module.css";

const Btn = ({ title, onClick }) => {
  return (
    <div className={styles.button} onClick={onClick}>
      <div className={styles.button_title}>{title}</div>
    </div>
  );
};

const CookieConsent = () => {
  const { t } = useTranslation();
  const { isCookiePopup, setCookiePopup } = useAppContext();

  const onAllow = () => {
    setCookiePopup(false);
    localStorage.setItem("consent", "accept");
  };
  const onDeny = () => {
    setCookiePopup(false);
  };

  return (
    <>
      {isCookiePopup && (
        <div className={styles.wrapper}>
          <div className={styles.title}>{t("cookie consent")}</div>
          <div className={styles.content}>
            {t("cookie description")}
          </div>
          <div className={styles.buttonWrapper}>
            <Btn title="Deny" onClick={onDeny} />
            <Btn title="Accept" onClick={onAllow} />
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
