/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from "react";
import { useMediaQuery } from "usehooks-ts";
import { initMercadoPago, CardPayment } from "@mercadopago/sdk-react";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";

import useConvertedFiat from "../../../hooks/useConvertedFiat";
import useFiatSymbol from "../../../hooks/useFiatSymbol";

import { getEventPrice } from "../../../utils";
import { convertHtmlToString } from "../../../utils/convertHtmlToString";
import { mercadoPayment } from "../../../api/credit";
import PaymentSuccessModal from "../payment_success_modal";
import PaymentFailedModal from "../payment_failed_modal";
import client from "../../../utils/ipfs_client";
import { CURRENCY } from "../../../utils/currency";

import styles from "./index.module.css";

const MercadoModal = ({ eventCard, amount }) => {
  console.log("mercado element");
  initMercadoPago(process.env.REACT_APP_MERCADO_PUBLIC_KEY);
  const {
    setLoading,
    country,
    discount,
    selectedSeats,
    setDiscount,
    setModal,
    media,
  } = useAppContext();

  const isMobile = useMediaQuery("(max-width: 576px)");
  const { userInfo } = useUserContext();
  const [wallet] = useState(userInfo.user.wallet_address);
  const [status, setStatus] = useState(true);
  const symbol = useFiatSymbol("CL");
  const convertedPrice = useConvertedFiat(
    getEventPrice(eventCard),
    eventCard.currency,
    "CL"
  );

  const totalCurrencyPrice =
    (
      convertedPrice *
      (1 + (eventCard.tax_include ? 0 : eventCard.tax / 100)) *
      amount
    ).toFixed(2) + symbol;

  const ticketCurrencyPrice =
    (
      convertedPrice *
      (1 + (eventCard.tax_include ? 0 : eventCard.tax / 100))
    ).toFixed(2) + symbol;
  const emailData = {
    mobile: isMobile,
    email: userInfo.user.email,
    ticket_number: Number(amount),
    user_name: userInfo.user.name,
    totalPrice: totalCurrencyPrice,
    ticketPrice: ticketCurrencyPrice,
    collection_name: eventCard.collection.name,
    scan: eventCard.scan,
    ticket_type: eventCard.collection.category,
    item: eventCard,
    addons: JSON.parse(eventCard.addons),
    start_now:
      eventCard.collection.name !== "Tulum Crypto Fest 2023" ? true : false,
    date: new Date(localStorage.getItem("service_date") || eventCard.date)
      .toString()
      .substring(0, 21),
    end_date: new Date(
      localStorage.getItem("service_date") || eventCard.end_date
    )
      .toString()
      .substring(0, 21),
    country,
  };
  const ticketData = {
    wallet_address: wallet,
    blockchain: "Binance Smart Chain",
    buyer: userInfo.user.id,
    eventcard: eventCard.id,
    collection: eventCard.collection.id,
    price: getEventPrice(eventCard) * amount,
    pay_order_id: "mercado payment",
    count: amount,
    service_date: localStorage.getItem("service_date")
      ? new Date(localStorage.getItem("service_date"))
          .toString()
          .substring(0, 16)
      : null,
    seats: JSON.stringify(selectedSeats),
    discount: discount * 100,
    media,
  };

  const tokenObject = {
    name: eventCard?.name,
    description:
      eventCard.category !== "Category2"
        ? convertHtmlToString(eventCard.venue_description)
        : convertHtmlToString(eventCard.description),
    image: eventCard.picture_ipfs,
    attributes: [
      {
        trait_type: "Price",
        value: getEventPrice(eventCard).toFixed(2),
      },
      { trait_type: "Currency", value: symbol },
      { trait_type: "Location", value: eventCard.location },
      { trait_type: "Date", value: eventCard.date },
      { trait_type: "Collection", value: eventCard.collection.name },
      {
        trait_type: "Addons",
        value: JSON.parse(eventCard.addons),
      },
    ],
  };

  const dataObject = {
    event: {
      id: eventCard?.id || "",
      name: eventCard?.name || "",
      price:
        parseInt(convertedPrice) * amount,
    },
    currency: CURRENCY["CL"],
    emailData,
    ticketData,
    tokenObject,
    userWallet: userInfo.user.wallet_address,
    contractAddress: eventCard.NFT_address,
    picture_ipfs: eventCard.picture_ipfs,
  };

  const uploadIPFS = async () => {
    const added = await client.add(JSON.stringify(tokenObject));
    const ipfs_url = `https://bkstage.infura-ipfs.io/ipfs/${added.path}`;
    return ipfs_url;
  };

  const sendPayment = async (param) => {
    setLoading(true);
    setStatus(false);
    const NFT_IPFS = await uploadIPFS();
    console.log(NFT_IPFS);
    const nftData = {
      contract: eventCard.NFT_address,
      IPFS_URL: NFT_IPFS,
      account: userInfo.user.wallet_address,
      picture_ipfs: eventCard.picture_ipfs,
    };
    const data = {
      param: {
        ...param,
        description: eventCard.name,
      },
      dataObject,
      nftData,
    };
    console.log(data);
    const response = await mercadoPayment(data);
    console.log(response);
    if (response.success) {
      setModal({
        open: true,
        children: <PaymentSuccessModal />,
      });
    } else {
      setModal({
        open: true,
        children: <PaymentFailedModal />,
      });
    }
    setDiscount(0);
    setLoading(false);
  };

  useEffect(() => {
    localStorage.setItem("ccObject", JSON.stringify(dataObject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      {status && (
        <CardPayment
          initialization={{
            amount: eventCard.tax_include
              ? parseInt(convertedPrice * amount)
              : parseInt(
                  (convertedPrice + (convertedPrice * eventCard.tax) / 100) *
                    amount
                ),
          }}
          onSubmit={async (param) => sendPayment(param)}
        />
      )}
    </div>
  );
};

export default MercadoModal;
