import { useState, useMemo } from "react";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "usehooks-ts";
import DigitalItem from "../../../components/digital_item";
import TokenItem from "../../../components/token_item";
import { useUserContext } from "../../../context/UserContext";
import { useAppContext } from "../../../context/AppContext";
import { ITicket } from "../../../utils/interface";
import { sendOnlyMail, downloadQRcode } from "../../../api/message";
import styles from "./index.module.css";

const SendReceive = () => {
  const {
    addToast,
    setLoading,
    digitalItems,
    tokens,
    bnbPrice,
    balance,
  } = useAppContext();
  const [tab, setTab] = useState("currencies");
  const { userInfo } = useUserContext();
  const [isWalletCopied, setIsWalletCopied] = useState(false);
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width: 992px)");
  const wallet = useMemo(() => {
    return userInfo?.user?.wallet_address;
  }, [userInfo]);

  function copyTextToClipboard(text: string) {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(text).then(
      function() {
        console.log("Async: Copying to clipboard was successful!");
      },
      function(err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  const handleWalletCopied = () => {
    if (wallet === "") return;
    copyTextToClipboard(wallet);
    setIsWalletCopied(true);
    setTimeout(() => {
      setIsWalletCopied(false);
    }, 2000);
  };

  const ticketBuyTime = (ticket: ITicket) => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    const buyDate = new Date(ticket.createdAt);
    buyDate.setHours(0);
    buyDate.setMinutes(0);
    buyDate.setSeconds(0);
    if (today.toDateString() === buyDate.toDateString()) return "Today";
    const period = Math.floor(
      (today.getTime() - buyDate.getTime()) / 1000 / 3600 / 24
    );
    if (period === 1) return " A day ago ";
    else if (period < 7) return `${period} days ago`;
    else if (period === 7) return "A week ago";
    else if (period < 29) return `${Math.ceil(period / 7)} weeks ago`;
    else if (period < 32) return "A month ago";
    else return buyDate.toDateString();
  };

  const sendMail = async (
    ticket: ITicket,
    totalCurrencyPrice: string,
    ticketCurrencyPrice: string
  ) => {
    setLoading(true);
    let url = new URL(ticket.tokenURL);
    let params = new URLSearchParams(url.search);
    let token_id = params.get("a");
    const emailData = {
      mobile: isMobile,
      email: userInfo.user.email,
      user_name: userInfo.user.name,
      ticket_number: ticket.count,
      totalPrice: totalCurrencyPrice,
      ticketPrice: ticketCurrencyPrice,
      ticket_type: ticket.collection.category,
      collection_name: ticket.collection.name,
      scan: ticket.eventcard.scan,
      item: ticket.eventcard,
      addons: JSON.parse(ticket.eventcard.addons),
      start_now:
        ticket.collection.name !== "Tulum Crypto Fest 2023" ? true : false,
      date: ticket.service_date
        ? new Date(ticket.service_date).toString().substring(0, 21)
        : new Date(ticket.eventcard.date).toString().substring(0, 21),
      end_date: new Date(ticket.eventcard.end_date).toString().substring(0, 21),
      seats: ticket.seats !== "" ? JSON.parse(ticket.seats) : null,
      wallet,
      nft_address: ticket.nft_address,
      token_id,
    };
    await sendOnlyMail({ emailData, ticketId: ticket.id })
      .then((res) => {
        addToast("Sent Email", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        addToast("failed", { appearance: "error", autoDismiss: true });
      });
    setLoading(false);
  };

  const downloadQR = async (
    ticket: ITicket,
    totalCurrencyPrice: string,
    ticketCurrencyPrice: string
  ) => {
    let url = new URL(ticket.tokenURL);
    let params = new URLSearchParams(url.search);
    let token_id = params.get("a");
    const emailData = {
      mobile: isMobile,
      email: userInfo.user.email,
      user_name: userInfo.user.name,
      ticket_number: ticket.count,
      totalPrice: totalCurrencyPrice,
      ticketPrice: ticketCurrencyPrice,
      ticket_type: ticket.collection.category,
      collection_name: ticket.collection.name,
      scan: ticket.eventcard.scan,
      item: ticket.eventcard,
      addons: JSON.parse(ticket.eventcard.addons),
      start_now:
        ticket.collection.name !== "Tulum Crypto Fest 2023" ? true : false,
      date: ticket.service_date
        ? new Date(ticket.service_date).toString().substring(0, 21)
        : new Date(ticket.eventcard.date).toString().substring(0, 21),
      end_date: new Date(ticket.eventcard.end_date).toString().substring(0, 21),
      seats: ticket.seats !== "" ? JSON.parse(ticket.seats) : null,
      wallet,
      nft_address: ticket.nft_address,
      token_id,
    };
    setLoading(true);
    await downloadQRcode({ emailData, ticketId: ticket.id })
      .then((res) => {
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          downloadFromUrl(
            process.env.REACT_APP_API_BASE_URL +
              "/api/upload/get_file?path=assets/uploads/pdfs/" +
              element,
            element
          );
        }
      })
      .catch((err) => {
        addToast("failed", { appearance: "error", autoDismiss: true });
      });
    setLoading(false);
  };

  const downloadFromUrl = (url: string, fileName: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        let url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  };

  return (
    <div className={styles.wallet_content}>
      <div className={styles.wallet__title_part} style={{ marginBottom: 30 }}>
        <img src="/img/wallet/sendReceive.svg" alt="currencies" />
        <div>
          <p className={styles.wallet__content_title}>{t("send & receive")}</p>
        </div>
      </div>
      <div className={styles.wallet_info}>
        <div className={styles.bnb_balance}>
          <p className={styles.available__bnb_txt}>{t("available")} BNB {t("balance")}</p>
          <p className={styles.available__bnb_amount}>{balance.toFixed(9)}</p>
          <p className={styles.bnb__price_txt}>
            ${(bnbPrice * balance).toFixed(2)} USD
          </p>
        </div>
        <div className={styles.wallet_address}>
          <div style={{ position: "relative" }}>
            <p className={styles.available__bnb_txt}>Wallet Address</p>
            {isWalletCopied && (
              <p className="copied_txt" style={{ top: 10 }}>
                {t("copied")}
              </p>
            )}
            <div className={styles.text_wallet}>
              <p className={styles.address_value}>
                {wallet?.substring(0, 15) + "..."}
              </p>
              <img
                src="/img/icons/copy.svg"
                alt="copy"
                onClick={handleWalletCopied}
                style={{ width: 20, cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <p className={styles.available__bnb_txt}>Wallet QR Code</p>
            <div className={styles.qrcode_container}>
              <QRCode size={90} value={wallet || ""} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.assets_tab}>
        <p
          className={tab === "currencies" ? styles.active_asset : styles.inactive_asset}
          onClick={() => setTab("currencies")}
        >
          {t("currencies")}
        </p>
        <p
          className={
            tab === "digital items" ? styles.active_asset : styles.inactive_asset
          }
          onClick={() => setTab("digital items")}
        >
          {t("tickets & items")}
        </p>
      </div>
      <div>
        {tab === "currencies" ? (
          <>
            {tokens.map((token: any, index: number) => (
              <TokenItem
                imgSrc={token.logo}
                coinName={token.symbol}
                balance={token.balance}
                price={`$${(token.price * token.balance).toFixed(2)} USD`}
                key={index}
                token_address={token.token_address}
                token_price = {token.price}
                decimals={token.decimals}
              />
            ))}
          </>
        ) : (
          <>
            {digitalItems.map((ticket: ITicket, index: number) => (
              <DigitalItem
                ticket={ticket}
                key={index}
                ticketBuyTime={ticketBuyTime}
                sendMail={sendMail}
                downloadQRcode={downloadQR}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default SendReceive;
