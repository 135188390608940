import { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../context/AppContext";
import { convertHtmlToString } from "../../../utils/convertHtmlToString";
import styles from "./index.module.css";
import { getEventPrice } from "../../../utils";

const DetailModal = ({ eventCard, tokenURL }) => {
  console.log(eventCard);
  const { setModal } = useAppContext();
  const [isVideo, setIsVideo] = useState("");
  const { t } = useTranslation();

  const checkLink = async () => {
    const config = {
      method: "head",
      url: eventCard.picture_ipfs,
      headers: {},
    };

    axios(config)
      .then(function(response) {
        console.log(response.headers["content-type"]);
        if (response.headers["content-type"].includes("video")) {
          setIsVideo("video");
        } else {
          setIsVideo("image");
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const symbol = {
    TRY: "₺",
    CLP: "$",
    DKK: "kr.",
    USD: "$",
    INR: "₹",
    GBP: "£",
    EUR: "€",
  };

  useEffect(() => {
    checkLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div
          className={styles.btn_close}
          onClick={() => setModal({ open: false })}
        >
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill="#ffffff"
              d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            ></path>
          </svg>
        </div>
      </div>
      <div>
        {isVideo === "image" ? (
          <img
            className={styles.nft_avatar}
            alt="NFT"
            src={eventCard.picture_ipfs}
          />
        ) : isVideo === "video" ? (
          <video
            autoPlay
            controls
            playsInline
            loop
            muted
            className={styles.nft_avatar}
          >
            <source src={eventCard.picture_ipfs} type="video/mp4" />
          </video>
        ) : (
          <div className={styles.nft_avatar}></div>
        )}
      </div>
      <p className={styles.title}>{eventCard.name}</p>
      <p className={styles.description}>
        {convertHtmlToString(eventCard.venue_description) ||
          convertHtmlToString(eventCard.description)}
      </p>
      <a href={tokenURL} className={styles.tokenURL}>Blockchain URL product</a>
      <p className={styles.attribute_title}>{t("attributes")}</p>
      <div className={styles.attribute_contain}>
        <p className={styles.attribute}>{t("price")}</p>
        <p className={styles.value}>
          {symbol[eventCard.currency]} {getEventPrice(eventCard)}
        </p>
      </div>
      <div className={styles.attribute_contain}>
        <p className={styles.attribute}>{t("location")}</p>
        <p className={styles.value}>{eventCard.location}</p>
      </div>
      <div className={styles.attribute_contain}>
        <p className={styles.attribute}>{t("date")}</p>
        <p className={styles.value}>{eventCard.date.substring(0, 10)}</p>
      </div>
      <div className={styles.attribute_contain}>
        <p className={styles.attribute}>{t("creator")}</p>
        <p className={styles.value}>{eventCard.creator.name}</p>
      </div>
      {JSON.parse(eventCard.addons).length !== 0 && (
        <p className={styles.attribute_title}>{t("benefits")}</p>
      )}
      {JSON.parse(eventCard.addons).map((item, index) => (
        <div className={styles.attribute_contain} key={index}>
          <p className={styles.attribute}>{item.name}</p>
          <p className={styles.value}>{item.description}</p>
        </div>
      ))}
    </div>
  );
};

export default DetailModal;
