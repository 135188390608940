import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";

const SelfieBoard = ({ uploadSrc }) => {
  const { setModal } = useAppContext();
  const { t } = useTranslation();
  const domEl = useRef(null);
  const [imgSrc, setImgSrc] = useState(uploadSrc);
  const [background, setBackground] = useState("/img/selfie/6.png");
  const selfieBack = [
    "/img/selfie/7.png",
    "/img/selfie/8.png",
    "/img/selfie/9.png",
    "/img/selfie/10.png",
    "/img/selfie/11.png",
  ];

  const download = async () => {

    const frame = new Image();
    frame.src = '/img/selfie/frame.png';
    const back = new Image();
    back.src = background;
    const logo = new Image();
    logo.src = '/img/selfie/logo.png';
    const photo = new Image();
    photo.src = uploadSrc;
    const haAsh = new Image();
    haAsh.src = '/img/selfie/Ha-ash.png';
    const line = new Image();
    line.src = '/img/selfie/line.png';
    const goodVibes = new Image();
    goodVibes.src = '/img/selfie/GoodVibes.png';
    const key = new Image();
    key.src = '/img/selfie/key.png';
    const sign = new Image();
    sign.src = '/img/selfie/sign.png';
    const crown = new Image();
    crown.src = '/img/selfie/crown.png';
    const backstageCraze = new Image();
    backstageCraze.src = '/img/selfie/backstageCraze.png';
    const heart = new Image();
    heart.src = '/img/selfie/heart.png';

    // Create a canvas element
    const canvas = document.createElement('canvas');
    canvas.width = 678; // Set the desired width of the final image
    canvas.height = 678; // Set the desired height of the final image
    const context = canvas.getContext('2d');

    // Draw the images onto the canvas
    context.drawImage(back, 0, 0, 678, 678);
    context.drawImage(photo, 89, 89, 500, 500);
    context.drawImage(frame, -10, 0, 685, 685); 
    context.drawImage(logo, 485, 25, 169.5, 26.5);
    context.drawImage(haAsh, 20, 20, 135.6, 45.5);
    context.drawImage(line, 30, 55, 169.5, 106);
    context.drawImage(goodVibes, 288, 34, 101.7, 91.8);
    context.drawImage(key, 35, 286, 101.7, 106);
    context.drawImage(sign, 15, 459, 237, 218.8);
    context.drawImage(crown, 530, 274, 101.7, 88);
    context.drawImage(backstageCraze, 360, 570, 203.4, 30.5);
    context.drawImage(heart, 438, 570, 135.6, 98.3);

    // Convert the canvas element to an image
    const link = document.createElement("a");
    link.href = canvas.toDataURL();
    link.download = "selfieWithHaAsh.png";
    link.click();
  };

  useEffect(() => {
    setImgSrc(uploadSrc);
  }, [uploadSrc]);

  return (
    <div style={style.container}>
      <div
        className="selfie__container"
        style={{ background: `url(${background})` }}
        ref={domEl}
      >
        <div style={style.photo}>
          <img
            alt="camera"
            src={imgSrc}
            style={{ height: "78%", aspectRatio: "1/1", marginTop: "11%" }}
          />
        </div>
        <img alt="frame" src="/img/selfie/frame.png" style={style.frame} />
        <img alt="logo" src="/img/selfie/logo.png" style={style.logo} />
        <img alt="ha-ash" src="/img/selfie/Ha-ash.png" style={style.ha_ash} />
        <img
          alt="heart"
          src="/img/selfie/backstageCraze.png"
          style={style.backstageCraze}
        />
        <img alt="sign" src="/img/selfie/sign.png" style={style.sign} />
        <img alt="key" src="/img/selfie/key.png" style={style.key} />
        <img alt="line" src="/img/selfie/line.png" style={style.line} />
        <img
          alt="goodvibes"
          src="/img/selfie/GoodVibes.png"
          style={style.goodVibes}
        />
        <img alt="crown" src="/img/selfie/crown.png" style={style.crown} />
        <img alt="bks mark" src="/img/selfie/heart.png" style={style.mark} />
      </div>

      <div style={style.back_group}>
        {selfieBack.map((item, index) => (
          <div
            className="selfie__back"
            onClick={() => setBackground(item)}
            key={index}
          >
            <img alt="selfie background" style={{ width: "100%", maxWidth: "86px" }} src={item} />
          </div>
        ))}
      </div>
      {imgSrc !== "" && (
        <div>
          <button style={style.selfie__download_btn} onClick={download}>
            <img alt="download" src="/img/selfie/download.png" />
            {t("download")}
          </button>
          <p style={style.share_txt}>{t("OR SHARE ON")}...</p>
          <div
            className="about__follow-icons"
            style={{ width: 270, marginTop: 0 }}
          >
            <a href="https://t.me/" target="_blank" rel="noreferrer">
              <img src="/img/icons/telegram.svg" alt="data" />
            </a>
            <a href="https://twitter.com/" target="_blank" rel="noreferrer">
              <img src="/img/icons/twitter.svg" alt="data" />
            </a>
            <a href="https://facebook.com/" target="_blank" rel="noreferrer">
              <img src="/img/icons/facebook.svg" alt="data" />
            </a>
            <a href="https://instagram.com/" target="_blank" rel="noreferrer">
              <img src="/img/icons/instagram.svg" alt="data" />
            </a>
            <a href="https://linkedin.com/" target="_blank" rel="noreferrer">
              <img src="/img/icons/linkedin.svg" alt="data" />
            </a>
          </div>
          <p style={style.preview_txt}>{t("You’ll see a preview before you post.")}</p>
          <button
            onClick={() => {
              setImgSrc("");
              setModal({
                open: false,
              });
            }}
            className="input__upload"
            style={{ width: 270 }}
          >
            {t("return")}
          </button>
        </div>
      )}
    </div>
  );
};

const style = {
  container: {
    width: "720px",
    maxWidth: "100%",
    textAlign: "center",
    backgroundColor: "#14142f",
    color: "white",
    borderRadius: "10px",
    border: "1px solid #534f77",
    padding: "20px",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    position: "absolute",
    right: "3%",
    width: "25%",
    top: "3%",
    zIndex: 10,
  },
  camera: {
    width: "100%",
    aspectRatio: "1/1",
    padding: "11%",
    position: "relative",
    zIndex: 10,
    overflow: "hidden",
  },
  photo: {
    width: "100%",
    aspectRatio: "1/1",
    position: "relative",
    zIndex: 10,
    overflow: "hidden",
  },
  ha_ash: {
    position: "absolute",
    top: "3%",
    left: "3%",
    width: "20%",
    zIndex: 10,
  },
  backstageCraze: {
    position: "absolute",
    bottom: "10%",
    right: "15%",
    width: "30%",
    zIndex: 10,
  },
  sign: {
    position: "absolute",
    bottom: "0%",
    left: "3%",
    width: "35%",
    zIndex: 10,
  },
  key: {
    position: "absolute",
    bottom: "40%",
    left: "4%",
    width: "15%",
    zIndex: 10,
  },
  line: {
    position: "absolute",
    top: "8%",
    left: "4%",
    width: "25%",
    zIndex: 10,
  },
  frame: {
    position: "absolute",
    top: "-2%",
    left: "-3%",
    width: "105%",
    zIndex: 10,
  },
  crown: {
    position: "absolute",
    top: "40%",
    right: "4%",
    width: "15%",
    zIndex: 10,
  },
  goodVibes: {
    position: "absolute",
    top: "4%",
    left: "42%",
    width: "15%",
    zIndex: 10,
  },
  mark: {
    position: "absolute",
    bottom: "0%",
    right: "13%",
    width: "20%",
    zIndex: 10,
  },
  blue_camera: {
    width: "16%",
    cursor: "pointer",
    borderRadius: 1000,
  },
  button_block: {
    width: "100%",
    marginTop: "-8%",
    zIndex: 10,
  },
  back_group: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
  },
  control_group: {
    display: "flex",
    justifyContent: "space-between",
    gap: 20,
    width: "100%",
  },
  preview_txt: {
    fontSize: "14px",
    marginTop: 21,
    color: "rgba(255, 255, 255, 0.66)",
  },
  share_txt: {
    letterSpacing: "0.15em",
  },
  selfie__download_btn: {
    width: "270px",
    height: "44px",
    background: "#6a4dfd",
    borderRadius: "4px",
    color: "white",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.15em",
    margin: "17px auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "7px",
    textTransform: "uppercase",
  }
};

export default SelfieBoard;
