import { useState } from "react";
import styles from "./index.module.css";

const FaqItem = ({ question, answer }) => {
  const [collapse, setCollapse] = useState(false);
  return (
    <div className={styles.faq__block}>
      <img
        className={styles.faq__minus}
        onClick={() => setCollapse(!collapse)}
        src={!collapse ? `/img/plus.svg` : `/img/minus.svg`}
        alt="minus"
      />
      <p className={styles.faq__block_question} onClick={() => setCollapse(!collapse)}>
        {question}
      </p>
      {collapse && (
        <div
          className={styles.faq__block_answer}
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      )}
    </div>
  );
};

export default FaqItem;
