import styles from "./index.module.css"

const Search = ({ placeholder, onChange }) => {
  return (
    <div className={styles.search__container}>
      <input type="text" onChange={(e) => onChange(e.target.value)} placeholder={placeholder} />
      <button type="button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" />
        </svg>
      </button>
    </div>
  );
};

export default Search;
