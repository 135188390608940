import React, { useState } from "react";
import { useUserContext } from "../../../context/UserContext";
import { useAppContext } from "../../../context/AppContext";
import { disable2FA, getTwoFactorStatus } from "../../../api/twofactor";
import styles from "./index.module.css";

const DisableTwoFactorModal = () => {
  const { userInfo } = useUserContext();
  const { setLoading, setModal, addToast, setTwoStepStatus } = useAppContext();
  const [token, setToken] = useState("");
  const [wrongCode, setWrongCode] = useState(false);
  const updateStep = async () => {
    setLoading(true);
    const response = await disable2FA({
      token,
      user: userInfo.user,
    });
    await getStatus();
    setLoading(false);
    if (response.isValid) {
      setModal({ open: false });
      addToast("Two Factor Authentication is successfully disabled!", {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      setWrongCode(true);
    }
  };

  const getStatus = async () => {
    const result = await getTwoFactorStatus({
      userId: userInfo.user.id
    });
    setTwoStepStatus(result.status);
  }

  const borderStyle = () => {
    return wrongCode
      ? {
          border: "#FF4E4E 1px solid",
        }
      : {};
  };

  return (
    <div className={styles.container}>
      <div>
        <p className={styles.guideText}>Enther the passcode to continue</p>
        <div className={styles.wrapper}>
          <input
            type="text"
            value={token}
            onChange={(e) => {
              setWrongCode(false);
              setToken(e.target.value);
            }}
            placeholder="Input passcode"
            style={borderStyle()}
          />
          {wrongCode && <span className="text-error">Wrong passcode</span>}
        </div>
      </div>

      <button className={styles.add__btn} onClick={updateStep} type="button">
        Verify
      </button>
    </div>
  );
};

export default DisableTwoFactorModal;
