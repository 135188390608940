import React from "react";
// import { Link } from "react-router-dom";
// import { useMediaQuery } from "usehooks-ts";
// import { useTranslation } from "react-i18next";

import styles from "./index.module.css";

const OfferItem = ({offer}) => {
  // const isMobile = useMediaQuery("(max-width: 992px)");
  // const { t } = useTranslation();
  return (
    <div className={styles.offer_block}>
      <div className={styles.offer__ellipse_green}>
        <div className={styles.offer__green_dot}></div>
      </div>
      <div style={{ flex: 1 }}>
        <p className={styles.offer_title}>Offer Available</p>
        <p className={styles.offer_description}>{offer.offer.description}</p>
        <p className={styles.offer_date}>{offer.offer.url}</p>
        <p className={styles.offer_date}>{offer.offer.date}</p>
        <p className={styles.offer_date}>{offer.offer.eventName}</p>
      </div>
      <button className={styles.offer_btn}>Claim Offer</button>
    </div>
  );
};

export default OfferItem;
