import { Link } from "react-router-dom";
import DeletePopper from "../DeletePopper";
import { useUserContext } from "../../context/UserContext";
import styles from "./index.module.css"

const Collection = ({ collection, handleDelete }) => {
  const { userInfo } = useUserContext();

  return (
    <div className={styles.collection}>
      {((userInfo && userInfo.user.user_type === "ADMIN") ||
        (userInfo &&
          userInfo.user.user_type === "BUSINESS" &&
          collection?.creator?.id === userInfo.user.id)) && (
        <DeletePopper
          onClickDelete={() => {
            handleDelete(collection.id);
          }}
        />
      )}
      <Link
        to={`/collection/${collection?.id}`}
        className={styles.collection__cover}
        style={{
          background: `url(${process.env.REACT_APP_ASSET_BASE_URL}/${collection?.picture_background})`,
          backgroundSize: "cover",
          backgroundColor: "rgba(255, 255, 255, 0.07)",
        }}
      />
      <Link to={`/collection/${collection?.id}`}>
        <div className={styles.collection__meta}>
          <div className={styles.collection__avatar}>
            <img
              src={`${process.env.REACT_APP_ASSET_BASE_URL}/${collection?.picture_small}`}
              alt="collection small"
            />
          </div>
          <div className={styles.collection__name}>
            {collection?.name}
          </div>
          <span className={styles.collection__number}>{collection?.category}</span>
        </div>
      </Link>
    </div>
  );
};

export default Collection;
