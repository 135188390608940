import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";

const CollectionFilterSort = ({
  currentBtn,
  currentTag,
  sort,
  setCurrentBtn,
  setCurrentTag,
  setSort,
}) => {
  const isMobile = useMediaQuery("(max-width: 450px)");
  const { t } = useTranslation();
  return (
    <div className="explorer__top-buttons">
      <div className="explorer__top-btn--group">
        <button
          className={
            currentBtn === "filter"
              ? "explorer__top-active--btn"
              : "explorer__top-inactive--btn"
          }
          onClick={() =>
            currentBtn === "" ? setCurrentBtn("filter") : setCurrentBtn("")
          }
        >
          <img src="/img/icons/filter.svg" alt="filter" />
          <p className="explorer__btn-text">{t("filter")}</p>
          {!isMobile && <img src="/img/icons/dropdown.svg" alt="dropdown" />}
        </button>
        {currentBtn === "filter" && (
          <div
            className={
              isMobile ? "explorer__top-btn-opts-mb" : "explorer__top-btn-opts"
            }
          >
            <div className="explorer__top-checkbox">
              <input
                id="all"
                name="all"
                type="checkbox"
                checked={currentTag === "all"}
                onChange={() => {
                  setCurrentTag("all");
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="all">{t("all")}</label>
            </div>
            <div className="explorer__top-checkbox">
              <input
                id="event"
                name="event"
                type="checkbox"
                checked={currentTag === "Category1"}
                onChange={() => {
                  setCurrentTag("Category1");
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="event">{t("ticket")}</label>
            </div>
            <div className="explorer__top-checkbox">
              <input
                id="digital"
                name="digital"
                type="checkbox"
                checked={currentTag === "Category2"}
                onChange={() => {
                  setCurrentTag("Category2");
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="digital">{t("digital art")}</label>
            </div>
            <div className="explorer__top-checkbox">
              <input
                id="service"
                name="service"
                type="checkbox"
                checked={currentTag === "Category3"}
                onChange={() => {
                  setCurrentTag("Category3");
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="service">{t("service")}</label>
            </div>
          </div>
        )}
      </div>
      <div className="explorer__top-btn--group">
        <button
          className={
            currentBtn === "sort"
              ? "explorer__top-active--btn"
              : "explorer__top-inactive--btn"
          }
          onClick={() =>
            currentBtn === "" ? setCurrentBtn("sort") : setCurrentBtn("")
          }
        >
          <img src="/img/icons/sort.svg" alt="sort" />
          <p className="explorer__btn-text">{t("sort")}</p>
          {!isMobile && <img src="/img/icons/dropdown.svg" alt="dropdown" />}
        </button>
        {currentBtn === "sort" && (
          <div className="explorer__top-btn-opts">
            <div className="explorer__top-checkbox">
              <input
                id="recent"
                name="recent"
                type="checkbox"
                checked={sort === 0}
                className="empty"
                onChange={() => {
                  setSort(0);
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="recent">{t("recently listed")}</label>
            </div>
            <div className="explorer__top-checkbox">
              <input
                id="low to hight"
                name="low to hight"
                type="checkbox"
                checked={sort === 1}
                className="empty"
                onChange={() => {
                  setSort(1);
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="low to hight">{t("price low to hight")}</label>
            </div>
            <div className="explorer__top-checkbox">
              <input
                id="hight to low"
                name="hight to low"
                type="checkbox"
                checked={sort === 2}
                className="empty"
                onChange={() => {
                  setSort(2);
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="hight to low">{t("price hight to low")}</label>
            </div>
            <div className="explorer__top-checkbox">
              <input
                id="likes"
                name="likes"
                type="checkbox"
                checked={sort === 3}
                className="empty"
                onChange={() => {
                  setSort(3);
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="likes">{t("most likes")}</label>
            </div>
            <div className="explorer__top-checkbox">
              <input
                id="date"
                name="date"
                type="checkbox"
                checked={sort === 4}
                className="empty"
                onChange={() => {
                  setSort(4);
                  setCurrentBtn("");
                }}
              />
              <label htmlFor="date">{t("date")}</label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CollectionFilterSort;
