import React from "react";
import { Link } from "react-router-dom";
// import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { isVideoFile } from "../../utils";

import styles from "./index.module.css";
import useFiatSymbol from "../../hooks/useFiatSymbol";
// import useConvertedFiat from "../../hooks/useConvertedFiat";
import useTicketFiat from "../../hooks/useTicketFiat";
import { useAppContext } from "../../context/AppContext";
import TransferNFTModal from "../custom_modals/transfer_nft_modal";
import DetailModal from "../custom_modals/detail_modal";
import ViewOfferModal from "../custom_modals/view_offer_modal";

const DigitalItem = ({ ticket, ticketBuyTime, sendMail, downloadQRcode }) => {
  const { t } = useTranslation();
  const { country, setModal, balance, addToast } = useAppContext();
  const totalCurrencyPrice =
    useTicketFiat(
      ticket.price,
      ticket.eventcard.currency,
      country,
      ticket.discount / 100
    ).toFixed(2) + useFiatSymbol(country);

  const ticketCurrencyPrice =
    useTicketFiat(
      ticket.eventcard.price,
      ticket.eventcard.currency,
      country,
      ticket.discount / 100
    ).toFixed(2) + useFiatSymbol(country);

  const sendItem = () => {
    if (balance > 0) {
      setModal({
        open: true,
        children: <TransferNFTModal ticket={ticket} />,
      });
    } else {
      addToast("You don't have bnb for transaction fee in your wallet.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const viewDetails = () => {
    setModal({
      open: true,
      children: <DetailModal eventCard={ticket.eventcard} tokenURL = {ticket.tokenURL} />,
    });
  };

  const viewOffers = () => {
    if (ticket.offers.length > 0) {
      setModal({
        open: true,
        children: <ViewOfferModal offers={ticket.offers} />,
      });
    }
  }

  return (
    <div className={styles.digital__item__block}>
      <div className={styles.top_part}>
        {ticket.eventcard.category === "Category1" ? (
          <>
            <div className={styles.ticket_item}>
              <img src="/img/wallet/smart_ticket.svg" alt="item" />
            </div>
            <p className={styles.item_title}>{t("smart ticket")}</p>
          </>
        ) : ticket.eventcard.category === "Category3" ? (
          <>
            <div className={styles.service_item}>
              <img src="/img/wallet/service_item.svg" alt="item" />
            </div>
            <p className={styles.item_title}>{t("service")}</p>
          </>
        ) : (
          <>
            <div className={styles.digital_item}>
              <img src="/img/wallet/digital_item.svg" alt="item" />
            </div>
            <p className={styles.item_title}>{t("digital asset")}</p>
          </>
        )}
      </div>
      <div className={styles.ticket_content}>
        <div className={styles.ticket__img_block}>
          <Link
            to={`/event/eventcard/${ticket.eventcard.id}`}
            className={styles.item_avatar}
          >
            {isVideoFile(ticket.eventcard.picture_large) ? (
              <video autoPlay playsInline loop muted className={styles.video}>
                <source
                  src={`${process.env.REACT_APP_ASSET_BASE_URL}/${ticket.eventcard.picture_large}`}
                  type="video/mp4"
                />
              </video>
            ) : (
              <img
                src={`${process.env.REACT_APP_ASSET_BASE_URL}/${ticket.eventcard.picture_large}`}
                alt="NFT"
              />
            )}
          </Link>
          <div className={styles.ticket__title_block_sm}>
            <p className={styles.item_name}>{ticket.eventcard.name}</p>
            <p className={styles.item_location}>{ticket.eventcard.location}</p>
          </div>
        </div>
        <div className={styles.ticket__content_block}>
          <div>
            <div className={styles.ticket__title_block}>
              <p className={styles.item_name}>{ticket.eventcard.name}</p>
              <p className={styles.item_location}>
                {ticket.eventcard.location}
              </p>
            </div>
            <div className={styles.created_purchased}>
              <div>
                <p className={styles.created_txt}>{t("created by")}</p>
                <p className={styles.user_info}>
                  {ticket.eventcard.creator.name}
                </p>
              </div>
              <div>
                <p className={styles.created_txt}>{t("purchased by")}</p>
                <p className={styles.user_info}>
                  {ticket.buyer_name || ticket.buyer.name},
                </p>
                <p className={styles.user__info_email}>
                  {ticket.buyer_email || ticket.buyer.email}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.button_group}>
            <button className={styles.item_btn} onClick={viewOffers}>
              <img src="/img/wallet/percent.svg" alt="details" height={16} />
              <span className={styles.view_btn}>{t("view offers")}</span>
              <div className={styles.offer_notification}>{ticket.offers.length}</div>
            </button>
            <button onClick={viewDetails} className={styles.item_btn}>
              <img
                src="/img/wallet/view_details.svg"
                alt="details"
                height={16}
              />
              <span className={styles.view_btn}>{t("view details")}</span>
            </button>
            <button onClick={sendItem} className={styles.item_btn}>
              <img src="/img/wallet/send_1.svg" alt="details" height={16} />
              <span className={styles.view_btn}>{t("send")}</span>
            </button>
          </div>
        </div>
      </div>
      <div className={styles.foot_part}>
        <div className={styles.bottom__line}></div>
        <div className={styles.bottom__container}>
          <div className={styles.bottom__buttons}>
            <button
              onClick={() =>
                sendMail(ticket, totalCurrencyPrice, ticketCurrencyPrice)
              }
              className={styles.bottom__button}
            >
              <img
                alt="mail"
                className={styles.btn__img}
                src="/img/activity/mail.svg"
              />
              <p style={{ margin: 0 }}>{t("Resend Confirmation Email")}</p>
            </button>
            <button
              onClick={() =>
                downloadQRcode(ticket, totalCurrencyPrice, ticketCurrencyPrice)
              }
              className={styles.bottom__button}
            >
              <img
                alt="mail"
                className={styles.btn__img}
                src="/img/activity/printer.svg"
              />
              <p style={{ margin: 0 }}>{t("Download Printable Ticket")}</p>
            </button>
          </div>
          <div>
            <div className={styles.purchased_time}>
              <img src="/img/icons/clock.svg" alt="clock" />
              <p className="activity__card-ago">{ticketBuyTime(ticket)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalItem;
