export const permissionFilter = (eventcard, permission) => {
  const permission_ticket = permission?.permission_ticket;
  if (permission && permission_ticket !== "" && permission_ticket !== "decline") {
    if (eventcard.collection.id === process.env.REACT_APP_LARS_COLLECTION) {
      if (eventcard.id === permission_ticket) {
        return true;
      } else return false;      
    } else {
      return true;
    }
  } else {
    if (
      eventcard.id === process.env.REACT_APP_LARS_EVENT ||
      eventcard.collection.id !== process.env.REACT_APP_LARS_COLLECTION
    ) {
      return true;
    } else return false;
  }
};
