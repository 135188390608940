import { useEffect, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import { useTranslation } from "react-i18next";

import { useAppContext } from "../../../context/AppContext";
import { useUserContext } from "../../../context/UserContext";
import {
  verifyEventCardById,
  getVerifyEventCards,
  updateEventLike,
} from "../../../api/event";
import { IEventCard } from "../../../utils/interface";
import VerifyTile from "../../../components/tile/verifyTile";
import Pagination from "../../../components/pagination";
import CollectionFilterSort from "../../../components/CollectionFilterSort";
import CategoryFilter from "../../../components/filter/CategoryFilter";

const PageVerifyEvent = () => {
  const { userInfo } = useUserContext();
  const isMobile = useMediaQuery("(max-width: 450px)");
  const isMiddle = useMediaQuery("(max-width: 1440px)");
  const isTablet = useMediaQuery("(max-width: 768px)");
  const [allItems, setAllItems] = useState([]);
  const [events, setEvents] = useState<IEventCard[]>([] as IEventCard[]);
  const [pageIndex, setPageIndex] = useState(0);
  const [itemIndex, setItemIndex] = useState(0);
  const [currentTag, setCurrentTag] = useState("all");
  const [currentBtn, setCurrentBtn] = useState("");
  const [sort, setSort] = useState(0);
  const { searchStr, scrollItem, setLoading, loading } = useAppContext();
  const navigate = useNavigate();
  //   const { t } = useTranslation();

  const leftClick = () => {
    if (itemIndex > 0) {
      let _itemIndex = itemIndex;
      _itemIndex--;
      setItemIndex(_itemIndex);
    }
  };

  const rightClick = () => {
    if ((itemIndex + 1) * 8 < events.length) {
      let _itemIndex = itemIndex;
      _itemIndex++;
      setItemIndex(_itemIndex);
    }
  };

  const getLikesNumber: (eventCard: any) => number = (eventCard: any) => {
    let likes = [];
    try {
      likes = JSON.parse(eventCard.likes_number);
    } catch (err) {
      likes = [];
    }
    if (typeof likes !== "object" || likes === null) likes = [];
    if (likes) return likes.length;
    else return 0;
  };

  const sortEvents = (events_: never[]) => {
    let _temp: any[] = [];
    if (currentTag === "all") {
      _temp = [...events_];
    } else {
      _temp = events_.filter((event: any) => event.category === currentTag);
    }
    let temp: any[] = [];
    if (sort === 0) {
      temp = _temp.sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    } else if (sort === 1) {
      temp = _temp.sort((a: any, b: any) => Number(a.price) - Number(b.price));
    } else if (sort === 2) {
      temp = _temp.sort((a: any, b: any) => Number(b.price) - Number(a.price));
    } else if (sort === 3) {
      temp = _temp.sort(
        (a: any, b: any) => getLikesNumber(b) - getLikesNumber(a)
      );
    } else if (sort === 4) {
      temp = _temp.sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      );
    }

    let res: any[] = [];
    res = temp.filter(
      (card: any) =>
        card.name.toLowerCase().includes(searchStr?.toLowerCase()) ||
        card.venue_description
          .toLowerCase()
          .includes(searchStr?.toLowerCase()) ||
        card.description.toLowerCase().includes(searchStr?.toLowerCase())
    );
    setPageIndex(0);
    setItemIndex(0);
    setEvents([...res]);
  };

  useEffect(() => {
    (scrollItem.current as any).scrollIntoView({ behavior: "smooth" });
  }, [itemIndex, pageIndex, scrollItem]);

  useEffect(() => {
    if (userInfo && userInfo.user.user_type === "ADMIN") {
      setLoading(true);
      getVerifyEventCards().then((res) => {
        if (res.success) {
          setAllItems(res.eventcards);
        }
        setLoading(false);
      });
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sortEvents(allItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, allItems, sort, currentTag, searchStr]);

  const handleVerifyEventCard = async (eventCardId: string) => {
    setLoading(true);
    await verifyEventCardById(eventCardId)
      .then((res) => {
        getVerifyEventCards().then((res) => {
          if (res.success) {
            setAllItems(res.eventcards);
            setLoading(false);
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  const onClickLike = (index: number) => {
    if (!userInfo) navigate("/signin");
    else {
      let likes: any[] = [];
      try {
        likes = JSON.parse((events[index] as any).likes_number);
      } catch (err) {
        likes = [];
      }
      if (typeof likes !== "object" || likes === null) likes = [];
      const userId = userInfo.user.id;
      if (likes.includes(userId)) {
        const index = likes.indexOf(userId);
        likes.splice(index, 1);
      } else {
        likes.push(userId);
      }
      let _itemIndex = itemIndex;
      let _pageIndex = pageIndex;
      updateEventLike({
        id: (events[index] as any).id,
        likes_number: JSON.stringify(likes),
      }).then((res) => {
        if (res.success) {
          setLoading(true);
          getVerifyEventCards().then((res) => {
            if (res.success) {
              setAllItems(res.eventcards);
              setLoading(false);
            }
          });
          setItemIndex(_itemIndex);
          setPageIndex(_pageIndex);
        }
      });
    }
  };

  return (
    <div className="container explorer__container">
      <div className="explorer__top">
        <div>
          <p className="explorer__top-title">Pending Ticket</p>
        </div>
        <div className="explorer__top-main">
          <CategoryFilter
            currentTag={currentTag}
            setCurrentTag={setCurrentTag}
          />
          <CollectionFilterSort
            currentBtn={currentBtn}
            currentTag={currentTag}
            sort={sort}
            setCurrentBtn={setCurrentBtn}
            setCurrentTag={setCurrentTag}
            setSort={setSort}
          />
        </div>
      </div>
      {events.length === 0 && loading === false && (
        <p className="empty__text">There is no pending ticket.</p>
      )}
      <div className="grid-container" style={{ margin: isMiddle ? "auto" : 0 }}>
        {events.map(
          (eventCard: any, i) =>
            i >= (pageIndex + itemIndex) * 8 &&
            i < (pageIndex + itemIndex + 1) * 8 && (
              <div
                key={`explorer_event_${i}`}
                className={isTablet ? "col-12" : "col-sm-6 col-lg-4 col-xl-3"}
                style={{ padding: 0, marginRight: 0 }}
              >
                <VerifyTile
                  eventCard={eventCard}
                  i={i}
                  carousel={true}
                  verifyEventCard={handleVerifyEventCard}
                  onClickLike={onClickLike}
                />
              </div>
            )
        )}
        {isMobile && pageIndex > 0 && (
          <button onClick={() => setPageIndex(0)} className="arrow_up_button">
            <LazyLoadImage src="/img/icons/arrow-upward.svg" alt="" />
          </button>
        )}
      </div>

      <Pagination
        items={events}
        setItemIndex={setItemIndex}
        pageIndex={pageIndex}
        itemIndex={itemIndex}
        leftClick={leftClick}
        rightClick={rightClick}
        count={8}
      />
      <div className="bottom_block"></div>
    </div>
  );
};

export default PageVerifyEvent;
