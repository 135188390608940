import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAppContext } from "../../context/AppContext";
import FiatSymbol from "../FiatSymbol";
// import ItemSelectModal from "../custom_modals/item_select_modal";
import styles from "./index.module.css";

const ItemAddons = ({
  values,
  addons,
  remove_Addon,
  add_Addons,
  addonIcons,
  benefitImage,
  benefitImagePreview,
  benefitImageChange,
  imageDelete,
}) => {
  const { t } = useTranslation();
  const { addToast } = useAppContext();
  const [addonName, setAddonName] = useState("");
  const [addonDescription, setAddonDescription] = useState("");
  const [addonPrice, setAddonPrice] = useState("");
  const [addonIcon, setAddonIcon] = useState("");
  const imageRef = useRef(null);

  // const set_Icon = (icon_src) => {
  //   setAddonIcon(icon_src);
  // };

  // const select_Icon = () => {
  //   setModal({
  //     open: true,
  //     children: (
  //       <ItemSelectModal
  //         title="Select Item Icon"
  //         handleEnd={set_Icon}
  //         icons={addonIcons}
  //       />
  //     ),
  //   });
  // };

  const add = () => {
    if (addonName === "" || addonDescription === "" || addonPrice === "") {
      addToast("Please input all traits for the benefit", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    add_Addons(addonName, addonPrice, addonDescription, addonIcon);
    setAddonName("");
    setAddonDescription("");
    setAddonPrice("");
    setAddonIcon("");
    imageRef.current.value = "";
  };

  return (
    <div className="cr__evcard-subItem">
      <h4 className="cr__evcard--subtitle">
        <span></span>
        {t("benefits")}
      </h4>
      <div className="addon-container">
        {addons.map((addon, index) => (
          <div className="addon-block" key={index}>
            <p
              style={{
                color: "#fff",
                width: 30,
                marginBottom: 0,
              }}
            >
              {index + 1}
            </p>
            <p className="text__addon-name">{addon.name}</p>
            <p className="text__addon-description">{addon.description}</p>
            <p className="text__addon-price">
              {addon.price} <FiatSymbol currency={values.currency} />
            </p>
            <LazyLoadImage src="/img/benefit.png" alt="addon-icon" />
            <div
              className="sign__btn-second"
              style={{
                width: 40,
                border: 0,
              }}
              onClick={() => remove_Addon(index)}
            >
              <LazyLoadImage
                src="/img/trash.png"
                alt="trush"
                className="img__trush"
              />
            </div>
          </div>
        ))}
        <div
          className="cr__evcard-rowParent"
          style={{ gap: 20, paddingLeft: 0 }}
        >
          <div className={styles.benefitNameBlock}>
            <p className="cr__evcard-labelTitle">{t("benefit name")}*</p>
            <p className={styles.labelDescription}>
              {t("Maximum 30 characters.")}
            </p>
            <input
              type="text"
              className="sign__input"
              value={addonName}
              onChange={(e) => setAddonName(e.target.value)}
              placeholder="Enter benefit name..."
            />
          </div>
          <div className={styles.benefitPriceBlock}>
            <p className="cr__evcard-labelTitle">{t("price")}</p>
            <p className={styles.labelDescription}>
              {t("Value of the benefit.")}
            </p>
            <input
              type="number"
              className="sign__input"
              placeholder="0"
              value={addonPrice}
              onChange={(e) => setAddonPrice(e.target.value)}
            />
            <div className={styles.fiatSymbol}>
              <FiatSymbol currency={values.currency} />
            </div>
          </div>
          <div className={styles.benefitIconBlock}>
            <p className="cr__evcard-labelTitle">{t("icon")}</p>
            <img
              src="/img/benefit.png"
              alt="AddOnIcon"
              style={{ marginTop: 10 }}
            />
          </div>
        </div>
        <div className="cr__evcard-rowParent" style={{ paddingLeft: 0 }}>
          <div className={styles.benefitDescriptionBlock}>
            <p className="cr__evcard-labelTitle">{t("description")}</p>
            <p className={styles.labelDescription}>
              {t("Appears below the benefit name.")}
            </p>
            <textarea
              className={styles.descriptionInput}
              rows="4"
              value={addonDescription}
              onChange={(e) => setAddonDescription(e.target.value)}
              placeholder="Enter benefit description..."
            />
          </div>
        </div>
        <div className="cr__evcard-rowParent" style={{ paddingLeft: 0 }}>
          <div style={{ width: "100%" }}>
            <p className={styles.benefitImagelabelTitle}>
              {t("ADD BENEFIT IMAGE/VIDEO")}
            </p>
            <p className="cr__evcard-labelDesc" style={{ maxWidth: "none" }}>
              {t(
                "You can upload image/video that show the details of the benefit. Maximum 1 image/video per benefit."
              )}
            </p>
            <div className="cr__evcard-uploadImg" style={{ maxWidth: 350 }}>
              <label
                id="file8"
                htmlFor="benefitimage-upload"
                className="input__upload"
                style={{
                  display: benefitImagePreview === "" ? "flex" : "none",
                }}
              >
                {benefitImagePreview ? (
                  benefitImage?.name
                ) : (
                  <>
                    <img src="/img/selfie/upload.png" alt="upload" />{" "}
                    <span>{t("upload")}</span>
                  </>
                )}
              </label>
              <input
                data-name="#file8"
                id="benefitimage-upload"
                name="file"
                type="file"
                accept=".png,.jpg,.jpeg,.gif,.mp4,.mov,.avi"
                onChange={benefitImageChange}
                src={benefitImagePreview}
                ref={imageRef}
              />
            </div>
            {benefitImagePreview && (
              <div className={styles.previewBlock}>
                {benefitImagePreview.includes("image") ? (
                  <LazyLoadImage
                    src={benefitImagePreview}
                    className={styles.largeImgPreview}
                    alt="floorMapImage preview"
                  />
                ) : (
                  <video
                    autoPlay
                    playsInline
                    loop
                    controls
                    muted
                    className={styles.largeImgPreview}
                    id="video"
                  >
                    <source src={benefitImagePreview} type="video/mp4" />
                    {t("Your browser does not support thte video tag.")}
                  </video>
                )}
                <img
                  onClick={() => imageDelete("benefitImage")}
                  className={styles.trashImg}
                  src="/img/trash.png"
                  alt="delete"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="sign__group cr__evcard-100"
        style={{ padding: "0 30px 0 53px" }}
      >
        <div className="sign__btn-second" onClick={add}>
          {t("new add on")}
        </div>
      </div>
    </div>
  );
};

export default ItemAddons;
