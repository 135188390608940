import api from "./api";

const createPermission = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/permission/create", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getPermissionByEmail = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/permission/get_by_email", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getAllPermission = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/permission/all_permissions", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const updatePermission = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/permission/update_permission", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export {
  createPermission,
  getPermissionByEmail,
  getAllPermission,
  updatePermission,
};
