import api from "./api";

const createOffer = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/offer/create", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getOffer = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/offer/get", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export { createOffer, getOffer };
