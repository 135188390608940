const CURRENCY = {
  TR: "TRY",
  US: "USD",
  IN: "INR",
  GB: "GBP",
  EU: "EUR",
  CL: "CLP",
  DK: "DKK",
  SE: "SEK",
};

export { CURRENCY };
