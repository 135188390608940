import api from "./api";

const create2FA = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/twofactor/generate", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const verify2FA = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/twofactor/verify", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const disable2FA = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/twofactor/disable", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const verifySign2FA = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/twofactor/login_verify", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

const getTwoFactorStatus = (data) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/twofactor/status", data)
      .then((response) => {
        if (response.status === 201) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export { create2FA, verify2FA, verifySign2FA, getTwoFactorStatus, disable2FA };
