// @ts-nocheck
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { ColDef, RowClassParams } from "ag-grid-community";
import { getAllShareLinks } from "../../../../api/sharelinktrack";
import { getAllLikeNumbers } from "../../../../api/event";
import { useUserContext } from "../../../../context/UserContext";
import { useAppContext } from "../../../../context/AppContext";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import styles from "./index.module.css";

const PageTrackReport = () => {
  const { userInfo } = useUserContext();
  const { setLoading } = useAppContext();
  const navigate = useNavigate();
  const [shareLinks, setShareLinks] = useState<any[]>([]);
  const [likes, setLikes] = useState<any[]>([]);
  const shareLinksRef = useRef(null);
  const likeRef = useRef(null);
  const getLinks = async () => {
    const links = await getAllShareLinks();
    let $links: any[] = [];
    links.map((item: any) => {
      let link = {
        Link: item.url,
        Category: item.category,
        "Owner Name": item.name,
        "Owner Email": item.email,
      };
      $links.push(link);
      return $links;
    });

    const visitedMap = {};

    const $sharedLinks = $links.reduce((acc, item) => {
      const key = item.Link;

      if (visitedMap[key]) {
        visitedMap[key].Visited++;
      } else {
        visitedMap[key] = { ...item, Visited: 1 };
        acc.push(visitedMap[key]);
      }

      return acc;
    }, []);
    const $Rlinks = $sharedLinks.filter((item) => item['Owner Name'] !== "undefined" || item["Owner Email"])

    setShareLinks($Rlinks);
  };
  const getLikes = async () => {
    const res = await getAllLikeNumbers();
    let $likes: any[] = [];
    res.eventcards.map((item: any) => {
      let like = {
        Name: item.name,
        Likes: JSON.parse(item.likes_number).length,
        Users: JSON.parse(item.likes_number),
      };
      $likes.push(like);
      return $likes;
    });
    setLikes($likes);
    setLoading(false);
  };
  const getRowClass = (params: RowClassParams) => {
    if (params.rowIndex % 2 === 0) return "odd-row";
  };
  const LinkCopyRender = (data: any) => {
    return (
      <div style={{ display: "flex", gap: 10 }}>
        <p style={{ margin: 0 }}>{data.value}</p>
        {/* <img src="/img/icons/copy.svg" alt="copy" /> */}
      </div>
    );
  };
  const columnDefsForLink = useMemo<ColDef[]>(
    () => [
      { field: "Owner Name", width: 200 },
      { field: "Owner Email", width: 200 },
      { field: "Category", width: 150 },
      { field: "Visited", width: 100 },
      {
        headerName: "Link",
        field: "Link",
        width: 1800,
        cellRenderer: "LinkCopyRender",
      },
    ],
    []
  );
  const columnDefsForLike = useMemo<ColDef[]>(
    () => [
      { field: "Name", width: 400 },
      { field: "Likes", width: 100 },
      { field: "Users", width: 800 },
    ],
    []
  );
  const sharedLinksExport = () => {
    shareLinksRef.current.api.exportDataAsCsv({ fileName: "sharedLinks.csv" });
  };
  const likesExport = () => {
    likeRef.current.api.exportDataAsCsv({ fileName: "likes.csv" });
  };
  useEffect(() => {
    if (userInfo && userInfo.user.user_type === "ADMIN") {
      setLoading(true);
      getLinks();
      getLikes();
    } else {
      navigate("/signin");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.report__container}>
      <div className={styles.link__container}>
        <div className={styles.link__top_part}>
          <h1 className={styles.title}>List of shared links</h1>
          <button className={styles.download__btn} onClick={sharedLinksExport}>
            Download CSV
          </button>
        </div>
        <div className={styles.table__container}>
          <AgGridReact
            className="w-full h-full ag-grid-table"
            rowClass={["custom-row"]}
            rowData={shareLinks}
            headerHeight={60}
            rowHeight={60}
            debounceVerticalScrollbar
            columnDefs={columnDefsForLink}
            getRowClass={getRowClass}
            suppressRowHoverHighlight={false}
            frameworkComponents={{
              LinkCopyRender,
            }}
            ref={shareLinksRef}
          ></AgGridReact>
        </div>
      </div>
      <div className={styles.link__container}>
        <div className={styles.link__top_part}>
          <h1 className={styles.title}>List of ticket likes</h1>
          <button className={styles.download__btn} onClick={likesExport}>
            Download CSV
          </button>
        </div>
        <div className={styles.table__container}>
          <AgGridReact
            className="w-full h-full ag-grid-table"
            rowClass={["custom-row"]}
            rowData={likes}
            headerHeight={60}
            rowHeight={60}
            debounceVerticalScrollbar
            columnDefs={columnDefsForLike}
            getRowClass={getRowClass}
            suppressRowHoverHighlight={false}
            ref={likeRef}
          ></AgGridReact>
        </div>
      </div>
    </div>
  );
};

export default PageTrackReport;
