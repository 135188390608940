// import { useTranslation } from "react-i18next";
// import { useAppContext } from "../../context/AppContext";
import React, { useEffect, useState, useRef } from "react";
// import { Link } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";
import { getAllNotification, readNotification } from "../../api/notification";
import styles from "./index.module.css";

const Notification = () => {
  // const { setModal } = useAppContext();
  // const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [redDot, setRedDot] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const { userInfo } = useUserContext();
  const btnElement = useRef();
  const content = useRef();

  const getData = async () => {
    const data = await getAllNotification();
    sortData(data);
  };

  const sortData = (data) => {
    let $data = data.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    setNotifications($data);
    let unreadData = $data.filter(
      (item) => !item.read_users.includes(userInfo.user.id)
    );
    if (unreadData.length !== 0) {
      setRedDot(true);
    }
  };

  const handleOutsideClick = (event) => {
    if (
      btnElement.current &&
      !btnElement.current.contains(event.target) &&
      content.current &&
      !content.current.contains(event.target)
    ) {
      setShow(false);
    }
  };

  useEffect(() => {
    if (show) {
      setRedDot(false);
      readNotification({
        userId: userInfo.user.id,
      }).then((response) => console.log(response));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    // Attach the global click event listener
    document.addEventListener("click", handleOutsideClick);

    // Return a cleanup function to remove the event listener
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <button
        className={styles.header__nav_link}
        onClick={() => notifications.length > 0 && setShow(!show)}
        ref={btnElement}
      >
        <img
          className={styles.bell__img}
          src="/img/icons/bell.svg"
          alt="data"
        />
        {redDot && (
          <img
            className={styles.bell__red}
            src="/img/icons/bell_red.svg"
            alt="data"
          />
        )}
      </button>
      <div
        className={styles.content}
        style={{ display: show ? "block" : "none" }}
        ref={content}
      >
        {/* <div className={styles.itemContent}>
          <Link to="/#">
            <p className={styles.allText}>See All Notifications</p>
          </Link>
        </div> */}
        {notifications.map((item, index) => (
          <div key={index} className={styles.itemContent}>
            <p className={styles.allText}>{item.title}</p>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: item.description }}
              style={{ borderBottom: notifications.length - 1 === index && "none" }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notification;
