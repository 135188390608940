import { useState } from "react";
// import { useMediaQuery } from "usehooks-ts";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import DeletePopper from "../DeletePopper";
import { useUserContext } from "../../context/UserContext";
import Fiat from "../Fiat";
import FiatSymbol from "../FiatSymbol";
import { getEventPrice } from "../../utils";
import CountTime from "../CountTime";
import styles from "./index.module.css";

const LikedTile = ({
  eventCard,
  i,
  deleteEventCard,
  onClickLike,
  carousel = false,
}) => {
  // const isMobile = useMediaQuery("(max-width: 450px)");
  const [showBuy, setShowBuy] = useState(false);
  const { userInfo } = useUserContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const buttonText = () => {
    let text =
      eventCard.collection.id === "0db5c2a2-3b63-4660-8d35-1bcf02eedde1" ||
      eventCard.collection.id === "b141ae24-0387-42d5-8cda-29b002b8c842"
        ? t("coming soon")
        : eventCard.total_tickets === eventCard.buy_count
        ? t("sold out")
        : t("buy");
    return text;
  };

  return (
    <div
      key={`explorer_event_${i}`}
      className={styles.explorer__card_item}
      onMouseEnter={(e) => setShowBuy(true)}
      onMouseLeave={(e) => setShowBuy(false)}
    >
      <div
        className={styles.card}
        style={{
          margin: carousel ? "auto" : "",
        }}
      >
        {eventCard.total_tickets === eventCard.buy_count && (
          <div className={styles.card_sold}>{t("sold out")}</div>
        )}
        {userInfo && userInfo.user.user_type === "ADMIN" && (
          <DeletePopper
            setDeletePopupStatus={() => {}}
            onClickDelete={() => {
              deleteEventCard(eventCard.id);
            }}
          />
        )}
        <Link
          to={`/event/eventcard/${eventCard.id}`}
          className={styles.card__cover}
        >
          <LazyLoadImage
            src={`${process.env.REACT_APP_ASSET_BASE_URL}/${eventCard.picture_small}`}
            alt="Item small Image"
          />
        </Link>
        <div className={styles.card__mark}>
          <img
            src={`${process.env.REACT_APP_SITE_URL}/img/mark/brown.svg`}
            alt="brown"
          />
        </div>
        <div className={styles.card__likes_top} onClick={() => onClickLike(i)}>
          {userInfo &&
          eventCard.likes_number &&
          eventCard.likes_number.includes(userInfo.user.id) ? (
            <img
              src="/img/icons/liked_blue.svg"
              alt="liked"
              className="like-img"
            />
          ) : (
            <svg
              className="like-img"
              width="20"
              height="20"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1107_4473)">
                <path
                  className="heart_icon"
                  d="M14.1187 3.22152C13.7782 2.88086 13.3739 2.61062 12.9289 2.42625C12.4839 2.24187 12.007 2.14697 11.5253 2.14697C11.0437 2.14697 10.5667 2.24187 10.1218 2.42625C9.6768 2.61062 9.27251 2.88086 8.93201 3.22152L8.22534 3.92819L7.51867 3.22152C6.83088 2.53373 5.89803 2.14733 4.92534 2.14733C3.95265 2.14733 3.0198 2.53373 2.33201 3.22152C1.64421 3.90932 1.25781 4.84217 1.25781 5.81486C1.25781 6.78754 1.64421 7.72039 2.33201 8.40819L3.03867 9.11486L8.22534 14.3015L13.412 9.11486L14.1187 8.40819C14.4593 8.06769 14.7296 7.6634 14.9139 7.21843C15.0983 6.77345 15.1932 6.29651 15.1932 5.81486C15.1932 5.3332 15.0983 4.85626 14.9139 4.41129C14.7296 3.96631 14.4593 3.56203 14.1187 3.22152V3.22152Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_1107_4473">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0.226562 0.147949)"
                  />
                </clipPath>
              </defs>
            </svg>
          )}
        </div>
        <div className={styles.card__info_contain}>
          <h3 className={styles.explorer__card_title}>
            <Link to={`/event/eventcard/${eventCard.id}`}>
              {eventCard.name}
            </Link>
          </h3>
          <div className={styles.text__location}>
            <div className={styles.text__location_item}>
              <p className={styles.text__card_key}>{t("collection")}</p>
              <div className="card__author">
                <Link to={`/collection/${eventCard.collection.id}`}>
                  {eventCard.collection.name}
                </Link>
              </div>
            </div>
            <div className={styles.text__location_item}>
              <p className={styles.text__card_key}>{t("creator")}</p>
              <div className="card__author">
                <span>{eventCard.creator.name}</span>
                <img
                  src="/img/icons/verified.svg"
                  alt="verified"
                  style={{ height: 16, display: "inline-block" }}
                />
              </div>
            </div>
          </div>
          {JSON.parse(eventCard.addons).length > 0 ? (
            <div className={styles.addons_contain}>
              <div className="article__message">
                <img alt="addons" src="/img/addon.png" />
              </div>
              <p className={styles.addons_text}>
                <span style={{ fontWeight: "bold" }}>
                  {JSON.parse(eventCard.addons).length} Benefit
                  {JSON.parse(eventCard.addons).length > 1 ? "s" : null}
                </span>
                &nbsp;Included
              </p>
            </div>
          ) : (
            <div className={styles.empty__addons_contain}></div>
          )}

          <div className={styles.card__explorer_info}>
            {!showBuy && (
              <div
                style={{
                  display: "flex",
                  justifyContent:
                    eventCard.category !== "Category2"
                      ? "space-between"
                      : "end",
                }}
              >
                {!showBuy && eventCard.category !== "Category2" && (
                  <p className={styles.text__location_key}>{t("ends in")}</p>
                )}
                <p className={styles.text__location_key}>{t("reserve price")}</p>
              </div>
            )}
            {!showBuy && (
              <div className="order__summary_item">
                <div>
                  {eventCard.category !== "Category2" && (
                    <div style={{ display: "flex", marginTop: 9 }}>
                      <LazyLoadImage src="/img/icons/clock.svg" alt="clock" />
                      <p className={styles.count__text}>
                        <CountTime
                          date={
                            eventCard?.end_date
                              ? eventCard.end_date
                              : eventCard.date
                          }
                        />
                      </p>
                    </div>
                  )}
                </div>
                <div className={styles.card__price}>
                  <p className={styles.text__location_price}>
                    <Fiat
                      price={getEventPrice(eventCard)}
                      currency={eventCard.currency}
                    />
                    &nbsp;
                    <FiatSymbol />
                  </p>
                </div>
              </div>
            )}
            {showBuy && (
              <button
                className={styles.buy__btn_tile}
                onClick={(e) => navigate(`/event/eventcard/${eventCard.id}`)}
              >
                {buttonText()}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LikedTile;
