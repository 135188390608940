import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../../context/UserContext";
import { useAppContext } from "../../../context/AppContext";
import Search from "../../../components/search";
import TransactionTable from "../../../components/transactionTable";
import ERC20TransactionTable from "../../../components/erc20_table";
import ERC721TransactionTable from "../../../components/erc721Table";
import TransactionItem from "../../../components/transaction_item";
import Bep20Item from "../../../components/bep20_item";
import NFTItem from "../../../components/nft_item";
import styles from "./index.module.css";

const Transactions = () => {
  const [search, setSearch] = useState("");
  const [tag, setTag] = useState("all");
  // const [staking, setStaking] = useState(false);
  const { userInfo } = useUserContext();
  const { t } = useTranslation();
  const {
    transactions,
    erc20Transactions,
    erc721Transactions,
  } = useAppContext();
  const memoTxns = useMemo(
    () =>
      tag === "all"
        ? transactions
        : tag === "nft"
        ? erc721Transactions
        : erc20Transactions,
    [transactions, erc20Transactions, erc721Transactions, tag]
  );
  const searchedTxns = useMemo(
    () =>
      memoTxns.filter((item: any) =>
        search === ""
          ? item
          : item?.transaction_hash
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            item?.hash?.toLowerCase().includes(search.toLowerCase()) ||
            item?.to_address?.toLowerCase().includes(search.toLowerCase()) ||
            item?.from_address?.toLowerCase().includes(search.toLowerCase())
      ),
    [search, memoTxns]
  );
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);

  const nextPage = () => {
    if (page < searchedTxns.length / count) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    setCount(10);
    setPage(1);
  }, [tag]);

  return (
    <div className={styles.wallet_content}>
      <div className={styles.wallet__title_part}>
        <img src="/img/wallet/transaction_1.svg" alt="transaction" />
        <div>
          <p className={styles.wallet__content_title}>{t("transactions")}</p>
        </div>
      </div>
      <div className={styles.currencies__top_part}>
        <div className={styles.transaction__top_btns}>
          <div className={styles.sent_received}>
            <button
              className={
                tag === "all"
                  ? "category__active--tag"
                  : "category__inactive--tag"
              }
              onClick={() => setTag("all")}
            >
              {t("transactions")}
            </button>
            <button
              className={
                tag === "bep-20"
                  ? "category__active--tag"
                  : "category__inactive--tag"
              }
              onClick={() => setTag("bep-20")}
            >
              {/* Token Transfers (BEP-20) */}
              BEP-20
            </button>
            <button
              className={
                tag === "nft"
                  ? "category__active--tag"
                  : "category__inactive--tag"
              }
              onClick={() => setTag("nft")}
            >
              {/* NFT Transfers */}
              NFT
            </button>
          </div>
          {/* <div className="divide__vertical_line"></div>
          <div className={styles.regular_staking}>
            <button
              className={
                staking === false ? styles.regular__staking_active__tag : ""
              }
              onClick={() => setStaking(false)}
            >
              Regular
            </button>
            <button
              className={staking === true ? styles.regular__staking_active__tag : ""}
              onClick={() => setStaking(true)}
            >
              Staking
            </button>
          </div> */}
        </div>
        <div className={styles.transaction_search}>
          <div>
            <Search onChange={setSearch} placeholder={"Search transaction"} />
          </div>
        </div>
      </div>
      <div className={styles.transaction_table_contain}>
        {tag === "all" && (
          <TransactionTable
            transactions={searchedTxns}
            page={page}
            count={count}
          />
        )}
        {tag === "nft" && (
          <ERC721TransactionTable
            transactions={searchedTxns}
            page={page}
            count={count}
            wallet={userInfo?.user?.wallet_address}
          />
        )}
        {tag === "bep-20" && (
          <ERC20TransactionTable
            transactions={searchedTxns}
            page={page}
            count={count}
            wallet={userInfo?.user?.wallet_address}
          />
        )}
      </div>
      {searchedTxns.length > count && (
        <div className={styles.transaction_pagination}>
          <div className={styles.tr__pagination_left}>
            <div className={styles.number_select} style={{ paddingRight: 2 }}>
              <p className={styles.show__numbers_txt}>Show:</p>
              <div style={{ position: "relative", display: "flex" }}>
                <select
                  className={styles.transaction__page_select}
                  name="page number"
                  id="page number"
                  value={count}
                  onChange={(e) => setCount(Number(e.target.value))}
                >
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                <img
                  className={styles.page__down_arrow}
                  src="/img/wallet/down_arrow.svg"
                  alt="down"
                />
              </div>
            </div>
            <p className={styles.show__numbers_txt}>
              Showing{" "}
              {searchedTxns.length > count * page
                ? count
                : searchedTxns.length - (page - 1) * count}{" "}
              out of {searchedTxns.length} entries
            </p>
          </div>
          <div className={styles.tr__pagination_right}>
            <div className={styles.prev_page} onClick={() => prevPage()}>
              <img src="/img/wallet/chevron-left.svg" alt="left arrow" />
            </div>
            <div className={styles.number_select}>
              <p className={styles.show__numbers_txt}>
                Page {page} of {Math.ceil(searchedTxns.length / count)}
              </p>
            </div>
            <div className={styles.next_page} onClick={() => nextPage()}>
              <img src="/img/wallet/chevron-right.svg" alt="right arrow" />
            </div>
          </div>
        </div>
      )}
      {tag === "bep-20" &&
        searchedTxns.map((item: any, index: number) => (
          <Bep20Item
            key={index}
            item={item}
            wallet={userInfo?.user?.wallet_address}
          />
        ))}
      {tag === "all" &&
        searchedTxns.map((item: any, index: number) => (
          <TransactionItem
            key={index}
            item={item}
            wallet={userInfo?.user?.wallet_address}
          />
        ))}
      {tag === "nft" &&
        searchedTxns.map((item: any, index: number) => (
          <NFTItem
            key={index}
            item={item}
            wallet={userInfo?.user?.wallet_address}
          />
        ))}
    </div>
  );
};

export default Transactions;
