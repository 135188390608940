import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/AppContext";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SplitPayment = ({
  payees,
  remove_Payee,
  add_Payee,
}) => {
  const { t } = useTranslation();
  const { addToast } = useAppContext();
  const [payeeWallet, setPayeeWallet] = useState("");
  const [payeeFee, setPayeeFee] = useState("");

  const shortAddress = (str) => {
    return str?.slice(0, 5) + "..." + str?.slice(str.length - 4);
  };
  const add = () => {
    if (payees.length > 10) return;
    if (payeeWallet === "" || payeeFee === "") {
      addToast("Please input all traits for the Payee", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    add_Payee(payeeWallet, payeeFee);
    setPayeeWallet("");
    setPayeeFee("");
  };

  return (
    <div className="cr__evcard-subItem">
      <h4 className="cr__evcard--subtitle">
        <span></span>
        {t("add payment split settings")}
      </h4>
      <div className="addon-container">
        {payees.map((payee, index) => (
          <div className="" key={index}>
            <div className="addon-block">
              <p
                style={{
                  color: "#fff",
                  width: 30,
                  marginBottom: 0,
                }}
              >
                {index + 1}
              </p>
              <p className="text__addon-name">{shortAddress(payee.wallet)}</p>
              <p className="text__addon-name">{payee.fee}</p>
              <div
                className="sign__btn-second"
                style={{
                  width: 40,
                  border: 0,
                  margin: 0,
                }}
                onClick={() => remove_Payee(index)}
              >
                <LazyLoadImage
                  src="/img/trash.png"
                  alt="trush"
                  className="img__trush"
                />
              </div>
            </div>
          </div>
        ))}
        <div className="cr__evcard-rowParent" style={{ paddingLeft: 0 }}>
          <div className="sign__group cr__evcard-50">
            <p className="cr__evcard-labelTitle">{t("BSC wallet")}</p>
            <input
              type="text"
              className="sign__input"
              value={payeeWallet}
              onChange={(e) => setPayeeWallet(e.target.value)}
            />
          </div>
          <div className="sign__group cr__evcard-50">
            <p className="cr__evcard-labelTitle">{t("payee fee")} %</p>
            <input
              type="number"
              className="sign__input"
              value={payeeFee}
              onChange={(e) => setPayeeFee(e.target.value)}
            />
          </div>
        </div>
        <div className="cr__evcard-rowParent">
          <div className="sign__group cr__evcard-50"></div>
        </div>
      </div>
      <div
        className="sign__group cr__evcard-100"
        style={{ padding: "0 30px 0 53px" }}
      >
        <div className="sign__btn-second" onClick={add}>
          {t("add")}
        </div>
      </div>
    </div>
  );
};

export default SplitPayment;
