/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { useUserContext } from "../../../context/UserContext";
import { useAppContext } from "../../../context/AppContext";
import { eventsTickets, getEventCardById } from "../../../api/event";
// import Pagination from "../../../components/pagination";
import OfferCreateTable from "../../../components/offer_table";
import OfferModal from "../../../components/custom_modals/offer_modal";

import styles from "./index.module.css";

const PageOfferCreate = () => {
  const { userInfo } = useUserContext();
  const [allTickets, setAllTickets] = useState<any[]>([]);
  const [eventCard, setEventCard] = useState();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(10);
  const [checkBoxs, setCheckBoxs] = useState<boolean[]>([]);
  //   const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { setLoading, setModal, addToast } = useAppContext();
  const tokens = useMemo(() => {
    const newData = allTickets.flatMap((item) => {
      if (item.tokenURL) {
        const tokenURLs = item.tokenURL.split("___");
        return tokenURLs.map((url: any) => ({ ...item, tokenURL: url }));
      } else {
        return { ...item, tokenURL: null };
      }
    });
    const data = newData
      .filter((item) => (item.tokenURL ? item : null))
      .sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      );
    return data;
  }, [allTickets]);

  const getData = async () => {
    setLoading(true);
    await getEventCardById(id)
      .then((res) => {
        setEventCard(res.eventcard);
      })
      .catch((error) => {
        console.log(error);
      });
    await eventsTickets({ eventCardId: id })
      .then((res) => {
        setAllTickets(res.ticket);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const updateOfferItems = (data: any) => {
    setCheckBoxs([...data]);
  };

  const createOffer = () => {
    const selected = tokens
      .filter((item, index) => (checkBoxs[index] ? item : null))
      .map((obj) => obj.tokenURL);
    const data = selected.map((url) => {
      const parts = url.split("/");
      const lastPart = parts[parts.length - 1];
      const $address = lastPart.split("=")[0];
      const address = $address.substring(0, $address.length - 2).toLowerCase();
      const tokenId = lastPart.split("=")[1];
      return { address, tokenId };
    });
    if (data.length !== 0) {
      setModal({
        open: true,
        children: <OfferModal nfts={data} eventCard={eventCard} />,
      });
    } else {
      addToast("Please select tickets.", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const nextPage = () => {
    if (page < tokens.length / count) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (
      (userInfo && userInfo.user.user_type === "ADMIN") ||
      (userInfo &&
        userInfo.user.user_type === "BUSINESS" &&
        userInfo.user.business_allow)
    ) {
      getData();
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.overview__container}>
      <div className={styles.top__part}>
        <p className={styles.title}>Select tickets to create offer</p>
        <button className={styles.create__btn} onClick={createOffer}>
          Create Offer
        </button>
      </div>
      <div className={styles.table__container}>
        {allTickets.length !== 0 && (
          <OfferCreateTable
            data={tokens}
            page={page}
            count={count}
            updateOfferItems={updateOfferItems}
          />
        )}
      </div>
      {tokens.length > count && (
        <div className={styles.transaction_pagination}>
          <div className={styles.tr__pagination_left}>
            <div className={styles.number_select} style={{ paddingRight: 2 }}>
              <p className={styles.show__numbers_txt}>Show:</p>
              <div style={{ position: "relative", display: "flex" }}>
                <select
                  className={styles.transaction__page_select}
                  name="page number"
                  id="page number"
                  value={count}
                  onChange={(e) => setCount(Number(e.target.value))}
                >
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                <img
                  className={styles.page__down_arrow}
                  src="/img/wallet/down_arrow.svg"
                  alt="down"
                />
              </div>
            </div>
            <p className={styles.show__numbers_txt}>
              Showing{" "}
              {tokens.length > count * page
                ? count
                : tokens.length - (page - 1) * count}{" "}
              out of {tokens.length} entries
            </p>
          </div>
          <div className={styles.tr__pagination_right}>
            <div className={styles.prev_page} onClick={() => prevPage()}>
              <img src="/img/wallet/chevron-left.svg" alt="left arrow" />
            </div>
            <div className={styles.number_select}>
              <p className={styles.show__numbers_txt}>
                Page {page} of {Math.ceil(tokens.length / count)}
              </p>
            </div>
            <div className={styles.next_page} onClick={() => nextPage()}>
              <img src="/img/wallet/chevron-right.svg" alt="right arrow" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageOfferCreate;
