import { useTranslation } from "react-i18next";

const ERC721TransactionTable = ({ transactions, page, count, wallet }) => {
  const { t } = useTranslation();
    return (
      <table className="transaction_table">
        <thead>
          <tr>
            <th>{t("transaction hash")}</th>
            <th>{t("type")}</th>
            <th>{t("from")}</th>
            <th>{t("to")}</th>
            <th>{t("date")}</th>
            <th>{t("item")}</th>
          </tr>
        </thead>
        <tbody>
          {transactions?.map(
            (item, index) =>
              index >= (page - 1) * count &&
              index < page * count && (
                <tr className="transaction_row" key={index}>
                  <td>{item?.transaction_hash.substring(0, 18)}...</td>
                  {item?.from_address === wallet ? (
                    <td>
                      <img src="/img/wallet/send_tran.svg" alt="send" />
                    </td>
                  ) : (
                    <td>
                      <img src="/img/wallet/receive_tran.svg" alt="receive" />
                    </td>
                  )}
                  {/* <td>{item?.block_number}</td> */}
                  <td>
                    {item?.from_address.substring(0, 8)}...
                    {item?.from_address.substring(
                      item?.from_address.length - 8,
                      item?.from_address.length
                    )}
                  </td>
                  <td>
                    {item?.to_address.substring(0, 8)}...
                    {item?.to_address.substring(
                      item?.to_address.length - 8,
                      item?.to_address.length
                    )}
                  </td>
                  <td>
                    {item?.block_timestamp.substring(0, 16).replace("T", " ")}
                  </td>
                  {/* <td>
                    {Number(item.value) / 10 ** Number(item.token_decimals)}
                  </td> */}
                  <td>
                    {item?.token_id}
                  </td>
                </tr>
              )
          )}
        </tbody>
      </table>
    );
  };
  
  export default ERC721TransactionTable;
  