import * as React from "react";
import Dialog from "@mui/material/Dialog";

export default function CustomModalComponent({ children, open, onClose }) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "transparent",
            maxWidth: "none !important",
          },
        }}
      >
        {children}
      </Dialog>
    </React.Fragment>
  );
}
